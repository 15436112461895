/* eslint-disable jsx-a11y/media-has-caption */

import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind            from 'react-autobind';
import Dropzone            from 'react-dropzone';
import filesize             from 'filesize';
import { Progress }        from 'react-sweet-progress';
import { DirectUpload }    from '@rails/activestorage';
import Pubnub              from 'pubnub';
import Tooltipable         from '~/components/effects/tooltipable';

import VideoActions             from '~/actions/video_actions';
import VideoStore               from '~/stores/video_store';
import SubscriptionLimitActions from '~/actions/subscription_limit_actions';
import SubscriptionLimitStore   from '~/stores/subscription_limit_store';

const today = Moment().format('MMMM D, YYYY');
// const uploadUrl = `${process.env.API_URL}/rails/active_storage/direct_uploads`;
const uploadUrl = `${Rails.apiUrl}/rails/active_storage/direct_uploads`;
const maxVideoSize = Number(process.env.MAX_VIDEO_SIZE);
const acceptedFormats = {
  'video/mp4':       ['.mp4'],
  'video/mpeg':      ['.mpeg', '.mpg'],
  'video/x-msvideo': ['.avi'],
  'video/quicktime': ['.mov'],
  'video/x-flv':     ['.flv'],
  'video/x-ms-wmv':  ['.wmv'],
  'video/x-m4v':     ['.m4v'],
};

class UploadVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:             false,
      videosLimitReached:  false,
      uploading:           false,
      percentCompleted:    0,
      submitting:          false,
      file:                undefined,
      errors:              {},
      transcoding:         {
        status:            '',
        progress:          0,
        video_url:         null,
        gif_thumbnail:     null,
        thumbnail:         null,
      },
      video:               {
        id:                 null,
        video:              '',
        title:              `Video - ${today}`,
        description:        '',
        animated_thumbnail: false,
      },
    };

    this.baseState = this.state;

    this.pubnub = new Pubnub({
      publishKey:   process.env.PUBNUB_PUBLISH_KEY,
      subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
      uuid:         process.env.PUBNUB_VIDEOS_UUID,
    });

    autoBind(this);
  }

  componentDidMount() {
    this.videosStoreListener = VideoStore.addListener(this.onVideoStoreChange);
    this.subscriptionLimitStoreListener = SubscriptionLimitStore.addListener(this.onSubscriptionLimitStoreChange);

    this.pubNubListeners = {
      message: this.onMessageReceived,
    };

    this.pubnub.addListener(this.pubNubListeners);

    SubscriptionLimitActions.checkIfVideosLimitReached();
  }

  componentWillUnmount() {
    if (this.videosStoreListener) this.videosStoreListener.remove();
    if (this.subscriptionLimitStoreListener) this.subscriptionLimitStoreListener.remove();
    if (this.pubnub) this.pubnub.removeListener(this.pubNubListeners);
  }

  onSubscriptionLimitStoreChange = () => {
    const { loading, videosLimitReached } = SubscriptionLimitStore.getState();

    this.setState({
      loading,
      videosLimitReached,
    });
  }

  onMessageReceived = (payload) => {
    const { video } = this.state;
    const { message } = payload;
    const transcoding = message?.transcoding;
    const status = transcoding?.status;

    this.setState({
      transcoding,
    }, () => {
      if (video?.id && status === 'transcoded') {
        this.pubnub.unsubscribe({
          channels: [`video_conversion-${video.id}`],
        });
      }
    });
  }

  onVideoStoreChange = () => {
    const {
      video, lastVideoStoreAction, errors, submitting,
    } = VideoStore.getState();
    let nextState = { errors, submitting };

    const params = {
      id:                 video?.id,
      title:              video?.title,
      description:        video?.description,
      animated_thumbnail: video?.animated_thumbnail,
      uuid:               video?.uuid,
    };

    switch (lastVideoStoreAction) {
      case 'createVideoDone':
        nextState = { video: params, errors, submitting };

        this.pubnub.subscribe({
          channels: [`video_conversion-${video.id}`],
        });

        break;
      case 'updateVideoDone':
        nextState = { video: params, errors, submitting };

        break;
      case 'createVideoFail':
        nextState = { errors, submitting };

        break;
      default:
    }

    this.setState(nextState);
  }

  setFormField(field, value) {
    this.setState((prevState) => ({
      video: {
        ...prevState.video,
        [field]: value,
      },
    }));
  }

  setErrors = (name, value) => {
    this.setState((prevState) => ({
      errors: {
        [name]: value,
      },
    }));
  }

  onDrop = (acceptedFiles, fileRejections) => {
    const file = acceptedFiles[0];
    const rejectedFile = fileRejections[0];

    rejectedFile?.errors.forEach((err) => {
      if (err.code === 'file-too-large') {
        this.setErrors('fileTooLarge', `Error: File is too large (${filesize(rejectedFile.file.size)}).`);
      }

      if (err.code === 'file-invalid-type') {
        this.setErrors('fileInvalidType', 'Error: File type is not accepted.');
      }
    });

    if (rejectedFile) return;

    this.setState({
      file,
    }, () => {
      this.uploadVideo();
    });
  };

  uploadVideo = () => {
    const { file } = this.state;

    if (!file) return;

    this.setState({ uploading: true });

    const upload = new DirectUpload(file, uploadUrl, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.setRequestHeader('Authentication', `${Rails.helpers.authToken}`);
      },
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event));
      },
    });

    upload.create((error, blob) => {
      if (error) {
        this.setState((prevState) => ({
          uploading:        false,
          percentCompleted: 0,
          errors:           {
            ...prevState.errors,
            file_uploading: error,
          },
        }));
      } else {
        this.setState((prevState) => ({
          uploading:        false,
          percentCompleted: 0,
          transcoding:      {
            ...prevState.transcoding,
            status:   'transcoding',
            progress: 0,
          },
          video:       {
            ...prevState.video,
            video: blob.signed_id,
          },
        }));

        this.createVideo();
      }
    });
  }

  createVideo = () => {
    const { video } = this.state;
    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      VideoActions.createVideo({ video });
    } else {
      this.setState({ errors });
    }
  }

  handleSaveVideo = (e) => {
    e.preventDefault();

    const { newTitle, video } = this.state;
    const params = {
      id:                 video.id,
      title:              video.title,
      description:        video.description,
      animated_thumbnail: video.animated_thumbnail,
    };

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      VideoActions.updateVideo(params, {
        loadVideos: false,
      });
    } else {
      this.setState({ errors });
    }
  }

  handleInsertVideo = (e) => {
    e.preventDefault();

    const { transcoding, video } = this.state;
    const { editor, closeModal, sms } = this.props;

    if (editor && (transcoding.gif_thumbnail || transcoding.thumbnail)) {
      const thumbnail = video?.animated_thumbnail ? transcoding.gif_thumbnail : transcoding.thumbnail;

      if (!sms) {
        editor.insertContent(
          `<a href='/video/${video.uuid}' target='_blank'><img src=${thumbnail} /></a>`,
        );
      } else {
        editor.insertContent(
          `${process.env.WEB_APP_URL}/video/${video.uuid}`,
        );
      }

      closeModal();
    }
  }

  disabledStatus = () => {
    const { submitting, uploading, transcoding } = this.state;

    return submitting || uploading || transcoding.status === 'transcoding';
  }

  directUploadDidProgress(event) {
    const percentCompleted = Math.round((event.loaded / event.total) * 100);

    this.setState({ percentCompleted });
  }

  validate() {
    const { video } = this.state;

    const errors = {};

    if (!video.title) {
      errors.title = 'Is invalid';
    }

    if (!video.id && !video.video) {
      errors.video = 'Is invalid';
    }

    return errors;
  }

  renderModalFooter = () => {
    const { transcoding, video } = this.state;
    const { sms } = this.props;
    const tooltipText = 'Note: The hyperlink to your video will be automatically shortened when the text is delivered. This ensures a cleaner, more user-friendly experience for the recipient.';

    return (
      <div className="modal-footer">
        { this.disabledStatus() ? (
          <button type="button" className="btn btn-secondary disabled" disabled>Cancel</button>
        ) : (
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
        )}

        { this.disabledStatus() || !video.id ? (
          <button type="button" className="btn btn-primary disabled" disabled>
            Save
          </button>
        ) : (
          <button type="button" className="btn btn-primary" onClick={this.handleSaveVideo}>
            Save
          </button>
        )}

        {sms ? (
          <Tooltipable placement="bottom" text={tooltipText}>
            <button
              type="button"
              className={`btn btn-primary ${this.disabledStatus() || !transcoding.gif_thumbnail ? 'disabled' : ''}`}
              onClick={(!this.disabledStatus() && transcoding.gif_thumbnail) ? this.handleInsertVideo : undefined}
              disabled={this.disabledStatus() || !transcoding.gif_thumbnail}
            >
              Insert video
            </button>
          </Tooltipable>
        ) : (
          <button
            type="button"
            className={`btn btn-primary ${this.disabledStatus() || !transcoding.gif_thumbnail ? 'disabled' : ''}`}
            onClick={(!this.disabledStatus() && transcoding.gif_thumbnail) ? this.handleInsertVideo : undefined}
            disabled={this.disabledStatus() || !transcoding.gif_thumbnail}
          >
            Insert video
          </button>
        )}
      </div>
    );
  }

  renderViews = () => {
    const { onChooseView } = this.props;

    return (
      <div className="form-row justify-content-center">
        { this.disabledStatus() ? (
          <button type="button" className="btn btn-outline-success mb-3 mr-3 disabled" disabled>
            Record a video
          </button>
        ) : (
          <button type="button" className="btn btn-outline-success mb-3 mr-3" onClick={(e) => onChooseView(e, 'recordVideo')}>
            Record a video
          </button>
        )}

        { this.disabledStatus() ? (
          <button type="button" className="btn btn-outline-success active mb-3 mr-3 disabled" disabled>
            Upload new video
          </button>
        ) : (
          <button type="button" className="btn btn-outline-success active mb-3 mr-3" onClick={(e) => onChooseView(e, 'uploadVideo')}>
            Upload new video
          </button>
        )}

        { this.disabledStatus() ? (
          <button type="button" className="btn btn-outline-success mb-3 disabled" disabled>
            Choose a video
          </button>
        ) : (
          <button type="button" className="btn btn-outline-success mb-3" onClick={(e) => onChooseView(e, 'videoLibrary')}>
            Choose a video
          </button>
        )}
      </div>
    );
  }

  renderThumbnail = () => {
    const { transcoding, video } = this.state;
    const { gif_thumbnail, thumbnail } = transcoding;

    if (!gif_thumbnail && !thumbnail) return null;

    const imageUrl = video.animated_thumbnail ? gif_thumbnail : thumbnail;

    return (
      <div className="row mb-3">
        <div className="col">
          <div className="text-center">
            <img alt="Preview Thumbnail" className="preview-thumbnail" src={imageUrl} />
          </div>
        </div>
      </div>
    );
  }

  renderDropZone = () => {
    const {
      video,
      file,
      errors,
      transcoding,
      uploading,
      percentCompleted,
    } = this.state;

    if (uploading) {
      return (
        <div className="col-8 mx-auto text-center">
          <p>We are now uploading your video.</p>
          <h6>Please keep the video window open while the video uploads. You can work on Brokerkit in the meantime in other tabs</h6>
          <Progress percent={percentCompleted} />
        </div>
      );
    }

    if (transcoding.status === 'transcoding') {
      const transcodingProgress = Math.round(transcoding.progress);

      return (
        <>
          <div className="col-8 mx-auto text-center mb-3">
            <p>We are now converting your video.</p>
            <h6>While the video converts you can keep the video window open or you can close it and wait for the video to show in your video library for sending.</h6>
          </div>
          <div className="col-8 mx-auto text-center">
            <Progress percent={transcodingProgress} />
          </div>
        </>
      );
    }

    return (
      <div className="preview-container">
        <Dropzone
          accept={acceptedFormats}
          onDrop={this.onDrop}
          multiple={false}
          maxSize={maxVideoSize}
          disabled={this.disabledStatus()}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            acceptedFiles,
            fileRejections,
          }) => {
            const rejectedFile = fileRejections[0];

            return (
              <div {...getRootProps({ className: 'dropzone py-5 text-gray' })}>
                <input {...getInputProps()} />

                <FontAwesomeIcon icon="fal fa-cloud-upload" size="5x" className="mb-2" />

                {file ? (
                  <div>
                    <FontAwesomeIcon icon="far fa-file-video" />
                    {' '}
                    {file.path}
                    {' '}
                    -
                    {' '}
                    {filesize(file.size)}
                  </div>
                ) : (
                  <>
                    {isDragActive ? (
                      <p className="text-gray">Drop the video file here ...</p>
                    ) : (
                      <p className="text-gray">
                        Click or Drag video file to this area to upload.
                      </p>
                    )}

                    <p className="text-gray mt-2">
                      Up to
                      {' '}
                      {filesize(maxVideoSize)}
                      {' '}
                      in size.
                    </p>

                    <p className="text-gray mt-2">
                      Accepted formats: mp4, mpg, mov, avi, flv, mpeg, wmv, m4v.
                    </p>

                    {rejectedFile && (
                      <>
                        {!!errors.file_uploading && (
                          <div className="text-danger mt-2">
                            Oops, something went wrong. Try again later.
                          </div>
                        )}

                        {!!errors.video && (
                          <div className="text-danger mt-2">
                            File must be selected!
                          </div>
                        )}

                        {!!errors.fileInvalidType && (
                          <div className="text-danger mt-2">
                            {errors.fileInvalidType}
                          </div>
                        )}

                        {!!errors.fileTooLarge && (
                          <div className="text-danger mt-2">
                            {errors.fileTooLarge}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }

  renderModalBody = () => {
    const {
      video,
      errors,
      loading,
      transcoding,
      videosLimitReached,
    } = this.state;
    const { onChooseView } = this.props;
    const poster = video.animated_thumbnail ? transcoding.gif_thumbnail : transcoding.thumbnail;

    if (loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse className="ml-3" />
        </div>
      );
    }

    if (videosLimitReached) {
      return (
        <div className="text-center">
          <h4>You&apos;ve reached the video limit</h4>
          <p>Upgrade to unlock uploading videos and recording time.</p>
          <a href="/billing" target="_blank" className="my-3 btn btn-secondary">Upgrade Plan</a>
          <p>
            <a href="#videoLibrary" onClick={(e) => onChooseView(e, 'videoLibrary')}> Or go delete videos in your library to continue recording.</a>
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="form-group mb-4">
          {transcoding.status === 'transcoded' ? (
            this.renderThumbnail()
          ) : (
            this.renderDropZone()
          )}
        </div>

        <div className="form-row">
          <div className="form-group col-6">
            <input
              type="text"
              id="video_title"
              name="video[title]"
              required
              placeholder="Give your video title"
              value={video.title}
              onChange={(e) => this.setFormField('title', e.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.title })}
            />
          </div>

          <div className="form-group col-6 my-auto">
            <div className="custom-control custom-checkbox text-right">
              <input
                type="checkbox"
                className="custom-control-input"
                name="video[thumbnail]"
                id="video_thumbnail"
                checked={video.animated_thumbnail}
                onChange={(e) => this.setFormField('animated_thumbnail', e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="video_thumbnail">
                Create an animated thumbnail (GIF)
              </label>
            </div>
          </div>
        </div>

        <div className="form-row mt-3">
          <div className="form-group col">
            <textarea
              id="video_description"
              name="video[description]"
              placeholder="Add text here for the recipient viewing page ..."
              value={video.description}
              onChange={(e) => this.setFormField('description', e.target.value)}
              className="form-control"
              rows={1}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="modal-body">
          {this.renderViews()}
          {this.renderModalBody()}
        </div>

        {this.renderModalFooter()}
      </>
    );
  }
}

UploadVideo.defaultProps = {
  onChooseView: () => false,
  closeModal:   () => false,
  sms:          false,
  editor:       {},
};

UploadVideo.propTypes = {
  onChooseView: PropTypes.func,
  closeModal:   PropTypes.func,
  sms:          PropTypes.bool,
  editor:       PropTypes.shape({}),
};

export default UploadVideo;
