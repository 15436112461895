import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { createFilter } from 'react-select-4';

import GlobalContainer from '~/components/global_container';
import { styles } from './components/styles';
import Option from './components/Option';
import Control from './components/Control';
// TODO: Implement MenuList
// import MenuList from './SelectWrapper/components/MenuList';

const Select = ({
  id,
  value,
  name,
  placeholder,
  options,
  onChange,
  isMulti,
  isClearable,
  isLoading,
  isInvalid,
  isSearchable,
  isDisabled,
  addBlank,
  tooltipText,
  tooltipPlacement,
  tooltipClassName,
}) => {
  const color = useMemo(() => (GlobalContainer.product() === 'retention' ? '#4286C6' : '#16B783'), []);
  const modifiedOptions = useMemo(() => {
    let baseOptions = [...options];

    if (addBlank) {
      baseOptions = [{ value: '-blank-', label: '-blank-' }, ...baseOptions];
    }

    return baseOptions;
  }, [options, addBlank]);
  const findOption = (val) => modifiedOptions.find((option) => matchesValue(option.value, val));
  const matchesValue = (optionValue, val) => String(optionValue) === String(val);
  const getOptionFromValue = () => {
    if (isMulti && Array.isArray(value)) {
      return value.map(findOption).filter(Boolean);
    }
    return findOption(value) || null;
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = useCallback((selectedOpt) => {
    setSelectedOption(selectedOpt);
    onChange(selectedOpt);
  }, [onChange]);

  useEffect(() => {
    const option = getOptionFromValue();
    setSelectedOption(option);
  }, [modifiedOptions]);

  return (
    <>
      <ReactSelect
        openMenuOnFocus
        inputId={id}
        name={name}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          Option,
          Control,
        }}
        placeholder={placeholder}
        options={modifiedOptions}
        onChange={handleChange}
        value={selectedOption}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        styles={styles(color, isInvalid)}
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipClassName={tooltipClassName}
      />
    </>
  );
};

Select.defaultProps = {
  id:                '',
  value:             '',
  name:              'dropdown',
  placeholder:       'Select an Option',
  onChange:          () => false,
  options:           [],
  isMulti:           false,
  isClearable:       false,
  isInvalid:         false,
  isSearchable:      false,
  isLoading:         false,
  addBlank:          false,
  tooltipText:       '',
  tooltipPlacement:  'bottom',
  tooltipClassName:  '',
};

Select.propTypes = {
  id:           PropTypes.string,
  value:        PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  name:              PropTypes.string,
  placeholder:       PropTypes.string,
  onChange:          PropTypes.func,
  options:           PropTypes.arrayOf(PropTypes.shape({})),
  isMulti:           PropTypes.bool,
  isClearable:       PropTypes.bool,
  isInvalid:         PropTypes.bool,
  isSearchable:      PropTypes.bool,
  isLoading:         PropTypes.bool,
  addBlank:          PropTypes.bool,
  tooltipText:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  tooltipPlacement: PropTypes.string,
  tooltipClassName: PropTypes.string,
};

export default Select;
