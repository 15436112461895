import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { PropTypes } from 'prop-types';

import ActivityEditor from '~/components/forms/HtmlEditors/ActivityEditor';
import LeadDrawerActions from '~/actions/lead_drawer_actions';
import FormFooter from './common/form_footer';

const validateForm = (activityType, body, footerRef) => {
  const errors = {};
  if (activityType === 'note' && _lodash.isEmpty(body)) {
    errors.body = "Can't be empty";
  }
  return footerRef.current.validate(errors);
};

const handleFormSubmit = (
  e,
  validate,
  id,
  body,
  callSid,
  activityType,
  lead,
  footerRef,
  setErrors,
) => {
  e.preventDefault();
  const validationErrors = validate();

  if (_lodash.isEmpty(validationErrors)) {
    const footer = footerRef.current.serialize();
    const currentRole = Rails.helpers.currentUser.role.name;

    const {
      status,
      rating,
      task,
      notify,
      call_disposition,
    } = footer;

    let {
      internal,
    } = footer;

    if (['agent', 'affiliate'].includes(currentRole)) {
      internal = false;
    }

    const data = {
      status,
      rating,
      task,
      event:    activityType,
      activity: {
        body,
        internal,
        notify,
        call_sid: callSid,
      },
    };

    if (call_disposition) {
      data.activity.parameters = {
        call_disposition,
      };
    }

    if (id) {
      LeadDrawerActions.updateLeadActivity(lead, id, data);
    } else {
      LeadDrawerActions.createLeadActivity(lead, data);
    }
  } else {
    setErrors(validationErrors);
  }
};

const NoteForm = ({
  lead,
  dialer,
  activityType,
  submitButtonText,
  submitButtonSendingText,
}) => {
  const [id, setId] = useState(dialer.id || null);
  const [body, setBody] = useState(dialer.body || '');
  const [callSid, setCallSid] = useState(dialer.callSid || '');
  const [callStatus, setCallStatus] = useState(dialer.callStatus || '');
  const [internal, setInternal] = useState(
    GlobalContainer.product() === 'retention',
  );
  const [errors, setErrors] = useState({});
  const footerRef = useRef();

  const validate = useCallback(
    () => validateForm(activityType, body, footerRef),
    [activityType, body, footerRef],
  );

  const onFormSubmit = useCallback(
    (e) => handleFormSubmit(
      e,
      validate,
      id,
      body,
      callSid,
      activityType,
      lead,
      footerRef,
      setErrors,
    ),
    [validate, id, body, callSid, activityType, lead, footerRef],
  );

  useEffect(() => {
    setId(dialer.id);
    setBody(dialer.body);
    setCallSid(dialer.callSid);
    setCallStatus(dialer.callStatus);
  }, [dialer]);

  return (
    <form onSubmit={onFormSubmit}>
      <div className="form-group mb15">
        <ActivityEditor
          value={body || ''}
          className={errors.body ? 'tiny-mce has-error' : ''}
          onChange={setBody}
          placeholder="Enter Text"
          activityType={activityType}
          spellCheck
        />
      </div>
      <div className="mt15 mb15">
        <FormFooter
          ref={footerRef}
          dialer={dialer}
          activityType={activityType}
          status={lead.status}
          callStatus={callStatus}
          internal={internal}
          lead={lead}
          onSubmit={onFormSubmit}
          submitButtonText={id ? 'Save' : submitButtonText}
          submitButtonSendingText={submitButtonSendingText}
        />
      </div>
    </form>
  );
};

NoteForm.defaultProps = {
  dialer:                  {},
  activityType:            'note',
  submitButtonText:        'Save Note',
  submitButtonSendingText: 'Saving ...',
};

NoteForm.propTypes = {
  lead:                    PropTypes.shape({}).isRequired,
  dialer:                  PropTypes.shape({}),
  activityType:            PropTypes.string,
  submitButtonText:        PropTypes.string,
  submitButtonSendingText: PropTypes.string,
};

export default NoteForm;
