import React from 'react';

const VerifiedTextingWarning = () => (
  <div className="alert alert-warning p-2 mb-2" role="alert">
    📵 This phone number is not linked to an approved verified texting campaign. To comply with carrier texting regulations, please register for verified texting
    {' '}
    <a target="_blank" href="/users/phone_number/edit" className="text-green">here</a>
    {' '}
    first to enable texting from this number.
  </div>
);

export default VerifiedTextingWarning;
