import React, {
  useState,
  useCallback,
} from 'react';
import StepWizard     from 'react-step-wizard';
import SettingsStep   from './steps/settings_step';
import UploadFileStep from './steps/upload_file_step';

const BrokerMetricsImportTab = () => {
  const [importType] = useState('Import::BrokerMetrics');
  const [labels, setLabels] = useState('');
  const [multipleListingServiceOuid, setMultipleListingServiceOuid] = useState('');

  const handleLabelsChange = useCallback((e) => {
    setLabels(e.target.value);
  }, []);

  const handleMultipleListingServiceChange = useCallback((value) => {
    setMultipleListingServiceOuid(value);
  }, []);

  const resetState = useCallback(() => {
    setLabels('');
    setMultipleListingServiceOuid('');
  }, []);

  return (
    <div className="broker-metrics-import mt-2">
      <h5>Follow the prompts below to import your data into Brokerkit</h5>

      <ul className="mt-2 pl-3">
        <li>
          Create a query in BrokerMetrics and export it to CSV in the &quot;Contact Information&quot; view for upload into Brokerkit. See
          {' '}
          <a
            className="text-primary"
            href="https://support.getbrokerkit.com/hc/en-us/articles/360004481311"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          {' '}
          how to pull the &quot;Contact Information&quot; view
        </li>
        <li>Your file uploaded must be in the format of the &quot;Contact Information&quot; view as we have mapped those columns to Brokerkit fields</li>
        <li>We match data by column header name to know which field to import the data into so do not modify the column headers or delete columns from the &quot;Contact Information&quot; view</li>
        <li>You can add columns with the header names &quot;Notes&quot; and &quot;Labels&quot; and import them. Any other columns will be skipped on import</li>
        <li>Any row that has neither an email address nor an agent ID will be skipped</li>
        <li>The agent ID OR email address columns will be used to match and update existing records</li>
        <li>If there are no existing records with the email addresses or agent IDs in the CSV, then new records will be created</li>
        <li>An MLS is required to be selected if the import includes Agent IDs</li>
        <li>Please import data from only one MLS at a time</li>
      </ul>

      <div className="mt-3">
        <StepWizard>
          <SettingsStep
            key="broker-metrics-settings"
            step={1}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            handleLabelsChange={handleLabelsChange}
            handleMultipleListingServiceChange={handleMultipleListingServiceChange}
          />
          <UploadFileStep
            key="broker-metrics-upload-template"
            step={2}
            importType={importType}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            resetState={resetState}
          />
        </StepWizard>
      </div>
    </div>
  );
};

export default BrokerMetricsImportTab;
