import React, {
  useState,
  useEffect,
} from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { Editor }          from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VideosModal from '~/components/modals/videos/VideosModal';
import AssistModal from '~/components/modals/AiAssist/Modal';
import {
  TINYMCE_SCRIPT_PATH,
  EMAIL_EDITOR_PLUGINS,
  EMAIL_EDITOR_TOOLBAR,
  IMAGES_UPLOAD_HANDLER,
} from '~/constants/TinyMCE';

import { initWebSpellChecker } from '~/lib/web_spell_checker';

const EmailEditor = ({
  id,
  value,
  className,
  placeholder,
  replaceValue,
  clearReplaceValue,
  onChange,
  setSubject,
}) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (replaceValue) {
      setContent(replaceValue);
      clearReplaceValue();
    }
  }, [replaceValue, clearReplaceValue]);

  const handleEditorChange = (_value, editor) => {
    const newContent = editor.getContent({ format: 'html' });
    setContent(newContent);
    onChange(newContent);
  };

  const handleOnInit = (_event, editor) => {
    setLoading(false);

    if (value) {
      editor.setContent(value, { format: 'html' });
    }
    initWebSpellChecker(editor.iframeElement);
  };

  return (
    <>
      <div className={className}>
        {loading && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Loading
          </div>
        )}

        <Editor
          id={id}
          tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
          scriptLoading={{ async: true }}
          value={content}
          onEditorChange={handleEditorChange}
          onInit={handleOnInit}
          init={{
            placeholder,
            license_key:                   'gpl',
            plugins:                       EMAIL_EDITOR_PLUGINS,
            toolbar:                       EMAIL_EDITOR_TOOLBAR,
            toolbar_mode:                  'wrap',
            branding:                      false,
            menubar:                       false,
            file_picker_types:             'image',
            min_height:                    350,
            relative_urls:                 false,
            remove_script_host:            false,
            document_base_url:             Rails.baseUrl,
            forced_root_block:             'div',
            entity_encoding:               'named',
            content_style:                 'body { font-family: Arial; font-size: 13px; }',
            paste_as_text:                 false,
            contextmenu:                   false,
            paste_remove_styles_if_webkit: false,
            statusbar:                     true,
            images_upload_handler:         IMAGES_UPLOAD_HANDLER,
            setup:                         (editor) => {
              editor.ui.registry.addIcon('videoIcon', '<span class="fas fa-video-plus" />');
              editor.ui.registry.addIcon('usersIcon', '<span class="fas fa-users" />');

              editor.ui.registry.addButton('videos', {
                icon:     'videoIcon',
                tooltip:  'Insert Video',
                onAction: () => {
                  ReactDOM.render(
                    <VideosModal
                      containerID="secondary-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog modal-lg"
                      editor={editor}
                    />, document.getElementById('secondary-modal'),
                  );
                },
              });

              editor.ui.registry.addMenuButton('variables', {
                icon:    'usersIcon',
                text:    'Variables ...',
                tooltip: 'Variables ...',
                fetch:   (callback) => {
                  const items = [
                    { type: 'menuitem', text: 'Lead First Name',        onAction: () => { editor.insertContent('{{firstName}}'); } },
                    { type: 'menuitem', text: 'Lead Last Name',         onAction: () => { editor.insertContent('{{lastName}}'); } },
                    { type: 'menuitem', text: 'Lead Full Name',         onAction: () => { editor.insertContent('{{fullName}}'); } },
                    { type: 'menuitem', text: 'Lead Company',           onAction: () => { editor.insertContent('{{company}}'); } },
                    { type: 'menuitem', text: "Agent's Volume",         onAction: () => { editor.insertContent('{{Volume}}'); } },
                    { type: 'menuitem', text: 'Referrer First',         onAction: () => { editor.insertContent('{{referrerFirst}}'); } },
                    { type: 'menuitem', text: 'Referrer Last',          onAction: () => { editor.insertContent('{{referrerLast}}'); } },
                    { type: 'menuitem', text: 'Referrer Full Name',     onAction: () => { editor.insertContent('{{referrerName}}'); } },
                    { type: 'menuitem', text: 'Sender First Name',      onAction: () => { editor.insertContent('{{senderFirst}}'); } },
                    { type: 'menuitem', text: 'Sender Last Name',       onAction: () => { editor.insertContent('{{senderLast}}'); } },
                    { type: 'menuitem', text: 'Sender Full Name',       onAction: () => { editor.insertContent('{{senderFull}}'); } },
                    { type: 'menuitem', text: 'Sender Company',         onAction: () => { editor.insertContent('{{senderCompany}}'); } },
                    { type: 'menuitem', text: 'Sender Landing Page',    onAction: () => { editor.insertContent('{{LandingPage}}'); } },
                    { type: 'menuitem', text: 'Sender Phone Number',    onAction: () => { editor.insertContent('{{senderPhone}}'); } },
                    { type: 'menuitem', text: 'Sender Email Signature', onAction: () => { editor.insertContent('{{senderEmailSignature}}'); } },
                  ];

                  callback(items);
                },
              });

              editor.ui.registry.addButton('ai-assist', {
                icon:     'ai',
                tooltip:  'AI Assist',
                onAction: () => {
                  ReactDOM.render(
                    <AssistModal
                      containerID="ai-assist-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog"
                      editor={editor}
                      setSubject={setSubject}
                    />,
                    document.getElementById('ai-assist-modal'),
                  );
                },
              });
            },
          }}
        />
      </div>
    </>
  );
};

EmailEditor.defaultProps = {
  id:                '',
  value:             '',
  className:         '',
  placeholder:       'Enter text or insert / drop images ...',
  replaceValue:      null,
  clearReplaceValue: () => false,
  onChange:          () => false,
  setSubject:        () => false,
};

EmailEditor.propTypes = {
  id:                PropTypes.string,
  value:             PropTypes.string,
  className:         PropTypes.string,
  placeholder:       PropTypes.string,
  replaceValue:      PropTypes.string,
  clearReplaceValue: PropTypes.func,
  onChange:          PropTypes.func,
  setSubject:        PropTypes.func,
};

export default EmailEditor;
