import React, { useMemo }      from 'react';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import { Link }                from 'react-router-dom';
import { getRestrictionScope } from '~/helpers/staffRestrictionHelper';

const { currentUser } = Rails.helpers;

const DuplicateContactCard = ({ duplicate: { type, match } }) => {
  const productType = GlobalContainer.product();
  const currentRestriction = useMemo(() => getRestrictionScope(), [productType]);
  const showLeadLink = (!currentRestriction || (match.owner && currentUser.id === match.owner.id));

  const getProfileURL = (contact) => {
    switch (contact.product) {
      case 'recruiting':
        return `/recruiter/leads?lead=${contact.id}`;
      case 'retention':
        return `/retention/team?lead=${contact.id}`;
      default:
        return `/recruiter/leads?lead=${contact.id}`;
    }
  };

  return (
    <div className="col-12 m-0 p-0">
      <div className="card mt-2">
        <div className="card-body d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <div className="mr-3" width="200px">
              <img width="70px" alt="User Avatar" className="rounded-circle" src={match.avatar_url} />
            </div>

            <div className="mr-2 d-flex flex-column justify-content-center">
              <div className="match-name">
                {match.name}
              </div>
              <div className="match-company text-gray">
                {match.company}
              </div>
              { match.owner && (
              <div className="match-owner">
                <span>Owner </span>
                <span className="owner-thumbnail">
                  <img width="18px" alt="User Avatar" className="rounded-circle" src={match.owner.avatar_url} />
                </span>
                <span className="owner-name ml-1">
                  {match.owner.name}

                </span>
              </div>
              )}
            </div>
          </div>
          {showLeadLink && (
            <div className="match-link d-flex align-items-center text-gray">
              <Link target="_blank" to={getProfileURL(match)} className="ml10">
                <FontAwesomeIcon icon={['fas', 'fa-external-link']} />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DuplicateContactCard;
