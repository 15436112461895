import React, {
  useState,
  useEffect,
} from 'react';
import ReactDOM             from 'react-dom';
import { PropTypes }        from 'prop-types';
import { Editor }           from '@tinymce/tinymce-react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import MentionsEditor       from '~/components/forms/HtmlEditors/MentionsEditor';

import AssistModal from '~/components/modals/AiAssist/Modal';
import {
  TINYMCE_SCRIPT_PATH,
  ACTIVITY_EDITOR_PLUGINS,
  ACTIVITY_EDITOR_TOOLBAR,
  IMAGES_UPLOAD_HANDLER,
} from '~/constants/TinyMCE';
import { initWebSpellChecker } from '~/lib/web_spell_checker';

const ActivityEditor = ({
  id,
  className,
  placeholder,
  value,
  onChange,
  activityType,
  spellCheck,
}) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [hasCheckedFeature, setHasCheckedFeature] = useState(false);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const init = {
    placeholder,
    license_key:                   'gpl',
    plugins:                       ACTIVITY_EDITOR_PLUGINS,
    toolbar:                       ACTIVITY_EDITOR_TOOLBAR,
    toolbar_mode:                  'wrap',
    branding:                      false,
    menubar:                       false,
    file_picker_types:             'image',
    min_height:                    350,
    relative_urls:                 false,
    remove_script_host:            false,
    document_base_url:             Rails.baseUrl,
    forced_root_block:             'div',
    entity_encoding:               'named',
    content_style:                 'body { font-family: Arial; font-size: 13px; }',
    paste_as_text:                 false,
    contextmenu:                   false,
    paste_remove_styles_if_webkit: false,
    statusbar:                     true,
    images_upload_handler:         IMAGES_UPLOAD_HANDLER,
    setup:                         (editor) => {
      if (Rails.abilities.manageLead) {
        editor.ui.registry.addButton('ai-assist', {
          icon:     'ai',
          tooltip:  'AI Assist',
          onAction: () => {
            ReactDOM.render(
              <AssistModal
                containerID="ai-assist-modal"
                modalClass="modal modal-overlay"
                dialogClass="modal-dialog"
                editor={editor}
              />,
              document.getElementById('ai-assist-modal'),
            );
          },
        });
      }
    },
  };

  useEffect(() => {
    const handlePosthogReady = () => {
      const isAccessAllowed = checkFeatureFlag(process.env.ALLOW_MENTIONS_FLAG);
      setIsFeatureEnabled(isAccessAllowed);
      setHasCheckedFeature(true);
    };

    if (window.isPosthogInitialized) {
      handlePosthogReady();
    } else {
      window.addEventListener('posthogReady', handlePosthogReady);
    }

    return () => {
      window.removeEventListener('posthogReady', handlePosthogReady);
    };
  }, []);

  const handleEditorChange = (_value, editor) => {
    const newContent = editor.getContent({ format: 'html' });
    setContent(newContent);
    onChange(newContent);
  };

  const handleOnInit = (_event, editor) => {
    setLoading(false);

    if (value) {
      editor.setContent(value, { format: 'html' });
    }

    if (spellCheck) {
      initWebSpellChecker(editor.iframeElement);
    }
  };
  return (
    <>
      <div className={className}>
        {loading && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Loading
          </div>
        )}

        {hasCheckedFeature && isFeatureEnabled ? (
          <MentionsEditor
            id={id}
            value={content}
            onEditorChange={handleEditorChange}
            onInit={handleOnInit}
            init={init}
            activityType={activityType}
          />
        ) : (
          <Editor
            id={id}
            tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
            scriptLoading={{ async: true }}
            value={content}
            onEditorChange={handleEditorChange}
            onInit={handleOnInit}
            init={init}
          />
        )}
      </div>
    </>
  );
};

ActivityEditor.defaultProps = {
  value:        '',
  className:    '',
  id:           '',
  placeholder:  'Enter text or insert / drop images ...',
  onChange:     () => false,
  activityType: 'note',
  spellCheck:   false,
};

ActivityEditor.propTypes = {
  value:        PropTypes.string,
  className:    PropTypes.string,
  id:           PropTypes.string,
  placeholder:  PropTypes.string,
  onChange:     PropTypes.func,
  activityType: PropTypes.string,
  spellCheck:   PropTypes.bool,
};

export default ActivityEditor;
