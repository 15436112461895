import autoBind from 'react-autobind';
import React    from 'react';

import BlankPage        from '~/components/pages/blank_page';
import FrontSideBar     from '~/components/navigation/front_side_bar';
import ListingCampaigns from './manage_campaigns/listing_campaigns';

class ManageCampaigns extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    return (
      <BlankPage title="Campaigns - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <main className="mr-sm-auto col-md-9 col-lg-10 col-xl-8">
            <ListingCampaigns />
          </main>
        </div>
      </BlankPage>
    );
  }
}

export default ManageCampaigns;
