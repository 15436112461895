import React, {
  useState,
  useMemo,
} from 'react';
import {
  Popover,
  Button,
  OverlayTrigger,
} from 'react-bootstrap';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shadowRoot          from 'react-shadow';
import PropTypes           from 'prop-types';
import classNames          from 'classnames';
import TimelineActivity    from '~/components/drawers/timeline/activity';
import Tooltipable         from '~/components/effects/tooltipable';
import TinyUser            from '~/components/shared/users/tiny_user';
import APIRequest          from '~/lib/api_request';
import ConfirmAlertBox      from '~/components/shared/confirm_alert_box';
import LeadDrawerActions   from '~/actions/lead_drawer_actions';

const TimelineActivityMessageScheduled = ({
  activity: activityProp,
  editable,
  deletable,
  lead,
  icon,
  prefix,
  color,
  title,
  setCurrentFormWithData,
  messageType,
}) => {
  const [activity, setActivity] = useState(activityProp);
  const [deletingActivity, setDeletingActivity] = useState(false);

  const dateTime = useMemo(() => {
    const { schedule_at: scheduleAt, timezone } = activity.parameters || {};

    if (!scheduleAt || !timezone) return '';

    return Moment(scheduleAt).tz(timezone).format('ddd, MMM D, hh:mmA z');
  }, [activity.parameters?.schedule_at, activity.parameters?.timezone]);

  const truncateMessageBody = (body) => body ? body.substring(0, 100) + (body.length > 100 ? '...' : '') : '';

  const formatMessageType = () => {
    switch (messageType) {
      case 'email':
        return 'Email';
      case 'sms_message':
        return 'Text';
      default:
        return messageType;
    }
  };

  const renderView = () => {
    const { subject, body, media_url } = activity.parameters || {};
    const { email } = activity;
    const attachments = email?.attachments || [];

    return (
      <div id={`${activity.id}-LeadActivity`}>
        { subject && <h5 className="mt10 mb5">{subject}</h5> }

        <shadowRoot.div id="stepEmailBodyPreview" className="form-control font-activity">
          <div className="content" dangerouslySetInnerHTML={{ __html: truncateMessageBody(body) || '' }} />
        </shadowRoot.div>

        { attachments && (
          attachments.map((a) => (
            <>
              <a key={uuidv4()} href={a.url}>
                <FontAwesomeIcon icon={['far', 'fa-file']} className="mt10 mr5" />
                {a.name}
              </a>
              <br />
            </>
          ))
        )}

        {(messageType === 'sms_message') && media_url && (
          <a href={media_url}>
            <FontAwesomeIcon icon={['far', 'fa-file']} className="mt10 mr5" />
            Attachment
          </a>
        )}
      </div>
    );
  };

  const renderTitle = () => (
    <h5 className="text-grey-dark mb5 flex-grow-1">
      {title}
      {' - '}
      <small>{dateTime}</small>
    </h5>
  );

  const renderWhoTimestamp = () => (
    <div className="text-grey mt10">
      <span className="pull-right text-grey">
        <Tooltipable
          text={Moment(activity.occurred_at).format(
            'MMMM Do YYYY, h:mm:ss a',
          )}
        >
          <>{Moment(activity.occurred_at).fromNow()}</>
        </Tooltipable>
      </span>
      <TinyUser user={activity.owner} />
    </div>
  );

  const renderActivitySwitch = () => {
    const btnClass = classNames('btn btn-secondary btn-sm pl8 pr8', {
      mr5: editable || deletable,
    });
    const tooltipText = activity.internal ? 'Private - Viewable to staff only' : 'Public - Viewable to staff and recruiting agent';

    return (
      <Tooltipable text={tooltipText}>
        <button
          type="button"
          className={btnClass}
          onClick={(e) => switchActivityView(activity.internal)}
        >
          <FontAwesomeIcon icon={['far', activity.internal ? 'fa-eye-slash' : 'fa-eye']} />
        </button>
      </Tooltipable>
    );
  };

  const renderPopover = (props) => {
    const { id } = activity;
    const object = getObject();
    const formattedMessageType = formatMessageType();

    let deleteMessage;
    if (object?.source === 'manual') {
      deleteMessage = `You are about to cancel a Scheduled ${formattedMessageType}. Please confirm to proceed with this action.`;
    } else {
      deleteMessage = `You are about to cancel a Scheduled Bulk ${formattedMessageType}, which will cancel this ${formattedMessageType} for all contacts selected for this ${formattedMessageType}. Please confirm to proceed with this action.`;
    }

    return (
      <Popover
        id={`ScheduleDeletePopover-${id}`}
        {...props}
      >
        <Popover.Content className="bg-grey-lightest">
          <ConfirmAlertBox
            onConfirm={onDelete}
            message={deleteMessage}
            disabled={deletingActivity}
            buttonTitle={deletingActivity ? 'Deleting...' : 'Confirm'}
          />
        </Popover.Content>
      </Popover>
    );
  };

  const renderActions = () => {
    const { parameters, id } = activity;
    const activityEditable = editable && parameters?.editable !== false;
    const activityDeletable = deletable && parameters?.deletable !== false;

    return (
      <div>
        {Rails.abilities.manageLead && renderActivitySwitch()}

        {activityEditable && (
          <button
            type="button"
            onClick={onEdit}
            className={classNames(
              'btn btn-secondary btn-sm',
              activityDeletable ? 'mr5' : '',
            )}
          >
            <FontAwesomeIcon icon={['far', 'fa-edit']} />
          </button>
        )}

        {activityDeletable && (
          <>
            <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover}>
              <Button
                id={`ScheduleDeleteButton-${id}`}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </Button>
            </OverlayTrigger>
          </>
        )}
      </div>
    );
  };

  const switchActivityView = (internal) => {
    APIRequest.put({
      resource: `/v1/leads/${lead.id}/activities/${activity.id}`,
      data:     {
        activity: { internal: !internal },
      },
    }).end((err, res) => {
      if (err) {
        const errorsArr = res.body?.errors || [];
        GlobalContainer.notify(errorsArr.join(', '), 'error');
      } else {
        setActivity(() => res.body);
      }
    });
  };

  const onDelete = () => {
    setDeletingActivity(() => true);

    if (messageType === 'sms_message') {
      LeadDrawerActions.cancelLeadSmsMessage(lead, activity.sms_message.id);
    } else if (messageType === 'email') {
      LeadDrawerActions.cancelLeadEmail(lead, activity.email.id);
    }
  };

  const onEdit = () => {
    const { schedule } = activity;
    const { run_at: runAt, run_at_timezone: timezone } = schedule;
    const dateTimeObject = Moment(runAt).tz(timezone);
    const object = getObject();
    const form = messageType === 'email' ? 'email' : 'text-message';
    const scheduleParams = {
      selectedSchedule: 'Custom',
      customOptions:    {
        date:     dateTimeObject.format('MMM Do YYYY'),
        time:     dateTimeObject.format('HH:mm'),
        timezone,
      },
    };
    const data = { object, schedule: scheduleParams };

    setCurrentFormWithData({ form, data });
  };

  const getObject = () => {
    if (messageType === 'email') {
      return activity.email;
    } if (messageType === 'sms_message') {
      return activity.sms_message;
    }

    return {};
  };

  return (
    <TimelineActivity
      icon={icon}
      prefix={prefix}
      color={color}
      activity={activity}
    >
      <div className="d-flex justify-content-between mb-2">
        {renderTitle()}
        {renderActions()}
      </div>

      {renderView()}
      {renderWhoTimestamp()}
    </TimelineActivity>
  );
};

TimelineActivityMessageScheduled.defaultProps = {
  editable:               true,
  deletable:              true,
  title:                  'Email Scheduled',
  icon:                   'envelope',
  prefix:                 'fas',
  color:                  'text-blue',
  setCurrentFormWithData: () => {},
};

TimelineActivityMessageScheduled.propTypes = {
  activity:               PropTypes.shape({}).isRequired,
  lead:                   PropTypes.shape({}).isRequired,
  messageType:            PropTypes.string.isRequired,
  editable:               PropTypes.bool,
  deletable:              PropTypes.bool,
  icon:                   PropTypes.string,
  prefix:                 PropTypes.string,
  color:                  PropTypes.string,
  title:                  PropTypes.string,
  setCurrentFormWithData: PropTypes.func,
};

export default TimelineActivityMessageScheduled;
