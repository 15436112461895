import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PropTypes } from 'prop-types';
import { Tooltip } from 'reactstrap';

const Tooltipable = ({
  text,
  placement = 'bottom',
  className = '',
  innerClassName = '',
  target: targetProp,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id] = useState(() => `tooltip-${uuidv4()}`);
  const toggle = () => setIsOpen(!isOpen);
  const target = targetProp || id;

  return (
    <span id={target} className={className}>
      <span>
        {children}
      </span>

      {text && (
        <Tooltip
          placement={placement}
          isOpen={isOpen}
          target={target}
          autohide={false}
          innerClassName={innerClassName}
          toggle={toggle}
        >
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Tooltip>
      )}
    </span>
  );
};

Tooltipable.propTypes = {
  text:           PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement:      PropTypes.string,
  className:      PropTypes.string,
  target:         PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  innerClassName: PropTypes.string,
  children:       PropTypes.node,
};

Tooltipable.defaultProps = {
  placement:      'bottom',
  className:      'd-inline-block',
  innerClassName: '',
  target:         undefined,
  children:       undefined,
};

export default Tooltipable;
