import React from 'react';

const TokenLimitExceededCTA = ({ state }) => (
  <>
    <div className="modal-body">
      <div>
        Unlock the full potential of our AI text generator by upgrading to the next pricing plan.
        Get additional generative AI tokens for creating your emails, texts,
        landing pages and notes to boost your recruiting and retention efforts.
      </div>

      <br />
      <div>
        Don&apos;t let limited tokens hold you back. Click the button below to upgrade & supercharge your Brokerkit experience today!
      </div>
    </div>

    <div className="modal-footer">
      <div>
        <a href="/billing" target="_blank" className="btn btn-success">
          👉 Upgrade My Plan Now
        </a>
      </div>

      <div className="mt-3 text-center">
        <small>
          If you have any questions or need assistance, our support team is always here to help.
          Just reach out to us at
          {' '}
          <a
            href="mailto:support@getbrokerkit.com"
            target="_blank"
            rel="noreferrer"
            className="text-success"
          >
            support@getbrokerkit.com
          </a>
          {' '}
          or via the in-app chat
        </small>
      </div>
    </div>
  </>
);

export default TokenLimitExceededCTA;
