import React     from 'react';
import PropTypes from 'prop-types';
import autoBind  from 'react-autobind';
import { Link }  from 'react-router-dom';

import Select           from '~/components/forms/select';
import ClickableTooltip from '~/components/effects/clickable_tooltip';

import MlsActions        from '~/actions/mls_actions';
import MlsStore          from '~/stores/mls_store';
import MlsServiceRequest from '~/lib/mls_service_request';

class MultipleListingServicesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingOptions: true,
      options:        [],
      value:          props.value,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { teamMlses } = this.props;

    this.mlsStoreListener = MlsStore.addListener(this.onMlsStoreChange);

    setTimeout(() => {
      this.loadPrimaryTeamMlsOption();

      if (teamMlses) {
        MlsActions.loadTeamMlsesOptions();
      } else {
        MlsActions.loadMlsOptions();
      }
    }, 0);
  }

  componentWillUnmount() {
    if (this.mlsStoreListener) this.mlsStoreListener.remove();
  }

  onMlsStoreChange() {
    const mlsStoreState = MlsStore.getState();
    const {
      loadingOptions, options, primaryMlsOuid, lastMlsStoreAction,
    } = mlsStoreState;
    let selectOptions = [];

    if (options) {
      selectOptions = options.map((mls) => ({
        label: mls.name,
        value: mls.ouid,
      }));
    }

    this.setState({
      loadingOptions,
      options: selectOptions,
    });
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : '';
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(value);
    });
  }

  loadPrimaryTeamMlsOption() {
    const { value } = this.state;
    const { primaryMls } = this.props;

    if (primaryMls && !value) {
      MlsServiceRequest.get({ resource: '/team_mlses/primary_mls_option' })
        .end((err, response) => {
          if (response && response.ok) {
            this.handleSelectChange(response.body.ouid);
          }
        });
    }
  }

  render() {
    const {
      placeholder, clearable, className, teamMlses, target,
    } = this.props;
    const { value, loadingOptions, options } = this.state;
    const search_queries_uri = GlobalContainer.productUri('/data');

    return (
      <>
        <Select
          className={className}
          clearable={clearable}
          placeholder={placeholder}
          value={value}
          onChange={this.handleSelectChange}
          options={options}
          isLoading={loadingOptions}
        />

        {(teamMlses) && _lodash.isEmpty(options) && target && (
          <ClickableTooltip
            text={(
              <>
                Go to MLS Settings
                {' '}
                <a className="text-white" href="/data" target="_blank">here</a>
                {' '}
                to set your MLSs for this account
              </>
            )}
            placement="bottom"
            target={target}
          />
        )}
      </>
    );
  }
}

MultipleListingServicesSelect.defaultProps = {
  onChange:           () => {},
  placeholder:        'Select a MLS...',
  style:              {},
  value:              null,
  clearable:          false,
  teamMlses:          false,
  className:          '',
  target:             'mls-select',
  primaryMls:         false,
};

MultipleListingServicesSelect.propTypes = {
  onChange:           PropTypes.func,
  placeholder:        PropTypes.string,
  style:              PropTypes.shape({}),
  value:              PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  clearable:          PropTypes.bool,
  teamMlses:          PropTypes.bool,
  className:          PropTypes.string,
  target:             PropTypes.string,
  primaryMls:         PropTypes.bool,
};

export default MultipleListingServicesSelect;
