import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import Item from './Item';

const InfiniteScroll = forwardRef(({
  items,
  renderItem,
  itemSize,
  nextPage,
  isNextPageLoading,
  loadNextPage,
}, ref) => {
  const listRef = useRef();
  const rowHeights = useRef({});
  const itemCount = nextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  const isItemLoaded = (index) => !nextPage || index < items.length;

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = (index) => rowHeights.current[index] ?? itemSize;

  useImperativeHandle(ref, () => ({
    scrollToItem: (index, align = 'auto') => {
      listRef.current?.scrollToItem(index, align);
    },
  }));

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref: infiniteLoaderRef }) => (
            <List
              ref={(list) => {
                infiniteLoaderRef(list);
                listRef.current = list;
              }}
              className="List"
              height={height}
              width={width}
              itemCount={itemCount}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              itemData={{
                items,
                renderItem,
                setRowHeight,
                itemSize,
                isItemLoaded,
              }}
            >
              {Item}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
});

export default InfiniteScroll;
