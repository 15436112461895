import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TwilioBundleActions from '~/actions/twilio_bundle_actions';
import TwilioBundleStore   from '~/stores/twilio_bundle_store';
import ClickableTooltip    from '~/components/effects/clickable_tooltip';

class ProfileStatusTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      errors:   {},
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to TwilioBundleStore change events and store reference
    this.listener = TwilioBundleStore.addListener(this.onTwilioBundleStoreChange);
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  onTwilioBundleStoreChange() {
    const { setTab } = this.props;
    const { creating, lastTwilioBundleStoreAction, error_message } = TwilioBundleStore.getState();

    const newState = {
      creating,
    };

    if (lastTwilioBundleStoreAction === 'createBundleDone') {
      GlobalContainer.notify('Your Business Profile was created.');
      setTab('business_profile');
    } else if (lastTwilioBundleStoreAction === 'createBundleFail') {
      let message = error_message || 'Failed to create Business Profile.';
      GlobalContainer.notify(message, 'error');
    }

    this.setState(newState);
  }

  onCreateClick(e) {
    e.preventDefault();

    TwilioBundleActions.createTwilioBundle();
  }

  renderStatus() {
    const { status } = this.props;

    if (!status) {
      return '-';
    }

    let text = '-';
    let icon = '';

    switch (status.status_code) {
      case 'draft':
        text = 'Draft';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
      case 'in-review':
        text = 'In Review';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
      case 'twilio-approved':
        text = 'Approved';
        icon = <FontAwesomeIcon icon={['fas', 'fa-check-circle']} className="text-success" />;
        break;
      case 'twilio-rejected':
        text = 'Rejected';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-danger" />;
        break;
      default:
        text = '-';
        break;
    }

    return (
      <span>
        Profile:
        {' '}
        {text}
        {' '}
        {icon && icon}
      </span>
    );
  }

  renderShakenStirStatus() {
    const { shaken_stir } = this.props;

    if (!shaken_stir) return null;

    let text = '';
    let icon = '';

    switch (shaken_stir.status_code) {
      case 'in-review':
        text = 'In Review';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
      case 'twilio-approved':
        text = 'Approved';
        icon = <FontAwesomeIcon icon={['fas', 'fa-check-circle']} className="text-success" />;
        break;
      case 'twilio-rejected':
        text = 'Rejected';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-danger" />;
        break;
      default:
        text = 'Pending';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
    }

    return (
      <span>
        Verified Calling:
        {' '}
        {text}
        {' '}
        {icon && icon}
      </span>
    );
  }

  renderVerifiedTextingStatus() {
    const { brand } = this.props;

    const status = brand ? brand.status_code : 'draft';

    let text = '';
    let icon = '';

    switch (status) {
      case 'PENDING':
        text = 'In Review';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
      case 'APPROVED':
        text = 'Approved';
        icon = <FontAwesomeIcon icon={['fas', 'fa-check-circle']} className="text-success" />;
        break;
      case 'FAILED':
        text = 'Rejected';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-danger" />;
        break;
      default:
        text = 'Pending';
        icon = <FontAwesomeIcon icon={['fas', 'fa-exclamation-circle']} className="text-secondary" />;
        break;
    }

    return (
      <span>
        Verified Texting:
        {' '}
        {text}
        {' '}
        {icon && icon}
      </span>
    );
  }

  render() {
    const { creating } = this.state;
    const { bundle, status } = this.props;

    return (
      <>
        <h4 className="mt-3">Business Verification Submission Status</h4>
        <p className="my-3">
          If you want to text from 5 or more numbers or see "Caller Verified" on your outbound calls, you will need to register your business.
        </p>

        {status.status_code === 'twilio-rejected' && status.status_text && (
          <div className="alert alert-danger text-left" role="alert">
            <ol>
              {status.status_text.split(';').map((item, index) => <li key={index}>{item}</li>) }
            </ol>
          </div>
        )}

        {!_lodash.isEmpty(bundle) && (
          <div className="table-responsive">
            <table className="table no-border">
              <thead>
                <tr>
                  <th scope="col" className="text-center">Business Profile Friendly Name</th>
                  <th scope="col" className="text-center">
                    Status
                    <span className="ml-2" id="status-tooltip">
                      <FontAwesomeIcon icon={['fas', 'fa-question-circle']} />
                    </span>
                    <ClickableTooltip
                      text="Customer profile verification can take up to 3 days or more. SHAKEN/STIR (verified calling) Verification can take up to 2 days."
                      target="status-tooltip"
                      placement="bottom"
                    />
                  </th>
                  <th scope="col" className="text-center">
                    Trust Score
                    <span className="ml-2" id="trust-score-tooltip">
                      <FontAwesomeIcon icon={['fas', 'fa-question-circle']} />
                    </span>
                    <ClickableTooltip
                      placement="bottom"
                      target="trust-score-tooltip"
                      text='A trust score is a score assigned by the campaign registry (TCR - a 3rd party that administers the wireless carrier’s new registration system). TCR uses a reputation algorithm to review specific criteria relating to your company, and then assigns a "Trust Score". The carriers use this score to represent how “trusted” your business is, and determine your daily messaging limits and throughput. The higher your trust score, the higher your daily messaging limit and throughput.'
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{bundle.friendly_name || '-'}</td>
                  <td className="text-center">
                    {this.renderStatus()}
                    {' '}
                    <br />
                    {this.renderShakenStirStatus()}
                    {' '}
                    <br />
                    {this.renderVerifiedTextingStatus()}
                  </td>
                  <td className="text-center">{status.score || '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="pull-right">
          {_lodash.isEmpty(bundle) && (
            this.state.creating ? (
              <button type="button" className="btn btn-success mr-2 disabled" disabled={creating}>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Starting ...
              </button>
            ) : (
              <button type="button" className="btn btn-success mr-2" onClick={this.onCreateClick}>Get Started</button>
            )
          )}
        </div>
      </>
    );
  }
}

ProfileStatusTab.defaultProps = {
  bundle:                {},
  status:                {},
  business_information:  {},
  address:               {},
  shaken_stir:           {},
};

ProfileStatusTab.propTypes = {
  bundle:                PropTypes.shape({}),
  status:                PropTypes.shape({}),
  business_information:  PropTypes.shape({}),
  address:               PropTypes.shape({}),
  shaken_stir:           PropTypes.shape({}),
};

export default ProfileStatusTab;
