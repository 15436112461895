import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React, { createRef }   from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment              from 'moment-timezone';
import { Container }       from 'flux/utils';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import LeadDrawerActions   from '~/actions/lead_drawer_actions';
import AppointmentEditor   from '~/components/forms/HtmlEditors/AppointmentEditor';
import TimeSelect          from '~/components/forms/dropdowns/TimeSelect';
import TimeLengthSelect    from '~/components/forms/dropdowns/TimeLengthSelect';
import TimezoneSelect      from '~/components/forms/dropdowns/TimezoneSelect';
import EmailSelect         from '~/components/forms/email_select';
import LeadHelpers         from '~/helpers/lead_helpers';
import { TeammatePicker }  from '~/components/forms/team_member_picker';
import FormFooter          from './common/form_footer';
import LeadDrawerStore     from '~/stores/lead_drawer_store';
import DateTimePicker      from '~/components/forms/DateTimePicker';
import { WarningMessage } from '~/components/warnings/GooglePermissionDeniedWarning';

const CurrentDate = Moment().startOf('day').toDate();

class AppointmentForm extends React.Component {
  static getStores() {
    return [LeadDrawerStore];
  }

  static calculateState() {
    return {
      leadDrawerState: LeadDrawerStore.getState(),
    };
  }

  constructor(props) {
    super(props);

    autoBind(this);

    const { lead } = props;

    this.state = {
      status:              lead.status,
      address:             Rails.helpers.currentTeam.address,
      appointment_at_date: Moment().local(),
      timezone:            Moment.tz.guess(),
      appointment_minutes: 60,
      cc_emails:           '',
      show_cc_emails:      false,
      owner_id:            Rails.helpers.currentUser.id,
      show_owner_dropdown: false,
      errors:              {},
      sending:             false,
      showPopover:         false,
      warningMessage:      '',
    };
    this.ref = createRef();

    // Explicitly bind the methods
    this.handleShowAdditionalEmails = this.handleShowAdditionalEmails.bind(this);
    this.handleHideAdditionalEmails = this.handleHideAdditionalEmails.bind(this);
    this.handleOwnerDropdown = this.handleOwnerDropdown.bind(this);
    this.handleSaveAppointment = this.handleSaveAppointment.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onChangeLength = this.onChangeLength.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
    this.validate = this.validate.bind(this);
    this.renderPopover = this.renderPopover.bind(this);
  }

  handleShowAdditionalEmails() {
    this.setState({ show_cc_emails: true });
  }

  handleHideAdditionalEmails(e) {
    e.preventDefault();

    this.setState({ show_cc_emails: false });
  }

  handleOwnerDropdown(e) {
    e.preventDefault();

    const { show_owner_dropdown } = this.state;

    this.setState({ show_owner_dropdown: !show_owner_dropdown });
  }

  handleSaveAppointment(e) {
    e.preventDefault();
    const { warningMessage } = this.state;

    if (warningMessage) {
      this.setState({ showPopover: true });
    } else {
      this.setState({ sending: true }, () => {
        this.createAppointment({ sendToLead: false });
      });
    }
  }

  onChangeDate(m) {
    this.setState({ appointment_at_date: m.format('YYYY-MM-DD') });
  }

  onChangeTime(opt) {
    this.setState({ appointment_at_time: opt ? opt.value : '' });
  }

  onChangeTimezone(opt) {
    this.setState({ timezone: opt ? opt.value : '' });
  }

  onChangeLength(opt) {
    this.setState({ appointment_minutes: opt ? opt.value : '' });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.createAppointment({ sendToLead: true });
  }

  renderPopover(props) {
    const { warningMessage } = this.state;

    return (
      <Popover id="dropdown-message-id" {...props}>
        <Popover.Content className="bg-grey-lightest">
          <WarningMessage
            message={warningMessage}
            onClose={() => this.setState({ showPopover: false })}
          />
        </Popover.Content>
      </Popover>
    );
  }

  createAppointment(options = {}) {
    const { lead } = this.props;
    const {
      appointment_at_time,
      appointment_at_date,
      subject,
      body,
      cc_emails,
      appointment_minutes,
      address,
      owner_id,
      timezone,
    } = this.state;

    this.setState({ sending: options.sendToLead ? 'send' : 'save' });

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      const combinedDateTime = `${appointment_at_date} ${appointment_at_time}`;
      const date = Moment.tz(combinedDateTime, timezone).seconds(0);
      const _footer = this.footer.serialize();
      const _attrs = {
        ..._footer,
        appointment: {
          subject,
          body,
          cc_emails,
          appointment_minutes,
          address,
          owner_id,
          timezone,
          appointment_at: date,
          internal:       _footer.internal,
        },
        send_to_lead: options.sendToLead,
      };

      LeadDrawerActions.createLeadAppointment(lead, _attrs)
        .catch((error) => {
          const errorMsg = error.response?.body?.errors
        || 'An error occurred while checking permissions for calendar,'
        + 'please try again later.';
          this.setState({
            warningMessage: errorMsg,
            showPopover:    true,
          });
        })
        .finally(() => {
          this.setState({ sending: false });
        });
    } else {
      this.setState({ sending: false, errors });
    }
  }

  validate() {
    const {
      appointment_at_date,
      appointment_at_time,
      appointment_minutes,
      address,
      subject,
      owner_id,
      timezone,
    } = this.state;
    const errors = {};

    if (!appointment_at_date) {
      errors.appointment_at_date = "Can't be empty";
    }

    if (!appointment_at_time) {
      errors.appointment_at_time = "Can't be empty";
    }

    if (!appointment_minutes) {
      errors.appointment_minutes = "Can't be empty";
    }

    if (!address) {
      errors.address = "Can't be empty";
    }

    if (!subject) {
      errors.subject = "Can't be empty";
    }

    if (!owner_id) {
      errors.owner_id = "Can't be empty";
    }

    if (!timezone) {
      errors.timezone = "Can't be empty";
    }

    return this.footer.validate(errors);
  }

  render() {
    const { lead } = this.props;
    const {
      sending,
      appointment_at_date,
      appointment_at_time,
      appointment_minutes,
      errors,
      address,
      show_owner_dropdown,
      owner_id,
      show_cc_emails,
      cc_emails,
      subject,
      status,
      internal,
      timezone,
      showPopover,
    } = this.state;

    const { currentUser } = Rails.helpers;
    const timezoneAbbr = LeadHelpers.tzAbbr(timezone);

    let submitButtonText = 'Send';

    if (currentUser.is_microsoft) {
      submitButtonText = 'Send with Microsoft';
    } else if (currentUser.is_google) {
      submitButtonText = 'Send with Google';
    }

    let submitActionsComponent;

    if (lead.email) {
      submitActionsComponent = (
        <div>
          <button
            type="button"
            className={classNames('btn btn-secondary btn-block-sm-down mb5', { disabled: sending })}
            onClick={this.handleSaveAppointment}
          >
            { sending === 'save' ? (
              <span>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Please Wait ...
              </span>
            ) : 'Save Appt' }
          </button>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            container={this.ref.current}
            show={showPopover}
            containerPadding={20}
            overlay={this.renderPopover}
          >
            <button type="submit" className={classNames('btn btn-primary btn-block-sm-down ml5 mb5', { disabled: sending })}>
              { sending === 'send' ? (
                <span>
                  <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                  {' '}
                  Sending ...
                </span>
              ) : submitButtonText }
            </button>
          </OverlayTrigger>
        </div>
      );
    } else {
      submitActionsComponent = (
        <button
          type="button"
          className={classNames('btn btn-secondary btn-block-sm-down mr5', { disabled: sending })}
          onClick={this.handleSaveAppointment}
        >
          { sending === 'save' ? (
            <span>
              <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
              {' '}
              Please Wait ...
            </span>
          ) : 'Save Appt' }
        </button>
      );
    }

    return (
      <form ref={(input) => this.form = input} onSubmit={this.onFormSubmit}>
        {!lead.email && (
          <div className="alert alert-info text-left mb15">
            <p className="mt10 mb10">
              {lead.name}
              {' '}
              doesn&#x27;t have an email, this appointment will be saved but not sent
              to the candidate. To send an invite, first add an email.
            </p>
          </div>
        )}

        <label htmlFor="appointment_at_date" className="d-block">Choose Appt Date and Time</label>
        <div className="row mb10">
          <div className="col-sm-4 col-md-12 col-xl-4 pl0 pr0-lg pr0-md pr0-xs-down mb5">
            <DateTimePicker
              id="appointment_at_date"
              placeholder="Select Date"
              format="LL"
              pickerType="date"
              minDate={CurrentDate}
              isInvalid={errors.appointment_at_date}
              value={appointment_at_date}
              onChange={this.onChangeDate}
            />
          </div>

          <div className="col-sm-4 col-md-6 col-xl-3 pl0 pr0 mb5">
            <TimeSelect
              placeholder={`Select Time ${timezoneAbbr ? `(${timezoneAbbr})` : ''}`}
              value={appointment_at_time}
              startHour="6"
              endHour="20"
              className={errors.appointment_at_time ? 'has-error' : ''}
              onChange={this.onChangeTime}
              isInvalid={!!errors.appointment_at_time}
            />
          </div>

          <div className="col-sm-4 col-md-6 col-xl-3 pr0 pl0-xs-down mb5">
            <TimezoneSelect
              value={timezone}
              isInvalid={!!errors.timezone}
              onChange={this.onChangeTimezone}
            />
          </div>

          <div className="col-sm-4 col-md-12 col-xl-2 pr0 pl0-lg pl0-md pl0-xs-down mb5">
            <TimeLengthSelect
              placeholder="Select Length"
              value={appointment_minutes}
              className={errors.appointment_minutes ? 'has-error' : ''}
              onChange={this.onChangeLength}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="address" className="d-block">Location</label>
          <input
            type="text"
            name="address"
            value={address}
            className={classNames('form-control', errors.address ? 'has-error' : '')}
            onChange={(e) => {
              this.setState({ address: e.target.value });
            }}
          />
        </div>

        { show_owner_dropdown && (
          <div className="form-group mb15">
            <a href="#cancel" className="pull-right" onClick={this.handleOwnerDropdown}>Cancel and hide</a>
            <TeammatePicker
              value={owner_id}
              label="Appointment Owner"
              className={classNames(errors.owner_id ? 'has-error' : '')}
              onChange={(opt) => {
                this.setState({ owner_id: opt && opt.value });
              }}
            />
          </div>
        )}

        { show_cc_emails && (
          <div className="form-group mb15">
            <div className="pull-right">
              <a href="#cancel" onClick={this.handleHideAdditionalEmails}>Cancel and hide</a>

              { !show_owner_dropdown
                && (
                <a href="#add-owner" className="ml-3" onClick={this.handleOwnerDropdown}>
                  Re-Assign Appt Owner
                </a>
                )}
            </div>

            <label htmlFor="cc_emails" className="d-block">CC</label>
            <EmailSelect
              name="cc_emails"
              placeholder="example@getbrokerkit.com"
              value={cc_emails}
              onChange={(e) => {
                this.setState({ cc_emails: _lodash.map(e, (email) => email.value).join(',') });
              }}
            />
          </div>
        )}

        <div className="form-group mb15">
          <div className="pull-right">
            {!show_owner_dropdown && !show_cc_emails && (
              <a href="#add-owner" onClick={this.handleOwnerDropdown}>
                Re-Assign Appt Owner
              </a>
            )}

            {!show_cc_emails && (
              <a href="#add-cc" className="ml-3" onClick={this.handleShowAdditionalEmails}>
                Add Additional Invitees
              </a>
            )}
          </div>

          <label htmlFor="subject" className="d-block">Meeting Title</label>
          <input
            type="text"
            name="subject"
            value={subject}
            className={classNames('form-control', errors.subject ? 'has-error' : '')}
            onChange={(e) => {
              this.setState({ subject: e.target.value });
            }}
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="body" className="d-block">Details / Message to Candidate</label>
          <AppointmentEditor
            className={errors.body ? 'tiny-mce has-error' : ''}
            onChange={(html) => {
              this.setState({ body: html });
            }}
            placeholder="Enter Text"
          />
        </div>

        <div className="mt15 mb15">
          <FormFooter
            {...this.props}
            ref={(el) => this.footer = el}
            activityType="appointment"
            submitActionsComponent={submitActionsComponent}
            status={status}
            internal={internal}
            lead={lead}
          />
        </div>
      </form>
    );
  }
}

AppointmentForm.defaultProps = {
  onSuccess: () => false,
  lead:      null,
};

AppointmentForm.propTypes = {
  onSuccess: PropTypes.func,
  lead:      PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default Container.create(AppointmentForm);
