import autoBind         from 'react-autobind';
import React            from 'react';
import { PropTypes }    from 'prop-types';
import qs               from 'qs';
import { v4 as uuidv4 } from 'uuid';

import GlobalContainer   from '~/components/global_container';
import AgentActions      from '~/actions/agent_actions';
import BulkSelectActions from '~/actions/bulk_select_actions';
import tableConfig       from '~/components/pages/agent_dashboard/table_config';
import AgentSideBar      from '~/components/navigation/agent_side_bar';
import RetentionSideBar  from '~/components/navigation/retention_side_bar';
import ErrorBoundary     from '~/components/shared/error_boundary';
import DataTable         from './agent_dashboard/data_table';
import PageScoping       from './agent_dashboard/page_scoping';
import SearchForm        from './agent_dashboard/search_form';
import TaskFiltersForm   from '~/components/pages/agent_dashboard/task_filters_form';
import AppointmentCard   from './agent_dashboard/appointment_card';
import ReportCard        from './agent_dashboard/report_card';
import widgetsConfig     from './agent_dashboard/widgets_config';
import AgentStore        from '~/stores/agent_store';
// import Report from '~/pages/Report';

// import bugsnag from 'bugsnag-js';
// import createPlugin from 'bugsnag-react';

// const bugsnagClient = bugsnag(process.env.BUGSNAG_API_KEY);
// const BugsnagErrorBoundary = bugsnagClient.use(createPlugin(React));

class AgentDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key:     uuidv4(),
      widgets: {
        hasSearch:         true,
        hasTaskSearch:     false,
        hasAppointment:    false,
        tables:            [],
      }, // manage current widgets on/off state
      scopedQueries: {},
    };

    this.unmounted = false;
    this.componentDidMount = this.componentDidMount.bind(this);
    this.reloadTimeout = null;

    autoBind(this);
  }

  /**
   * Return context to child components as props
   * https://facebook.github.io/react/docs/context.html
   * @returns {
   *   {
   *     history:  AgentDashboard.props.history,
   *     location: AgentDashboard.props.location,
   *     match:    AgentDashboard.props.match
   *   }
   * }
   */
  getChildContext() {
    const { history, location, match } = this.props;

    return {
      history,
      location,
      match,
      handleSearch:     this.handleSearch,
      handleTaskSearch: this.handleTaskSearch,
    };
  }

  componentDidMount() {
    this.configurePageWidgets();
    window.addEventListener('agent.reload', this.reload);
  }

  componentDidUpdate() {
    const { widgets } = this.state;

    document.title = widgets.title || 'Brokerkit';
  }

  componentWillUnmount() {
    this.unmounted = true;

    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }

    window.removeEventListener('agent.reload', this.reload);
  }

  handleSearch(searchData) {
    const { history } = this.props;

    BulkSelectActions.deselectLeadItems();

    const searchQuery = qs.stringify(
      { s: searchData, _t: Rails.helpers.currentTeam.id },
      { arrayFormat: 'brackets' },
    );
    const resource = GlobalContainer.product() === 'recruiting' ? 'recruiter' : GlobalContainer.product();

    history.push(
      `/${resource}/search?${decodeURI(searchQuery)}`,
      searchData,
    );

    if (GlobalContainer.product() === 'retention') {
      AgentActions.loadLeads(tableConfig.retentionSearchTable(), {
        searchData,
      });
    } else {
      AgentActions.loadLeads(tableConfig.recruitingSearchTable(), {
        searchData,
      });
    }
  }

  handleTaskSearch(searchData) {
    BulkSelectActions.deselectLeadItems();

    const { location, history } = this.props;

    const searchQuery = qs.stringify(
      { s: searchData, _t: Rails.helpers.currentTeam.id },
      { arrayFormat: 'brackets' },
    );

    history.push(
      `${location.pathname}?${decodeURI(searchQuery)}`,
      searchData,
    );

    switch (location.pathname) {
      case '/recruiter/tasks':
      case '/retention/tasks':
        AgentActions.loadLeads(tableConfig.allTasksTable(), {
          searchData,
        });
        break;
      case '/recruiter/tasks_due_today':
      case '/retention/tasks_due_today':
        AgentActions.loadLeads(tableConfig.tasksDueTodayTable(), {
          searchData,
        });
        break;
      case '/recruiter/tasks_due_later':
      case '/retention/tasks_due_later':
        AgentActions.loadLeads(tableConfig.tasksDueLaterTable(), {
          searchData,
        });
        break;
      case '/recruiter/tasks_overdue':
      case '/retention/tasks_overdue':
        AgentActions.loadLeads(tableConfig.tasksOverdueTable(), {
          searchData,
        });
        break;
      default:
        AgentActions.loadLeads(tableConfig.tasksDueTodayTable(), {
          searchData,
        });
        break;
    }
  }

  reload() {
    if (this.unmounted) return;

    // Clear existing timeout if any
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
    const { scopedQueries } = AgentStore.getState();

    // give some time before reload - not sure why but instant reload doesn't refresh data
    this.reloadTimeout = setTimeout(() => {
      if (this.unmounted) return; // Check again before setting state
      this.setState({ key: uuidv4(), scopedQueries });
    }, 200);
  }

  configurePageWidgets() {
    const { location } = this.props;

    this.setState({
      widgets: widgetsConfig(location),
    });
  }

  renderSearchForm() {
    const { widgets } = this.state;

    if (!widgets.hasSearch) {
      return null;
    }

    return <SearchForm handleSearch={this.handleSearch} />;
  }

  renderTaskFiltersForm() {
    const { widgets } = this.state;

    if (!widgets.hasTaskSearch) {
      return null;
    }

    return <TaskFiltersForm handleTaskSearch={this.handleTaskSearch} />;
  }

  renderAppointment() {
    const { widgets, key } = this.state;

    if (!widgets.hasAppointment) {
      return null;
    }

    return (
      <div className="mb15" key={`appointments-${key}`}>
        <AppointmentCard />
      </div>
    );
  }

  renderReport() {
    const { widgets } = this.state;

    if (!widgets.hasReport) {
      return null;
    }

    return (
      <div className="mb15">
        <ReportCard />
      </div>
    );
  }

  renderSidebar() {
    const { widgets } = this.state;

    switch (GlobalContainer.product()) {
      case 'retention':
        return <RetentionSideBar widgets={widgets} />;

      case 'recruiting':
      default:
        return <AgentSideBar widgets={widgets} />;
    }
  }

  renderTables() {
    const { widgets, key, scopedQueries } = this.state;

    const tablesContent = [];
    const { location } = this.props;
    const { pathname } = location;

    widgets.tables.forEach((table) => {
      const tableParams = {};

      switch (table.scope) {
        case 'agent_referrals':
          tableParams.loadReferrerStats = true;
          break;

        case 'search_results':
          tableParams.searchData = GlobalContainer.urlParams().s;
          break;

        case 'all_tasks':
        case 'today_or_past_tasks':
        case 'tasks_due_later':
        case 'tasks_overdue':
          tableParams.downloadTaskCSV = true;
          tableParams.searchData = GlobalContainer.urlParams().s;
          break;

        default:
        // nothing
      }

      const query = scopedQueries[table.scope];
      const tableSection = (
        <div className="mb15" key={`table-${table.scope}-${key}`}>
          <ErrorBoundary onRecovery={this.reload}>
            <DataTable table={table} params={tableParams} query={query} />
          </ErrorBoundary>
        </div>
      );

      tablesContent.push(tableSection);
    });

    return tablesContent;
  }

  renderPageWithLayout() {
    const { location } = this.props;
    const { pathname } = location;
    const { widgets } = this.state;

    let pageLayout = (
      <div className="row">
        <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">{this.renderSidebar()}</nav>

        <div className="right-side-page-lg">
          {this.renderSearchForm()}
          {this.renderTaskFiltersForm()}
          {this.renderAppointment()}
          {this.renderTables()}
          {this.renderReport()}
          <PageScoping widgets={widgets} />
        </div>
      </div>
    );

    // setup special layout for Agent view
    if (pathname === '/agent/dashboard') {
      pageLayout = (
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {this.renderTables()}
            <PageScoping widgets={widgets} />
          </div>
        </div>
      );
    }

    return pageLayout;
  }

  render() {
    const { widgets } = this.state;

    return (
      <GlobalContainer widgets={widgets}>
        {this.renderPageWithLayout()}
      </GlobalContainer>
    );
  }
}

AgentDashboard.childContextTypes = {
  history:          PropTypes.shape({}),
  location:         PropTypes.shape({}),
  match:            PropTypes.shape({}),
  handleSearch:     PropTypes.func,
  handleTaskSearch: PropTypes.func,
};

AgentDashboard.defaultProps = {
  history:  {},
  location: {
    pathname: null,
  },
  match: {
    params: {},
  },
};

AgentDashboard.propTypes = {
  history:  PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};

export default AgentDashboard;
