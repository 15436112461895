import autoBind            from 'react-autobind';
import React               from 'react';
import update              from 'immutability-helper';
import filesize             from 'filesize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes           from 'prop-types';

import { checkFeatureFlag }  from '~/helpers/FeatureFlagChecker';
import LeadHelpers           from '~/helpers/lead_helpers';
import LeadDrawerActions     from '~/actions/lead_drawer_actions';
import LeadDrawerStore       from '~/stores/lead_drawer_store';
import ErrorMessage          from '~/components/forms/ErrorMessage';
import SmsTextEditor         from '~/components/forms/HtmlEditors/SmsTextEditor';
import SmsTemplateSelect     from '~/components/forms/sms_template_select';
import SMSFormOnboarding     from './text_message_form_onboarding';
import FormFooter            from './common/form_footer';
import VerifiedTextingWarning from '~/components/warnings/VerifiedTextingWarning';

const MAX_MEDIA_SIZE = 5120000; // 5MB
const charsLimit = 1600;
const limitError = ['You have exceeded your limit of maximum characters allowed.'];

let leadStoreListener;

class TextMessageForm extends React.Component {
  isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

  constructor(props) {
    super(props);

    const { lead, schedule, smsMessage } = this.props;
    const { currentUser } = Rails.helpers;

    this.state = {
      id:                        smsMessage?.id || null,
      status:                    lead?.status,
      errors:                    {},
      body:                      smsMessage?.body || '',
      media:                     smsMessage?.media || null,
      media_url:                 smsMessage?.media_url || null,
      schedule:                  schedule || { customOptions: { timezone: currentUser.timezone } },
      scheduleSmsMessageAllowed: checkFeatureFlag(process.env.ALLOW_SCHEDULE_TEXTS_FLAG),
    };

    autoBind(this);
  }

  componentDidMount() {
    const { smsMessage, schedule } = this.props;

    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onLeadStoreChange);

    this.setState((prevState) => ({
      smsMessage: { ...prevState.smsMessage, ...smsMessage },
      schedule:   { ...prevState.schedule, ...schedule },
    }));
  }

  componentDidUpdate(prevProps) {
    const { smsMessage, schedule } = this.props;

    if (schedule !== prevProps.schedule || smsMessage?.id !== prevProps.smsMessage?.id) {
      this.setState((prevState) => ({
        smsMessage: { ...prevState.smsMessage, ...smsMessage },
        schedule,
      }));
    }
  }

  componentWillUnmount() {
    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  onLeadStoreChange() {
    const { leadDrawerStoreAction } = LeadDrawerStore.getState();

    if (leadDrawerStoreAction === 'createLeadSmsMessageFail' || leadDrawerStoreAction === 'updateLeadSmsMessageFail') {
      this.setState({ sending: false });
    }
  }

  handleBodyChange = (text) => {
    this.setState((prevState) => ({ body: text }), () => {
      this.validateBody();
    });
  }

  handleMediaUrlChange = (media) => {
    let media_url;

    if (media.type === 'image') {
      media_url = media.small_media_url;
    }

    this.setState((prevState) => ({
      media,
      media_url,
    }), () => {
      this.validateVideoFile();
    });
  }

  onFormSubmit(e) {
    e.persist();

    const {
      body, smsNumber, media_url, schedule, id,
    } = this.state;
    const { lead } = this.props;

    this.setState({ sending: true });

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      const _footer = this.footer.serialize();
      const _attrs = Object.assign(_footer, {
        sms_message: {
          body,
          media_url,
          schedule,
          internal:  _footer.internal,
        },
        sms_number_id: smsNumber.id,
      });

      if (id) {
        LeadDrawerActions.updateLeadSmsMessage(lead, id, _attrs);
      } else {
        LeadDrawerActions.createLeadSmsMessage(lead, _attrs);
      }
    } else {
      this.setState({ sending: false, errors });
    }
  }

  setSchedule(newSchedule) {
    this.setState((prevState) => ({
      schedule: { ...prevState.schedule, ...newSchedule },
    }));
  }

  validateVideoFile = () => {
    const { media } = this.state;

    if (media.type === 'image') return;

    const media_size = media?.animated_thumbnail ? media.gif_byte_size : media.thumbnail_byte_size;
    let error;

    if (media_size > MAX_MEDIA_SIZE) {
      error = [`File is too large (${filesize(media_size)}). Allowed ${filesize(MAX_MEDIA_SIZE)}.`];
    }

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        media: error,
      },
    }));
  }

  validateBody() {
    const { body } = this.state;
    const lengthCondition = body && charsLimit < body.length;

    const newState = update(this.state, {
      errors: {
        body: { $set: lengthCondition ? limitError : null },
      },
      disabledButton: { $set: !!lengthCondition },
    });

    this.setState(newState);
  }

  validate() {
    const { body } = this.state;
    let errors = {};

    if (!body) {
      errors.body = ["Can't be empty."];
    }

    if (body && charsLimit < body.length) {
      errors.body = limitError;
    }

    errors = this.footer.validate(errors);

    this.setState({ errors });

    return errors;
  }

  applyTemplate(template) {
    const { body } = this.state;
    let smsAttrs;

    if (template) {
      smsAttrs = {
        body:         template.body,
        templateBody: template.body,
        media:        {
          name: template.media_name,
        },
        media_url:    template.media_url,
        errors:       {},
      };
    } else {
      smsAttrs = {
        body:         null,
        templateBody: null,
        media:        null,
        media_url:    null,
        errors:       {},
      };
    }

    this.setState({
      body,
      ...smsAttrs,
    });
  }

  renderAttachment = () => {
    const { media, media_url } = this.state;

    if (!media_url) return null;

    const name = media?.name || media?.title;

    return (
      <div className="alert alert-success p10 text-left mb5" key={name}>
        <div className="pull-right">
          <a
            href="#remove-attachment"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ media: null, media_url: null });
            }}
          >
            <FontAwesomeIcon icon={['far', 'fa-times']} />
          </a>
        </div>
        <div className="text-break">
          <FontAwesomeIcon icon={['far', 'fa-file']} className="mr10" />
          <a href={media_url} target="_blank" rel="noreferrer">
            {name}
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {
      errors,
      sending,
      disabledButton,
      body,
      templateBody,
      smsNumber,
      subscription,
      status,
      smsMessage,
      internal,
      media_url,
      media,
      schedule,
      scheduleSmsMessageAllowed,
    } = this.state;
    const { lead, scheduleBtnPopoverPlacement } = this.props;
    const mediaName = media?.name || media?.title;

    if (subscription && smsNumber) {
      return (
        <form onSubmit={this.onFormSubmit}>
          {this.isRestrictionForUnverifiedNumbersEnabled && !smsNumber.verified_texting && <VerifiedTextingWarning />}

          <div className="form-group mb15">
            <label htmlFor="sms_text_service_number" className="d-block">From</label>
            <div id="service_number" className="form-control bg-grey-lightest">{LeadHelpers.renderPhoneNumber(smsNumber.service_number)}</div>
          </div>

          <div className="form-group mb15">
            <SmsTextEditor
              mms={!!media_url}
              className={errors.body ? 'tiny-mce has-error' : ''}
              placeholder="Enter your text message"
              onChange={this.handleBodyChange}
              handleMediaUrlChange={this.handleMediaUrlChange}
              value={body}
              replaceValue={templateBody}
              clearReplaceValue={() => {
                this.setState({
                  templateBody: null,
                });
              }}
            />
            {errors.body && <ErrorMessage message={errors.body} />}
          </div>

          <div className="row m0">
            <div className="col-lg-5 p0">
              {this.renderAttachment()}
              {errors.media && <ErrorMessage message={errors.media} />}
            </div>

            <div className="col-lg-4 p0 pl0-sm-down ml-auto">
              <SmsTemplateSelect
                body={body}
                media_url={media_url}
                media_name={mediaName}
                applyTemplate={this.applyTemplate}
                validateSmsTemplate={this.validate}
              />
            </div>
          </div>

          <div className="mt15 mb15">
            <FormFooter
              ref={(el) => this.footer = el}
              activityType="direct_text_message"
              status={status}
              internal={internal}
              lead={lead}
              submitButtonText="Send Text Message"
              disabledButton={sending || disabledButton || (this.isRestrictionForUnverifiedNumbersEnabled && !smsNumber.verified_texting)}
              onSubmit={this.onFormSubmit}
              showScheduleButton={scheduleSmsMessageAllowed}
              schedule={schedule}
              setSchedule={this.setSchedule}
              scheduleBtnPopoverPlacement={scheduleBtnPopoverPlacement}
              isEditingMode={!!smsMessage?.id}
            />
          </div>
        </form>
      );
    }

    return (
      <SMSFormOnboarding
        onSubscriptionSelect={(sub) => {
          this.setState({
            subscription: sub,
          });
        }}
        onPhoneSelect={(num) => {
          this.setState({
            smsNumber: num,
          });
        }}
      />
    );
  }
}

TextMessageForm.defaultProps = {
  scheduleBtnPopoverPlacement: 'bottom',
  lead:                        {},
  smsMessage:                  {},
};

TextMessageForm.propTypes = {
  scheduleBtnPopoverPlacement: PropTypes.string,
  lead:                        PropTypes.shape({}),
  smsMessage:                  PropTypes.shape({}),
};

export default TextMessageForm;
