import autoBind       from 'react-autobind';
import classNames     from 'classnames';
import React          from 'react';
import { Input }      from 'reactstrap';
import PhoneInput     from 'react-phone-input-2';
import isEmail        from 'validator/lib/isEmail';
import isMobilePhone  from 'validator/lib/isMobilePhone';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import ErrorMessage from '~/components/forms/ErrorMessage';
import Tooltipable from '~/components/effects/tooltipable';
import LeadHelpers from '~/helpers/lead_helpers';
import APIRequest from '~/lib/api_request';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHIBLE_KEY);

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: {
        first_name:   '',
        last_name:    '',
        email:        '',
        phone:        '',
        company_name: '',
        timezone:     Moment.tz.guess(),
      },
      submitting: false,
      errors:     {},
    };

    autoBind(this);
  }

  handleFirstNameChange = (e) => {
    e.preventDefault();

    const first_name = e.target.value;

    this.setState((prevState) => ({
      team: { ...prevState.team, first_name },
    }));
  };

  handleLastNameChange = (e) => {
    e.preventDefault();

    const last_name = e.target.value;

    this.setState((prevState) => ({
      team: { ...prevState.team, last_name },
    }));
  };

  handleEmailChange = (e) => {
    e.preventDefault();

    const email = e.target.value;

    this.setState((prevState) => ({
      team: { ...prevState.team, email },
    }));
  };

  handleCompanyNameChange = (e) => {
    e.preventDefault();

    const company_name = e.target.value;

    this.setState((prevState) => ({
      team: { ...prevState.team, company_name },
    }));
  };

  handlePhoneChange = (phone) => {
    this.setState((prevState) => ({
      team: { ...prevState.team, phone },
    }));
  };

  handleFormSubmit = async (e, elements, stripe) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    this.setState({ submitting: true });

    const { team } = this.state;
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);

    const errors = this.validate();

    if (payload.error) errors.stripe = [payload.error.message];

    if (_lodash.size(errors) === 0) {
      this.setState({ errors: {} });

      const params = {
        ...team,
        page: 'BuyNow',
        card: {
          id:          payload.token.id,
          client_ip:   payload.token.client_ip,
          address_zip: payload.token.card.address_zip,
          brand:       payload.token.card.brand,
          exp_month:   payload.token.card.exp_month,
          exp_year:    payload.token.card.exp_year,
          last4:       payload.token.card.last4,
        },
      };

      APIRequest.post({
        resource: '/v1/registration',
        data:     params,
      }).end((error, response) => {
        if (response.body.errors) {
          this.setState({
            errors:     response.body.errors,
            submitting: false,
          });
        } else {
          this.setState({ submitting: false }, () => {
            if (!error) {
              browserHistory.push('/users/sign_in');
              window.location.reload(); // Since sign_in page - rails view :(
            }
          });
        }
      });
    } else {
      this.setState({ submitting: false, errors });
    }
  };

  validate = () => {
    const { team } = this.state;
    const {
      first_name, last_name, email, phone, company_name,
    } =      team;

    const errors = {};

    if (!first_name) {
      errors.first_name = 'Is invalid';
    }

    if (!last_name) {
      errors.last_name = 'Is invalid';
    }

    if (!phone || !isMobilePhone(phone, ['en-US'])) {
      errors.phone = 'Is invalid';
    }

    if (!email || !isEmail(email)) {
      errors.email = 'Is invalid';
    }

    if (!company_name) {
      errors.company_name = ["Company Name can't be blank"];
    }

    return errors;
  };

  render() {
    const { team, submitting, errors } = this.state;

    const submitButtonClass = classNames(
      'btn btn-primary mt-3 mt-xl-0 mt-md-0 pull-right',
      { disabled: submitting },
    );

    return (
      <>
        <div className="sign-up">
          <div className="container-fluid">
            <div className="row sign-up--row flex-column-reverse flex-lg-row flex-md-row">
              <div className="col-12 col-lg-8 col-md-7 d-flex align-items-xl-center py-4 my-md-5 mb-5">
                <div className="col-12 col-xl-9 col-md mx-auto p-0 px-md-3 px-xl-0 ">
                  <h1>Create Your Account</h1>
                  <hr className="mb-4" />
                  <div className="my-auto">
                    <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                        {({ elements, stripe }) => (
                          <form
                            className="sign-up--form"
                            onSubmit={(e) => this.handleFormSubmit(
                              e,
                              elements,
                              stripe,
                            )}
                          >
                            <div className="row">
                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <Input
                                    type="text"
                                    className={classNames(
                                      'form-control',
                                      {
                                        'has-error':
                                          !!errors.first_name,
                                      },
                                    )}
                                    id="team_first_name"
                                    value={team.first_name}
                                    onChange={
                                      this.handleFirstNameChange
                                    }
                                    placeholder="First Name"
                                  />
                                  <label
                                    htmlFor="team_first_name"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <Input
                                    type="text"
                                    className={classNames(
                                      'form-control',
                                      {
                                        'has-error':
                                          !!errors.last_name,
                                      },
                                    )}
                                    id="team_last_name"
                                    value={team.last_name}
                                    onChange={
                                      this.handleLastNameChange
                                    }
                                    placeholder="Last Name"
                                  />
                                  <label
                                    htmlFor="team_last_name"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <Tooltipable
                                    placement="bottom"
                                    text="This must be a Google or Microsoft email address to leverage our email and calendar integration"
                                  >
                                    <Input
                                      type="text"
                                      className={classNames(
                                        'form-control',
                                        {
                                          'has-error': !!errors.email,
                                        },
                                      )}
                                      id="team_email"
                                      value={team.email}
                                      onChange={
                                        this.handleEmailChange
                                      }
                                      placeholder="Email Address"
                                    />
                                  </Tooltipable>
                                  <label
                                    htmlFor="team_email"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <PhoneInput
                                    country="us"
                                    onlyCountries={['us']}
                                    disableCountryCode
                                    disableDropdown
                                    defaultMask="(...) ...-...."
                                    value={LeadHelpers.nationalFormatPhoneNumber(
                                      team.phone,
                                    )}
                                    onChange={this.handlePhoneChange}
                                    placeholder=""
                                    specialLabel={false}
                                    inputProps={{
                                      id:        'team_phone',
                                      className: classNames(
                                        'form-control',
                                        {
                                          'has-error': !!errors.phone,
                                        },
                                      ),
                                      type:        'phone',
                                      placeholder:
                                        'Mobile Phone Number',
                                    }}
                                  />
                                  <label
                                    htmlFor="team_phone"
                                    className="form-label"
                                  >
                                    Mobile Phone Number
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <Tooltipable
                                    placement="bottom"
                                    text="The name of your brokerage, office, team or region you will be managing in this account"
                                  >
                                    <Input
                                      type="text"
                                      className={classNames(
                                        'form-control',
                                        {
                                          'has-error':
                                            !!errors.company_name,
                                        },
                                      )}
                                      id="team_company_name"
                                      value={team.company_name}
                                      onChange={
                                        this.handleCompanyNameChange
                                      }
                                      placeholder="Company Name"
                                    />
                                  </Tooltipable>
                                  <label
                                    htmlFor="team_company_name"
                                    className="form-label"
                                  >
                                    Company Name
                                  </label>
                                </div>
                                {errors.company_name && (
                                  <small
                                    className="text-red"
                                    dangerouslySetInnerHTML={{
                                      __html: errors.company_name[0],
                                    }}
                                  />
                                )}
                              </div>

                              <div className="form-group col-lg-6 col-md-12">
                                <div className="d-flex flex-column-reverse">
                                  <div
                                    id="card-element"
                                    className={classNames(
                                      'form-control',
                                      { 'has-error': !!errors.stripe },
                                    )}
                                  >
                                    <CardElement />
                                  </div>
                                  <label
                                    htmlFor="team_card_details"
                                    className="form-label"
                                  >
                                    Card Details
                                  </label>
                                </div>
                                {errors.stripe && (
                                  <ErrorMessage
                                    message={errors.stripe}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="row align-items-center signup-TOS">
                              <div className="col-xl-8 col-md-8">
                                <div className="mb-3">
                                  <p className="signup-TOS-links">
                                    <span className="text-black">Please Note:</span>
                                    {' '}
                                    By signing up, you agree to our
                                    {' '}
                                    <a
                                      href="/home/terms-of-service/"
                                      target="_blank"
                                    >
                                      Terms Of Service
                                    </a>
                                    {' '}
                                    and
                                    {' '}
                                    <a
                                      href="/home/privacy-policy/"
                                      target="_blank"
                                    >
                                      Privacy Policy
                                    </a>
                                    .
                                  </p>

                                  <p className="signup-TOS-links">
                                    As you become a part of our community, you may receive
                                    {' '}
                                    occasional updates via text & email messages.
                                    {' '}
                                    In certain cases, communication surcharges may apply.
                                  </p>
                                </div>
                              </div>

                              <div className="col-xl-4 col-md-4">
                                {submitting ? (
                                  <button
                                    type="submit"
                                    className={submitButtonClass}
                                    disabled={submitting}
                                  >
                                    Creating...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className={submitButtonClass}
                                  >
                                    Sign Up
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        )}
                      </ElementsConsumer>
                    </Elements>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-5 sign-up--content align-items-center d-flex py-4">
                <div className="col-12 col-xl-10 p-0 mx-auto sign-up-wrapper">
                  <div className="brokerkit-logo text-center my-md-5 mt-lg-0 mb-lg-5">
                    <img
                      src={Rails.assets['logo.png']}
                      alt="Logo"
                      width="210"
                      height="38"
                    />
                  </div>

                  <h2 className="text-center text-white font-28 mb-3 d-none d-lg-block d-md-block">
                    Why Brokerkit?
                  </h2>

                  <div className="why-borkerkit d-none d-lg-block d-md-block">
                    <div className="why-borkerkit-wrapper">
                      <ul className="p-0 m-0">
                        <li>
                          <i
                            className="fa fa-angle-double-right mr-2"
                            aria-hidden="true"
                          />
                          Accelerate your real estate brokerage growth
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-double-right mr-2"
                            aria-hidden="true"
                          />
                          Improve your agent recruiting and retention
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-double-right mr-2"
                            aria-hidden="true"
                          />
                          Save time by automating routine tasks
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
