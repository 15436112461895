import { ReduceStore }       from 'flux/utils';
import AppDispatcher         from '~/dispatchers/app_dispatcher';
import BulkSelectActionTypes from '~/types/bulk_select_action_types';

class BulkSelectStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      // table scope for data below is stored as Object key
      scopedSelItems:         {}, // store different selected table items
      scopedSelAllItems:      {},
      unselectedItems:        {}, // store unselected table items when all items optons is set
      bulkSelectStoreAction:  null, // store last dispatch action name
    };
  }

  selectAllItems(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.scopedSelAllItems[tableScope] = true;
    newState.unselectedItems[tableScope] = {};
    newState.bulkSelectStoreAction = 'selectAllItems';

    return newState;
  }

  deselectAllItems(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.scopedSelAllItems[tableScope] = false;
    newState.scopedSelItems[tableScope] = {};
    newState.unselectedItems[tableScope] = {};
    newState.bulkSelectStoreAction = 'deselectAllItems';

    return newState;
  }

  selectLeadItems(state, action) {
    const newState = { ...state };
    const { tableScope, leadItems } = action;

    newState.bulkSelectStoreAction = 'selectLeadItems';

    if (!newState.scopedSelItems[tableScope]) {
      newState.scopedSelItems[tableScope] = {};
      newState.unselectedItems[tableScope] = {};
    }

    leadItems.forEach((leadItem) => {
      const itemKey = `lead-item-${leadItem.id}`;

      if (!newState.unselectedItems[tableScope][itemKey]) {
        newState.scopedSelItems[tableScope][itemKey] = leadItem;
      }
    });

    return newState;
  }

  deselectLeadItems(state, action) {
    const newState = { ...state };
    const { tableScope, leadItems } = action;

    newState.bulkSelectStoreAction = 'deselectLeadItems';

    if (!tableScope
        || !leadItems
        || !newState.scopedSelItems[tableScope]) {
      newState.scopedSelItems = {};
      newState.scopedSelAllItems = {};
      newState.unselectedItems = {};

      return newState;
    }

    if (newState.scopedSelAllItems[tableScope]) {
      newState.scopedSelAllItems[tableScope] = false;
      newState.scopedSelItems[tableScope] = {};
      newState.unselectedItems[tableScope] = {};

      return newState;
    }

    leadItems.forEach((leadItem) => {
      const itemKey = `lead-item-${leadItem.id}`;
      delete newState.scopedSelItems[tableScope][itemKey];
    });

    return newState;
  }

  selectLeadItem(state, action) {
    const newState = { ...state };
    const { tableScope, leadItem } = action;
    const itemKey = `lead-item-${leadItem.id}`;

    newState.bulkSelectStoreAction = 'selectLeadItem';

    if (!newState.scopedSelItems[tableScope]) {
      newState.scopedSelItems[tableScope] = {};
      newState.unselectedItems[tableScope] = {};
    }

    newState.scopedSelItems[tableScope][itemKey] = leadItem;
    delete newState.unselectedItems[tableScope][itemKey];

    return newState;
  }

  deselectLeadItem(state, action) {
    const newState = { ...state };
    const { tableScope, leadItem } = action;

    newState.bulkSelectStoreAction = 'deselectLeadItem';

    if (!tableScope || !leadItem || !newState.scopedSelItems[tableScope]) {
      return newState;
    }

    const itemKey = `lead-item-${leadItem.id}`;

    delete newState.scopedSelItems[tableScope][itemKey];

    if (!newState.unselectedItems[tableScope]) {
      newState.scopedSelAllItems[tableScope] = {};
      newState.unselectedItems[tableScope] = {};
    }

    if (newState.scopedSelAllItems[tableScope]) {
      newState.unselectedItems[tableScope][itemKey] = leadItem;

      return newState;
    }

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case BulkSelectActionTypes.SELECT_ALL_ITEMS:
        return this.selectAllItems(state, action);

      case BulkSelectActionTypes.DESELECT_ALL_ITEMS:
        return this.deselectAllItems(state, action);

      case BulkSelectActionTypes.SELECT_LEAD_ITEMS:
        return this.selectLeadItems(state, action);

      case BulkSelectActionTypes.DESELECT_LEAD_ITEMS:
        return this.deselectLeadItems(state, action);

      case BulkSelectActionTypes.SELECT_LEAD_ITEM:
        return this.selectLeadItem(state, action);

      case BulkSelectActionTypes.DESELECT_LEAD_ITEM:
        return this.deselectLeadItem(state, action);

      default:
        return state;
    }
  }
}

export default new BulkSelectStore();
