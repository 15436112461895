import tableConfig from './table_config';

function widgetsConfig(location) {
  const { pathname } = location;

  const availableWidgets = {
    hasSearch:      true,
    hasTaskSearch:  false,
    hasAppointment: false,
    hasReport:      false,
    tables:         [],
  };

  switch (pathname) {
    case '/recruiter/leads':
      availableWidgets.title = 'Leads - Brokerkit';
      availableWidgets.tables = [tableConfig.recruitingLeadsTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/recruiter/tasks':
    case '/retention/tasks':
      availableWidgets.title = 'All Tasks - Brokerkit';
      availableWidgets.tables = [tableConfig.allTasksTable()];
      availableWidgets.leadsScope = false;
      availableWidgets.hasSearch = false;
      availableWidgets.hasTaskSearch = true;
      break;

    case '/recruiter/tasks_due_today':
    case '/retention/tasks_due_today':
      availableWidgets.title = 'Tasks Due Today - Brokerkit';
      availableWidgets.tables = [tableConfig.tasksDueTodayTable()];
      availableWidgets.leadsScope = false;
      availableWidgets.hasSearch = false;
      availableWidgets.hasTaskSearch = true;
      break;

    case '/recruiter/tasks_due_later':
    case '/retention/tasks_due_later':
      availableWidgets.title = 'Tasks Due Later - Brokerkit';
      availableWidgets.tables = [tableConfig.tasksDueLaterTable()];
      availableWidgets.leadsScope = false;
      availableWidgets.hasSearch = false;
      availableWidgets.hasTaskSearch = true;
      break;

    case '/recruiter/tasks_overdue':
    case '/retention/tasks_overdue':
      availableWidgets.title = 'Tasks Overdue - Brokerkit';
      availableWidgets.tables = [tableConfig.tasksOverdueTable()];
      availableWidgets.leadsScope = false;
      availableWidgets.hasSearch = false;
      availableWidgets.hasTaskSearch = true;
      break;

    case '/recruiter/backlog':
      availableWidgets.title = 'Backlog - Brokerkit';
      availableWidgets.tables = [tableConfig.backlogTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/recruiter/search':
      availableWidgets.title = 'Search - Brokerkit';
      availableWidgets.tables = [tableConfig.recruitingSearchTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/recruiter/reports':
      availableWidgets.title = 'Report - Brokerkit';
      availableWidgets.hasSearch  = false;
      availableWidgets.hasReport  = true;
      availableWidgets.leadsScope = true;
      break;

    case '/agent/dashboard':
      availableWidgets.title = 'Agent Dashboard - Brokerkit';
      availableWidgets.tables = [tableConfig.referralsTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/recruiter':
    case '/recruiter/today':
      availableWidgets.hasAppointment = true;
      availableWidgets.title = 'Today - Brokerkit';
      availableWidgets.tables = [tableConfig.todayTable(), tableConfig.followUpTable()];
      availableWidgets.leadsScope = false;
      availableWidgets.tasksScope = true;
      break;

    case '/retention/team':
      availableWidgets.title  = 'Team - Brokerkit';
      availableWidgets.tables = [tableConfig.retentionTeamTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/retention/backlog':
      availableWidgets.title = 'Backlog - Brokerkit';
      availableWidgets.tables = [tableConfig.backlogRetentionTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/retention/search':
      availableWidgets.title = 'Search - Brokerkit';
      availableWidgets.tables = [tableConfig.retentionSearchTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/retention/reports':
      availableWidgets.title = 'Report - Brokerkit';
      availableWidgets.hasSearch = false;
      availableWidgets.hasReport = true;
      availableWidgets.leadsScope = true;
      break;

    case '/retention':
    case '/retention/today':
      availableWidgets.title = 'Today - Brokerkit';
      availableWidgets.tables = [
        tableConfig.retentionFollowUps(),
        tableConfig.onboardingTable(),
        tableConfig.todayRetentionTable(),
      ];
      availableWidgets.leadsScope = true;
      break;

    case '/archived/search':
      availableWidgets.title = 'Search - Brokerkit';
      availableWidgets.tables = [tableConfig.archivedSearchTable()];
      availableWidgets.leadsScope = true;
      break;

    case '/archived':
      availableWidgets.title = 'Archived - Brokerkit';
      availableWidgets.tables = [tableConfig.archivedTable()];
      availableWidgets.leadsScope = true;
      break;

    default:
      availableWidgets.hasAppointment = true;
      availableWidgets.title = 'Today - Brokerkit';
      availableWidgets.tables = [tableConfig.todayTable(), tableConfig.followUpTable()];
      availableWidgets.leadsScope = true;
  }

  return availableWidgets;
}

export default widgetsConfig;
