import React, { useRef, useEffect, memo } from 'react';
import { areEqual } from 'react-window';

const Item = memo(({ index, style, data }) => {
  const itemRef = useRef(null);
  const { height, ...restStyle } = style;
  const isLoading = !data.isItemLoaded(index);

  useEffect(() => {
    if (itemRef.current && data.setRowHeight) {
      const itemHeight = itemRef.current.clientHeight || data.itemSize;
      data.setRowHeight(index, itemHeight);
    }
  }, [index, itemRef.current, data.setRowHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (itemRef.current && data.setRowHeight) {
        const itemHeight = itemRef.current.clientHeight || data.itemSize;
        data.setRowHeight(index, itemHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={itemRef} style={restStyle}>
      {data.renderItem(data.items[index], isLoading, index)}
    </div>
  );
}, areEqual);

export default Item;
