import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import PhoneInput          from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import isEmail             from 'validator/lib/isEmail';

import BusinessContactsActions from '~/actions/business_contacts_actions';
import BusinessContactsStore   from '~/stores/business_contacts_store';
import LeadHelpers             from '~/helpers/lead_helpers';
import JobPositionSelect       from '~/components/forms/job_position_select';
import Tooltipable             from '~/components/effects/tooltipable';
import ErrorAlert             from '~/components/shared/error_alert';

class BusinessContactsTab extends React.Component {
  constructor(props) {
    super(props);

    const { authorized_representative_1, authorized_representative_2 } = this.props;
    const representative_1 = authorized_representative_1;
    const representative_2 = authorized_representative_2;

    this.state = {
      errors:                      {},
      creating:                    false,
      saving:                      false,
      no_second_representative:    _lodash.isEmpty(representative_2),
      authorized_representative_1: {
        first_name:     representative_1.first_name || '',
        last_name:      representative_1.last_name || '',
        email:          representative_1.email || '',
        business_title: representative_1.business_title || '',
        job_position:   representative_1.job_position || '',
        phone_number:   representative_1.phone_number || '',
      },
      authorized_representative_2: {
        first_name:     representative_2.first_name || '',
        last_name:      representative_2.last_name || '',
        email:          representative_2.email || '',
        business_title: representative_2.business_title || '',
        job_position:   representative_2.job_position || '',
        phone_number:   representative_2.phone_number || '',
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to BusinessContactsStore change events and store reference
    this.listener = BusinessContactsStore.addListener(this.onBusinessContactsStoreChange);
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  onBusinessContactsStoreChange() {
    const {
      errors,
      creating,
      saving,
      authorized_representative_1,
      authorized_representative_2,
      lastBusinessContactsStoreAction,
      tabName,
    } = BusinessContactsStore.getState();

    const { setTab } = this.props;

    let newState = {
      errors,
      creating,
      saving,
    };

    if (lastBusinessContactsStoreAction === 'createContactsDone'
        || lastBusinessContactsStoreAction === 'updateContactsDone') {
      GlobalContainer.notify('Your Business Contacts was saved.');
      newState = { ...newState, authorized_representative_1, authorized_representative_2 };
    } else if (lastBusinessContactsStoreAction === 'createContactsFail'
      || lastBusinessContactsStoreAction === 'updateContactsFail') {
      newState.submitError = errors || 'Failed to save Business Contact.';
    }

    if (saving || creating) newState.submitError = null;
    this.setState(newState);
    setTimeout(() => {
      if (tabName) setTab(tabName);
    }, 0)
  }

  onCreateClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();

    const { no_second_representative, authorized_representative_1, authorized_representative_2 } = this.state;
    const data = {
      no_second_representative,
      authorized_representative_1: { ...authorized_representative_1 },
      authorized_representative_2: { ...(!no_second_representative && authorized_representative_2) },
    };

    if (_lodash.size(errors) === 0) {
      BusinessContactsActions.createContacts(data, tabName);
    } else {
      this.setState({ errors });
    }
  }

  onSaveClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();

    const { no_second_representative, authorized_representative_1, authorized_representative_2 } = this.state;
    const data = {
      no_second_representative,
      authorized_representative_1: { ...authorized_representative_1 },
      authorized_representative_2: { ...(!no_second_representative && authorized_representative_2) },
    };

    if (_lodash.size(errors) === 0) {
      BusinessContactsActions.updateContacts(data, tabName);
    } else {
      this.setState({ errors });
    }
  }

  setFormField(model, field, value) {
    this.setState((prevState) => ({
      [model]: {
        ...prevState[model],
        [field]: value,
      },
    }));
  }

  validate() {
    const { no_second_representative, authorized_representative_1, authorized_representative_2 } = this.state;

    const errors = {};

    if (!authorized_representative_1.first_name) {
      errors.first_name1 = 'Is invalid';
    }

    if (!authorized_representative_1.last_name) {
      errors.last_name1 = 'Is invalid';
    }

    if (!authorized_representative_1.business_title) {
      errors.business_title1 = 'Is invalid';
    }

    if (!authorized_representative_1.job_position) {
      errors.job_position1 = 'Is invalid';
    }

    if (!authorized_representative_1.phone_number || !isMobilePhone(authorized_representative_1.phone_number, ['en-US'])) {
      errors.phone_number1 = 'Is invalid';
    }

    if (!authorized_representative_1.email || !isEmail(authorized_representative_1.email)) {
      errors.email1 = 'Is invalid';
    }

    if (!no_second_representative) {
      if (!authorized_representative_2.first_name) {
        errors.first_name2 = 'Is invalid';
      }

      if (!authorized_representative_2.last_name) {
        errors.last_name2 = 'Is invalid';
      }

      if (!authorized_representative_2.business_title) {
        errors.business_title2 = 'Is invalid';
      }

      if (!authorized_representative_2.job_position) {
        errors.job_position2 = 'Is invalid';
      }

      if (!authorized_representative_2.phone_number || !isMobilePhone(authorized_representative_2.phone_number, ['en-US'])) {
        errors.phone_number2 = 'Is invalid';
      }

      if (!authorized_representative_2.email || !isEmail(authorized_representative_2.email)) {
        errors.email2 = 'Is invalid';
      }
    }

    return errors;
  }

  renderAuthorizedRepresentative1(disableStatus) {
    const { errors, authorized_representative_1 } = this.state;

    return (
      <>
        <h4 className="my-3">Authorized Representative #1</h4>
        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="first_name" className="label">First Name</label>
            <input
              type="text"
              id="first_name_1"
              placeholder="Enter First Name"
              value={authorized_representative_1.first_name}
              onChange={(val) => this.setFormField('authorized_representative_1', 'first_name', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.first_name1 })}
              disabled={disableStatus}
            />
            <Tooltipable
              placement="bottom"
              target="first_name_1"
              text="Enter the name of a person publicly associated with your business, such as on your website or other publicly accessible documents. This person should be authorized to represent your business."
            />
          </div>

          <div className="form-group col">
            <label htmlFor="last_name" className="label">Last Name</label>
            <input
              type="text"
              id="last_name"
              placeholder="Enter Last Name"
              value={authorized_representative_1.last_name}
              onChange={(val) => this.setFormField('authorized_representative_1', 'last_name', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.last_name1 })}
              disabled={disableStatus}
            />
          </div>
        </div>

        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="email" className="label">Email</label>
            <input
              type="text"
              id="email"
              placeholder="Enter Email"
              value={authorized_representative_1.email}
              onChange={(val) => this.setFormField('authorized_representative_1', 'email', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.email1 })}
              disabled={disableStatus}
            />
          </div>

          <div className="form-group col">
            <label htmlFor="business_title" className="label">Business Title</label>
            <input
              type="text"
              id="business_title"
              placeholder="Enter Business Title"
              value={authorized_representative_1.business_title}
              onChange={(val) => this.setFormField('authorized_representative_1', 'business_title', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.business_title1 })}
              disabled={disableStatus}
            />
          </div>
        </div>

        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="job_position" className="label">Job Position</label>
            <JobPositionSelect
              value={authorized_representative_1.job_position}
              placeholder="Select Job Position"
              clearable
              id="job_position"
              onChange={(val) => this.setFormField('authorized_representative_1', 'job_position', val ? val.value : '')}
              className={classNames({ 'has-error': !!errors.job_position1 })}
              disabled={disableStatus}
            />
          </div>

          <div className="form-group col">
            <label htmlFor="phone_number" className="label">Phone Number</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              specialLabel={false}
              inputProps={{
                id:          'phone_number',
                placeholder: '(111) 111-1111',
                required:    true,
                className:   classNames('phone form-control', { 'has-error': !!errors.phone_number1 }),
              }}
              value={LeadHelpers.nationalFormatPhoneNumber(authorized_representative_1.phone_number)}
              onChange={(val) => this.setFormField('authorized_representative_1', 'phone_number', val)}
              disabled={disableStatus}
            />
          </div>
        </div>
      </>
    );
  }

  renderAuthorizedRepresentative2(disableStatus) {
    const { errors, authorized_representative_2 } = this.state;

    return (
      <>
        <h4 className="my-3">Authorized Representative #2</h4>
        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="first_name" className="label">First Name</label>
            <input
              type="text"
              id="first_name_2"
              placeholder="Enter First Name"
              value={authorized_representative_2.first_name}
              onChange={(val) => this.setFormField('authorized_representative_2', 'first_name', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.first_name2 })}
              disabled={disableStatus}
            />
            <Tooltipable
              placement="bottom"
              target="first_name_2"
              text="Enter the name of a person publicly associated with your business, such as on your website or other publicly accessible documents. This person should be authorized to represent your business."
            />
          </div>

          <div className="form-group col">
            <label htmlFor="last_name" className="label">Last Name</label>
            <input
              type="text"
              id="last_name"
              placeholder="Enter Last Name"
              value={authorized_representative_2.last_name}
              onChange={(val) => this.setFormField('authorized_representative_2', 'last_name', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.last_name2 })}
              disabled={disableStatus}
            />
          </div>
        </div>

        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="email" className="label">Email</label>
            <input
              type="text"
              id="email"
              placeholder="Enter Email"
              value={authorized_representative_2.email}
              onChange={(val) => this.setFormField('authorized_representative_2', 'email', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.email2 })}
              disabled={disableStatus}
            />
          </div>

          <div className="form-group col">
            <label htmlFor="business_title" className="label">Business Title</label>
            <input
              type="text"
              id="business_title"
              placeholder="Enter Business Title"
              value={authorized_representative_2.business_title}
              onChange={(val) => this.setFormField('authorized_representative_2', 'business_title', val.target.value)}
              className={classNames('form-control', { 'has-error': !!errors.business_title2 })}
              disabled={disableStatus}
            />
          </div>
        </div>

        <div className="row mb-3 text-center">
          <div className="form-group col">
            <label htmlFor="job_position" className="label">Job Position</label>
            <JobPositionSelect
              value={authorized_representative_2.job_position}
              placeholder="Select Job Position"
              clearable
              id="job_position"
              onChange={(val) => this.setFormField('authorized_representative_2', 'job_position', val ? val.value : '')}
              className={classNames({ 'has-error': !!errors.job_position2 })}
              disabled={disableStatus}
            />
          </div>

          <div className="form-group col">
            <label htmlFor="phone_number" className="label">Phone Number</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              specialLabel={false}
              inputProps={{
                id:          'phone_number',
                placeholder: '(111) 111-1111',
                required:    true,
                className:   classNames('phone form-control', { 'has-error': !!errors.phone_number2 }),
              }}
              value={LeadHelpers.nationalFormatPhoneNumber(authorized_representative_2.phone_number)}
              onChange={(val) => this.setFormField('authorized_representative_2', 'phone_number', val)}
              disabled={disableStatus}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      errors, no_second_representative, authorized_representative_1, saving, creating, submitError,
    } = this.state;
    const { bundle, status } = this.props;
    const disableStatus = _lodash.includes(['in-review', 'twilio-approved'], status.status_code);

    return (
      <>
        <p>Your authorized representative could be contacted to verify your identities. Please ensure they are contactable via email and phone.</p>

        <form>
          {this.renderAuthorizedRepresentative1(disableStatus)}

          <div className="custom-control custom-checkbox pl20 mb-3">
            <input
              type="checkbox"
              className="custom-control-input"
              name="no_second_representative"
              id="no_second_representative"
              checked={no_second_representative}
              onChange={(e) => {
                this.setState({ no_second_representative: e.target.checked });
              }}
              disabled={disableStatus}
            />
            <label className="custom-control-label" htmlFor="no_second_representative">
              I do not have a second authorized representative
            </label>
          </div>

          {!no_second_representative
            && this.renderAuthorizedRepresentative2(disableStatus)}

          <div className="row">
            <div className="col-8">
              {submitError && <ErrorAlert
                heading="Oops, Unable to Submit your Business Profile"
                message={submitError}
              />}
            </div>
            <div className="col-4 pl-0 pr-3">
              <div className="pull-right">
                {_lodash.isEmpty(authorized_representative_1) ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success mr-2"
                      disabled={creating}
                      onClick={(e) => {
                        this.onCreateClick(e, 'business_profile');
                      }}
                    >
                      Back
                    </button>

                    {creating ? (
                      <button type="button" className="btn btn-success mr-2 disabled" disabled={creating}>
                        <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={this.onCreateClick}
                      >
                        Save
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={creating}
                      onClick={(e) => {
                        this.onCreateClick(e, 'verified_texting');
                      }}
                    >
                      Next
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-success mr-2"
                      disabled={saving || disableStatus}
                      onClick={(e) => {
                        this.onSaveClick(e, 'business_profile');
                      }}
                    >
                      Back
                    </button>

                    {saving ? (
                      <button type="button" className="btn btn-success mr-2 disabled" disabled={saving}>
                        <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classNames('btn btn-success mr-2', { disabled: disableStatus })}
                        disabled={disableStatus}
                        onClick={this.onSaveClick}
                      >
                        Save
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={saving || disableStatus}
                      onClick={(e) => {
                        this.onSaveClick(e, 'verified_texting');
                      }}
                    >
                      Next
                    </button>
                  </>
                )}

              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

BusinessContactsTab.defaultProps = {
  bundle:                      {},
  status:                      {},
  business_information:        {},
  address:                     {},
  authorized_representative_1: {},
  authorized_representative_2: {},
};

BusinessContactsTab.propTypes = {
  bundle:                      PropTypes.shape({}),
  status:                      PropTypes.shape({}),
  business_information:        PropTypes.shape({}),
  address:                     PropTypes.shape({}),
  authorized_representative_1: PropTypes.shape({}),
  authorized_representative_2: PropTypes.shape({}),
};

export default BusinessContactsTab;
