import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker }    from 'react-color';
import ajaxUploader        from 'simple-ajax-uploader';

import { TeammatePicker }    from '~/components/forms/team_member_picker';
import APIRequest            from '~/lib/api_request';
import AccountActions        from '~/actions/account_actions';
import AccountStore          from '~/stores/account_store';
import LandingPageHtmlEditor from '~/components/forms/HtmlEditors/LandingPageHtmlEditor';

import { initWebSpellChecker } from '~/lib/web_spell_checker';

class LandingPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingPage:              props.landingPage,
      uploadingIntroBackground: false,
      accountState:             AccountStore.getState(),
      errors:                   {},
      showSketchPicker:         false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.uploader = new ajaxUploader.SimpleUpload({
      button:        'landing_page_intro_background',
      url:           `${Rails.apiUrl}/api/v1/landing_page`,
      name:          'landing_page[intro_background]',
      method:        'PUT',
      responseType:  'json',
      customHeaders: APIRequest.apiRequiredHeaders(),
      maxSize:       10240, // kilobytes
      onSubmit:      this.onUploadSubmit,
      onProgress:    this.onUploadProgress,
      onComplete:    this.onUploadComplete,
      onSizeError:   this.onUploadSizeError,
    });

    // listen to LeadDrawerStore change events and store reference
    this.listener = AccountStore.addListener(
      this.onAccountStoreChange,
    );
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.form !== prevState.form) {
      const introElement = this.landingPageIntroInput
      if (introElement) initWebSpellChecker(introElement);
    }
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  handleActiveChange(e) {
    const {
      landingPage: { active },
    } = this.state;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        active: !active,
      },
    }));
  }

  handleHeaderChange(e) {
    e.preventDefault();

    const header = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        header,
      },
    }));
  }

  handleTitleChange(e) {
    e.preventDefault();

    const title = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        title,
      },
    }));
  }

  handleUserIntroductionChange(e) {
    e.preventDefault();

    const user_introduction = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        user_introduction,
      },
    }));
  }

  handleIntroChange(e) {
    e.preventDefault();

    const intro = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        intro,
      },
    }));
  }

  handleIntroBackgroundChange(e) {
    if (e.target.files.length === 0) return;

    const intro_background = e.target.files[0];

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        intro_background,
      },
    }));
  }

  handleTeamLeaderChange(selectedItem) {
    const { value } = selectedItem;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        team_leader_id: value,
      },
    }));
  }

  handleColorChange(color) {
    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        color: color.hex,
      },
    }));
  }

  handlePhoneChange(e) {
    e.preventDefault();

    const phone = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        phone,
      },
    }));
  }

  handleBodyChange(body) {
    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        body,
      },
    }));
  }

  handleFBPixelIDChange(e) {
    e.preventDefault();

    const fb_pixel_id = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        fb_pixel_id,
      },
    }));
  }

  handleGATrackingIDChange(e) {
    e.preventDefault();

    const ga_tracking_id = e.target.value;

    this.setState((prevState) => ({
      landingPage: {
        ...prevState.landingPage,
        ga_tracking_id,
      },
    }));
  }

  handleSketchPicker(e) {
    e.preventDefault();

    const { showSketchPicker } = this.state;

    this.setState({ showSketchPicker: !showSketchPicker });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { landingPage } = this.state;

    const landingPageData = {
      active:            landingPage.active,
      header:            landingPage.header,
      title:             landingPage.title,
      intro:             landingPage.intro,
      team_leader_id:    landingPage.team_leader_id,
      body:              landingPage.body,
      phone:             landingPage.phone,
      fb_pixel_id:       landingPage.fb_pixel_id,
      ga_tracking_id:    landingPage.ga_tracking_id,
      user_introduction: landingPage.user_introduction,
      color:             landingPage.color,
    };

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      this.setState({ form: false });

      AccountActions.updateLandingPage(landingPageData);
    } else {
      this.setState({ errors });
    }
  }

  onAccountStoreChange() {
    const accountState = AccountStore.getState();
    const { landingPage, lastAccountStoreAction } = accountState;

    const newState = {
      accountState,
    };

    if (landingPage) {
      newState.landingPage = landingPage;
    }

    if (lastAccountStoreAction === 'updateLandingPageDone') {
      GlobalContainer.notify('Team landing page was updated.');
    }

    this.setState(newState);
  }

  onUploadSubmit(filename, extension, uploadBtn, fileSize) {
    this.setState({
      uploadingIntroBackground: true,
    });

    return true;
  }

  onUploadProgress(percentage) {}

  onUploadComplete(filename, response, uploadBtn, fileSize) {
    this.setState({
      landingPage:              response,
      uploadingIntroBackground: false,
    });
  }

  onUploadSizeError(filename, fileSize) {
    GlobalContainer.notify(
      'Background image size could not be larger than 10 megabytes.',
      'error',
    );
  }

  hideSketchPicker() {
    this.setState({ showSketchPicker: false });
  }

  validate() {
    const { landingPage } = this.state;

    const errors = {};

    if (!landingPage.title) {
      errors.title = "Can't be empty";
    }

    if (!landingPage.user_introduction) {
      errors.user_introduction = "Can't be empty";
    }

    this.setState({ errors });

    return errors;
  }

  showForm(e) {
    e.preventDefault();
    this.setState({ form: true, showSketchPicker: false });
  }

  hideForm(e) {
    e.preventDefault();
    this.setState({ form: false, showSketchPicker: false, errors: {} });
  }

  renderIntroBackground() {
    const { landingPage, uploadingIntroBackground } = this.state;

    let backgroundImageContent;

    if (landingPage && landingPage.intro_background) {
      backgroundImageContent = (
        <div
          className="rounded mb10"
          style={{
            backgroundImage:    `url('${landingPage.intro_background.large}')`,
            backgroundRepeat:   'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:     'cover',
            minHeight:          '10em',
          }}
        />
      );
    }

    if (uploadingIntroBackground) {
      backgroundImageContent = (
        <div className="mb10">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    return backgroundImageContent;
  }

  render() {
    const {
      form, landingPage, accountState, errors, showSketchPicker,
    } = this.state;
    const { updating } = accountState;

    const submitButtonClass = classNames('btn btn-primary', {
      disabled: updating,
    });

    return (
      <form method="POST" className="landing-page-form" onSubmit={this.handleFormSubmit}>
        {form ? (
          <div>
            <h4 className="mb10">Team Landing Page</h4>
            <p className="text-grey mb10">
              Use this for job ads and any brokerage marketing. Any
              customizations will also affect agent landing pages.
            </p>
            <div className="mt10">
              <button
                type="button"
                className="mr10 btn btn-secondary"
                onClick={this.hideForm}
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-edit']}
                  className="fa-sm mr5"
                />
                Hide Customization Options
              </button>
              <button
                type="submit"
                className={classNames(submitButtonClass, 'mr10')}
                disabled={updating}
              >
                Save Changes
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h4 className="mb10">Team Landing Page</h4>
            <p className="text-grey mb10">
              Use this for job ads and any brokerage marketing. Any
              customizations will also affect agent landing pages.
            </p>
            <div className="mb10">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.showForm}
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-edit']}
                  className="fa-sm mr5"
                />
                Show Customization Options
              </button>
            </div>

            <div className="">
              {landingPage && landingPage.active ? (
                <div>
                  <a
                    href={landingPage.url}
                    target="_blank"
                    className="mr10"
                    rel="noreferrer"
                  >
                    <strong>{landingPage.url}</strong>
                  </a>
                  <a
                    href={landingPage.url}
                    target="_blank"
                    className="ml10 btn btn-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'fa-external-link-alt']}
                      className="mr5"
                    />
                    Preview
                  </a>
                </div>
              ) : (
                <span>
                  {landingPage.url}
                  {' '}
                  <span className="text-muted">(inactive)</span>
                </span>
              )}
            </div>
          </div>
        )}

        <div className={classNames(!form && 'd-none')}>
          <div className="mt15">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="enable-landing-page"
                onChange={() => {}}
                checked={landingPage.active ? 'checked' : ''}
                onClick={this.handleActiveChange}
              />
              <label
                className="custom-control-label"
                htmlFor="enable-landing-page"
                style={{ marginTop: 2 }}
              >
                Enable Landing Page
              </label>
            </div>
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_header"
              className="col-form-label"
            >
              Team Page Headline
            </label>
            <input
              type="text"
              className="form-control"
              id="landing_page_header"
              ref={(input) => (this.landingPageHeaderInput = input)}
              name="landing_page[header]"
              placeholder="Team Page Headline"
              value={landingPage.header || ''}
              onChange={this.handleHeaderChange}
            />
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_title"
              className="col-form-label"
            >
              Page Title
            </label>
            <input
              type="text"
              className={
                errors.title
                  ? 'has-error form-control'
                  : 'form-control'
              }
              id="landing_page_title"
              ref={(input) => (this.landingPageTitleInput = input)}
              name="landing_page[title]"
              placeholder="Page Title"
              value={landingPage.title || ''}
              onChange={this.handleTitleChange}
            />
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_intro"
              className="col-form-label"
            >
              Team Landing Page Introduction
            </label>
            <textarea
              className="form-control"
              id="landing_page_intro"
              ref={(input) => (this.landingPageIntroInput = input)}
              name="landing_page[intro]"
              placeholder="Team Landing Page Introduction"
              rows={5}
              value={landingPage.intro}
              onChange={this.handleIntroChange}
            />
          </div>

          <div>
            <label
              htmlFor="user_introduction"
              className="col-form-label"
            >
              User Landing Page Introduction
            </label>
            <input
              type="text"
              className={
                errors.user_introduction
                  ? 'has-error form-control'
                  : 'form-control'
              }
              id="user_introduction"
              ref={(input) => (this.landingPageAgentIntroductionInput = input)}
              name="landing_page[user_introduction]"
              placeholder="User Landing Page Introduction"
              value={landingPage.user_introduction || ''}
              onChange={this.handleUserIntroductionChange}
            />
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_intro_background"
              className="col-form-label"
            >
              Background Image
              {' '}
              <small>(10MB max)</small>
            </label>

            {this.renderIntroBackground()}

            <input
              type="file"
              className="form-control"
              id="landing_page_intro_background"
              onChange={this.handleIntroBackgroundChange}
            />
          </div>

          <div className="form-group m-1 mb-3">
            <label htmlFor="cta-color">
              Submission Button Color
            </label>
            <div className="mt-1">
              <div
                className="cta-swatch-wrapper bg-white rounded d-inline-block px-2 py-1"
                onClick={this.handleSketchPicker}
              >
                <div
                  className="cta-swatch-color rounded"
                  style={{ backgroundColor: landingPage.color }}
                />
              </div>
              <div className="color-picker-wrapper">
                {showSketchPicker && (
                <div className="m-1 picker-popover position-absolute">
                  <div
                    className="position-fixed picker-cover"
                    onClick={this.hideSketchPicker}
                  />
                  <SketchPicker
                    disableAlpha
                    color={landingPage.color}
                    onChangeComplete={this.handleColorChange}
                  />
                </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="landing_page_team_leader_id">
              Team Leader
            </label>
            <TeammatePicker
              id="landing_page_team_leader_id"
              name="landing_page[team_leader_id]"
              placeholder="Select a team leader for display"
              value={landingPage.team_leader_id}
              onChange={this.handleTeamLeaderChange}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="landing-page-phone" className="d-block">
              Phone
            </label>
            <input
              id="landing-page-phone"
              className="form-control"
              value={landingPage.phone || ''}
              onChange={this.handlePhoneChange}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="landing_page_body">Page Main Content</label>
            <LandingPageHtmlEditor
              id="landing_page_body"
              value={landingPage.body}
              onChange={this.handleBodyChange}
              spellCheck={this.state.form}
            />
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_fb_pixel_id"
              className="col-form-label"
            >
              Facebook Pixel ID
            </label>
            <input
              type="text"
              className="form-control"
              id="landing_page_fb_pixel_id"
              ref={(input) => (this.landingFBPixelIDInput = input)}
              name="landing_page[fb_pixel_id]"
              placeholder="E.g. 881112223334567"
              value={landingPage.fb_pixel_id || ''}
              onChange={this.handleFBPixelIDChange}
            />
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="landing_page_ga_tracking_id"
              className="col-form-label"
            >
              Google Analytics Tracking ID
            </label>
            <input
              type="text"
              className="form-control"
              id="landing_page_ga_tracking_id"
              ref={(input) => (this.landingGATrackingIDInput = input)}
              name="landing_page[ga_tracking_id]"
              placeholder="E.g. UA-XXXXXXX-XX"
              value={landingPage.ga_tracking_id || ''}
              onChange={this.handleGATrackingIDChange}
            />
          </div>

          <div className="form-group mt30">
            <button
              type="submit"
              className={classNames(submitButtonClass, 'mr10')}
              disabled={updating}
            >
              Save Changes
            </button>
            {landingPage.active ? (
              <a
                href={landingPage.url}
                target="_blank"
                className="btn btn-secondary"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-external-link-alt']}
                  className="mr5"
                />
                Preview
              </a>
            ) : (
              <a
                href="#preview"
                disabled
                className="ml10 disabled btn btn-secondary"
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-external-link-alt']}
                  className="mr5"
                />
                Preview
              </a>
            )}
          </div>
        </div>
      </form>
    );
  }
}

LandingPageForm.defaultProps = {
  landingPage: {},
};

LandingPageForm.propTypes = {
  landingPage: PropTypes.shape({}),
};

export default LandingPageForm;
