import classNames           from 'classnames';
import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Tooltipable          from '~/components/effects/tooltipable';

import LeadHelpers from '~/helpers/lead_helpers';
import DataActions from '~/actions/data_actions';

class DuplicateItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      group:      props.group,
      submitting: false,
    };
  }

  handlePrimaryDuplicateClick(e, lead) {
    e.preventDefault();

    lead.primary = !lead.primary;

    this.setState({ group: this.state.group });
  }

  handleMergeDuplicate(e, lead) {
    e.preventDefault();

    const { group } = this.state;
    const { duplicatesFilter, pagination } = this.props;
    const primaryLead = _lodash.find(group, (lead) => lead.primary);

    if (primaryLead && lead) {
      this.setState({ submitting: true });
      DataActions.mergeDuplicates(primaryLead, lead, duplicatesFilter, pagination.current_page);
    }
  }

  renderPhoneNumbers(lead) {
    const cellPhone   = lead.cell_phone ? `${LeadHelpers.formatPhoneNumber(lead.cell_phone)} (Cell)` : null;
    const homePhone   = lead.other_phone ? `${LeadHelpers.formatPhoneNumber(lead.other_phone)} (Home)` : null;
    const officePhone = lead.office_phone ? `${LeadHelpers.formatPhoneNumber(lead.office_phone)} (Office)` : null;
    const phoneNumbers = [cellPhone, homePhone, officePhone];

    return _lodash.chain(phoneNumbers)
      .filter((phoneNumber) => phoneNumber !== null)
      .map((phoneNumber) => phoneNumber)
      .join(', ')
      .value();
  }

  renderRole(lead) {
    if (!lead.role) return null;

    let className;
    switch (lead.role.name) {
      case 'admin':
        className = 'badge badge-outline-green';
        break;
      case 'staff':
        className = 'badge badge-outline-yellow';
        break;
      case 'agent':
        className = 'badge badge-outline-blue';
        break;
      case 'affiliate':
        className = 'badge badge-outline-gray';
        break;
      default:
        return null;
    }

    return (
      <span
        className={classNames(className, 'ml-2')}
      >
        {_lodash.capitalize(lead.role.name)}
      </span>
    );
  }

  renderLeadLink(lead) {
    if (lead.recruiting) {
      return (
        <Link target="_blank" to={`/recruiter/today?lead=${lead.id}&_t=${lead.team_id}`}>{lead.name}</Link>
      );
    }

    if (lead.retention) {
      return (
        <Link target="_blank" to={`/retention/today?lead=${lead.id}&_t=${lead.team_id}`}>{lead.name}</Link>
      );
    }

    return null;
  }

  renderLeadItem() {
    const { group, submitting }   = this.state;
    const primaryLead = _lodash.find(group, (lead) => lead.primary);

    return group.map((lead, index) => {
      const emailList = _lodash.map(lead.lead_emails, (lead) => lead.email);
      const agentIdList = _lodash.map(lead.lead_agent_ids, (lead) => {
        const mls = `- MLS(${lead.mls_name})`;
        return `${lead.agent_id} ${lead.mls_name ? mls : ''}`;
      });
      const leadDOM = (
        <div className="col-lg-6 col-md-12 mt-2" key={index}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {this.renderLeadLink(lead)}
                {this.renderRole(lead)}
              </h5>

              <p className="card-text">
                Email Addresses:
                {' '}
                {emailList.join(', ')}
              </p>
              <p className="card-text">
                Agent IDs:
                {' '}
                {agentIdList.join(', ')}
              </p>
              <p className="card-text">
                Phone Numbers:
                {' '}
                {this.renderPhoneNumbers(lead)}
              </p>
              <p className="card-text">
                Company Name:
                {' '}
                {lead.company}
              </p>
              <p className="card-text">
                Bucket:
                {' '}
                {lead.bucket}
              </p>
              <p className="card-text">
                Creation Date:
                {' '}
                {lead.created_at}
              </p>

              <div className="mt-2">
                {(!primaryLead || lead.primary) && (
                  <div className="pull-left">
                    <button type="button" onClick={(e) => this.handlePrimaryDuplicateClick(e, lead)} className={classNames('btn btn-wide btn-sm', lead.primary ? 'btn-green' : 'btn-outline-green')}>
                      Primary
                    </button>
                  </div>
                )}

                {!lead.primary && (
                  <div className="pull-right">
                    <Tooltipable text="When you merge records, data from the Primary record will overwrite data in certain fields such as production and office information on the secondary records. Your messages (emails, texts), activity records (calls logged, sticky notes,…), email addresses, and agent IDs will all be added to the Primary record. Campaign subscriptions will not be merged from the secondary records to the primary record.">
                      <button type="button" onClick={(e) => this.handleMergeDuplicate(e, lead)} className="btn btn-info merge-button btn-sm" disabled={!primaryLead || submitting}>
                        Merge into Primary
                      </button>
                    </Tooltipable>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );

      return leadDOM;
    });
  }

  render() {
    return (
      <>
        <div className="row mb-4">
          {this.renderLeadItem()}
        </div>
        <hr />
      </>
    );
  }
}

DuplicateItem.defaultProps = {};

DuplicateItem.propTypes = {
  history:          PropTypes.shape({}).isRequired,
  group:            PropTypes.array,
  pagination:       PropTypes.shape({}),
  duplicatesFilter: PropTypes.string,
};

export default withRouter(DuplicateItem);
