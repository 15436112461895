import AppDispatcher         from '~/dispatchers/app_dispatcher';
import BulkSelectActionTypes from '~/types/bulk_select_action_types';

const BulkSelectActions = {
  /**
   * Select all items for bulk action
   * @param {Object} table - Lead/Task info table
   */
  selectAllItems(table) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_ALL_ITEMS,
      tableScope: table.scope,
    });
  },

  /**
   * De-select all items for bulk action
   * @param {Object} table - Lead/Task info table
   */
  deselectAllItems(table) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_ALL_ITEMS,
      tableScope: table.scope,
    });
  },

  /**
   * Select multiple lead items for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object[]} leadItems
   */
  selectLeadItems(table, leadItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_LEAD_ITEMS,
      tableScope: table.scope,
      leadItems,
    });
  },

  /**
   * De-select multiple lead items for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object[]} leadItems
   */
  deselectLeadItems(table, leadItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_LEAD_ITEMS,
      tableScope: table ? table.scope : null,
      leadItems,
    });
  },

  /**
   * Select a single lead item for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object} leadItem
   */
  selectLeadItem(table, leadItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_LEAD_ITEM,
      tableScope: table.scope,
      leadItem,
    });
  },

  /**
   * De-select a single lead item for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object} leadItem
   */
  deselectLeadItem(table, leadItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_LEAD_ITEM,
      tableScope: table.scope,
      leadItem,
    });
  },
};

export default BulkSelectActions;
