import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import APIRequest from '~/lib/api_request';
import Select     from '~/components/forms/select';

class TeamPhoneNumberPicker extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const value = props.value ? props.value.toString() : '';

    this.state = {
      disabled: false,
      value,
      loading:  false,
      options:  [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.value ? nextProps.value.toString() : '';

    this.setState({
      value,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadOptions() {
    const { contactOwner, handleNumbersExistsChange } = this.props;

    this._isMounted = true;
    this.setState({ loading: true });

    const request = APIRequest.get({
      resource: '/v1/sms_numbers/team',
    });

    request.end((error, response) => {
      if (this._isMounted) {
        if (error) return;

        const { data } = response.body;

        if (handleNumbersExistsChange) handleNumbersExistsChange(!_lodash.isEmpty(data));

        const options = data.map((u) => ({
          value:            u.service_number,
          label:            `${u.user_name} - ${u.formatted_number}`,
          verified_texting:  u.verified_texting,
        }));

        if (contactOwner) options.unshift({ value: -1, label: "Contact's Owner" });

        this.setState((prevState) => ({
          options,
          loading: false,
        }), () => {
          const { handleVerifiedTextingWarning } = this.props;
          const { value } = this.state;
          const defaultOption = options.find((option) => option.value === value);
          const showWarning = defaultOption && !defaultOption.verified_texting;

          handleVerifiedTextingWarning(showWarning);
        });
      }
    });
  }

  render() {
    const { label, onChange } = this.props;
    const {
      loading, value, options, disabled,
    } = this.state;

    return (
      <div>
        { label && (
          <label className="d-block">{label}</label>
        )}
        <Select
          {...this.props}
          ref={(el) => { this.select = el; }}
          isLoading={loading}
          options={options}
          value={value}
          disabled={disabled}
          openOnFocus
          onChange={onChange}
        />
      </div>
    );
  }
}

TeamPhoneNumberPicker.defaultProps = {
  name:                         '',
  value:                        '',
  clearable:                    false,
  contactOwner:                 false,
  onChange:                     () => false,
  handleVerifiedTextingWarning: () => {},
  handleNumbersExistsChange:    () => false,
};

TeamPhoneNumberPicker.propTypes = {
  name:                        PropTypes.string,
  value:                       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearable:                    PropTypes.bool,
  contactOwner:                 PropTypes.bool,
  onChange:                     PropTypes.func,
  handleVerifiedTextingWarning: PropTypes.func,
  handleNumbersExistsChange:    PropTypes.func,
};

export default TeamPhoneNumberPicker;
