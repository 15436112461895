import React from 'react';
import PropTypes from 'prop-types';
import ActivityComponentMap from '~/constants/ActivityComponentMap';

const Activity = ({ lead, activity, setCurrentFormWithData }) => {
  const ActivityComponentInfo = ActivityComponentMap[activity.key];

  if (!ActivityComponentInfo) {
    return (
      <div key={activity.id} className="mt15 mb15">
        <p className="text-grey">
          Missing activity timeline for
          {' '}
          <code className="text-red">{activity.key}</code>
        </p>
      </div>
    );
  }

  const { Component: ActivityComponent, props: activityProps = {} } = ActivityComponentInfo;
  const allProps = {
    activity,
    lead,
    key: `activity-${activity.id}-${activity.updated_at}`,
    ...activityProps,
    setCurrentFormWithData,
  };

  return <ActivityComponent {...allProps} />;
};

Activity.propTypes = {
  lead:     PropTypes.shape({}).isRequired,
  activity: PropTypes.shape({
    id:         PropTypes.number.isRequired,
    key:        PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  setCurrentFormWithData: PropTypes.func.isRequired,
};

export default Activity;
