import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Input }            from 'reactstrap';
import classNames           from 'classnames';
import update               from 'immutability-helper';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import ClickableTooltip     from '~/components/effects/clickable_tooltip';
import Tooltipable          from '~/components/effects/tooltipable';

import TeamMlsActions  from '~/actions/team_mls_actions';
import TeamMlsStore    from '~/stores/team_mls_store';
import GlobalContainer from '~/components/global_container';
import ImportersSelect from '~/components/forms/importers_select';

class MlsConfigurationForm extends React.Component {
  constructor(props) {
    super(props);

    const { teamMlsId } = props;

    this.state = {
      loadingTeamMls: !!teamMlsId,
      teamMls:        {},
      updating:       false,
      errors:         {},
    };

    autoBind(this);
  }

  componentDidMount() {
    const { teamMlsId } = this.props;

    // listen to TeamMlsStore change events and store reference
    this.listener = TeamMlsStore.addListener(
      this.onTeamMlsStoreChange,
    );

    if (teamMlsId) {
      TeamMlsActions.loadTeamMls(
        teamMlsId,
        this.onTeamMlsLoad,
        this.onTeamMlsLoadFail,
      );
    }
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  handleUpdateClick(e) {
    e.preventDefault();

    const { teamMls } = this.state;

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      TeamMlsActions.updateTeamMls(teamMls);
    } else {
      this.setState({ errors });
    }
  }

  onTeamMlsStoreChange() {
    const mlsState = TeamMlsStore.getState();
    const {
      teamMls, updating, lastTeamMlsStoreAction, errors,
    } = mlsState;

    const newState = {
      teamMls,
      updating,
      errors,
    };

    if (lastTeamMlsStoreAction === 'updateTeamMlsDone') {
      GlobalContainer.notify(
        'Your Configuration for Multiple Listing Service were saved.',
      );
    }

    this.setState(newState);
  }

  onTeamMlsLoad(teamMls) {
    this.setState({
      loadingTeamMls: false,
      teamMls,
    });
  }

  onTeamMlsLoadFail() {
    this.setState({
      loadingTeamMls: false,
      teamMls:        {},
    });
  }

  setFormField(name, val) {
    this.setState({
      teamMls: update(this.state.teamMls, { [name]: { $set: val } }),
    });
  }

  validate() {
    const errors = {};
    const { teamMls } = this.state;

    if (!teamMls.importers_id) {
      errors.importers_id = 'Is invalid';
    }

    if (!teamMls.api_key) {
      errors.api_key = 'Is invalid';
    }

    return errors;
  }

  renderSearchQueryForm() {
    const {
      loadingTeamMls, teamMls, updating, errors,
    } = this.state;
    const { history } = this.props;

    if (loadingTeamMls) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return (
      <>
        <h5>{teamMls.mls_name}</h5>

        <div className="row my-4">
          <div className="col">
            <label
              id="data-source-label"
              htmlFor="data_source"
              className="label"
            >
              Select Data Source
            </label>
            <ImportersSelect
              className={classNames('text-center', {
                'has-error': !!errors.importers_id,
              })}
              value={teamMls.importers_id || ''}
              clearable
              onChange={(val) => this.setFormField(
                'importers_id',
                val ? val.value : '',
              )}
            />
          </div>
          <ClickableTooltip
            text={(
              <>
                Learn how to get an API key
                {' '}
                <a
                  className="text-white"
                  href="https://support.getbrokerkit.com/hc/en-us/articles/4464878235533"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </>
            )}
            placement="bottom"
            target="data-source-label"
          />

          <div className="col">
            <label
              id="api-key-label"
              htmlFor="api_key"
              className="label"
            >
              API Key
            </label>
            <Input
              className={classNames({
                'has-error': !!errors.api_key,
              })}
              value={teamMls.api_key || ''}
              id="api_key"
              placeholder="Add API key here"
              onChange={(e) => this.setFormField('api_key', e.target.value)}
            />
          </div>
          <ClickableTooltip
            text={(
              <>
                Learn how to get an API key
                {' '}
                <a
                  className="text-white"
                  href="https://support.getbrokerkit.com/hc/en-us/articles/4464878235533"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </>
            )}
            placement="bottom"
            target="api-key-label"
          />
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="preferred_phone_selector"
                checked={teamMls.preferred_phone}
                onChange={(e) => this.setFormField(
                  'preferred_phone',
                  e && e.target.checked,
                )}
              />

              <Tooltipable
                placement="bottom"
                text="When selected, the MLS Preferred Phone number will be added to the Cell Phone Field if there is no cell phone number available via the Relitix integration. Note that it is not possible to determine the phone type from the MLS Preferred Phone attribute so it is possible this is not a mobile phone."
              >
                <label
                  className="ml-1 form-check-label"
                  htmlFor="preferred_phone_selector"
                >
                  Map Preferred Phone to Cell Phone if Cell Phone is
                  not available
                </label>
              </Tooltipable>
            </div>
          </div>
        </div>

        <div className="pull-right">
          {updating ? (
            <button
              type="button"
              className="btn btn-primary disabled"
              disabled
            >
              <FontAwesomeIcon
                icon="far fa-spinner"
                pulse
                className="mr5"
              />
              {' '}
              Updating ...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleUpdateClick}
            >
              Update
            </button>
          )}
        </div>
      </>
    );
  }

  render() {
    const data_uri = GlobalContainer.productUri('/data');

    return (
      <div className="card search-query">
        <div className="card-block">
          <h4 className="mb30">
            <div className="pull-right">
              <Link
                to={{
                  pathname: data_uri,
                  dataTab:  'multiple_listing_services',
                }}
                className="btn btn-outline-success mr5"
              >
                <FontAwesomeIcon
                  icon={['fas', 'fa-caret-left']}
                  className="mr-1"
                />
                {' '}
                Back
              </Link>
            </div>
            Manage MLS Data Source
            <hr className="mt-5 hr-success-color" />
          </h4>

          {this.renderSearchQueryForm()}
        </div>
      </div>
    );
  }
}

MlsConfigurationForm.defaultProps = {
  teamMlsId: null,
};

MlsConfigurationForm.propTypes = {
  teamMlsId: PropTypes.string,
  history:   PropTypes.shape({}).isRequired,
};

export default withRouter(MlsConfigurationForm);
