import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import SubmissionActivityEntry from './SubmissionActivityEntry';
import Activity from './Activity';

const Activities = ({
  loadingActivities,
  activities,
  activeTab,
  hideOlderThan,
  expanded,
  setExpanded,
  lead,
  setCurrentFormWithData,
}) => {
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    const hash = location.hash.replace('#', '');

    if (!loadingActivities && hash) {
      setTimeout(() => {
        scrollToElement(hash);
      }, 0);
    }
  }, [loadingActivities]);

  const handleShowClick = () => {
    setExpanded(false);
  };

  const handleHideClick = () => {
    setExpanded(true);
  };

  const ShowButton = ({ onClick, hiddenCount }) => (
    <div className="text-center mb15">
      <button
        type="button"
        onClick={onClick}
        className="btn btn-secondary btn-wide btn-sm"
      >
        {hiddenCount}
        {' '}
        older activities, click to show recruiting
        history
      </button>
    </div>
  );

  const HideButton = ({ onClick }) => (
    <div className="text-center mb15">
      <button
        type="button"
        onClick={onClick}
        className="btn btn-secondary btn-wide btn-sm"
      >
        Hide recruiting history
      </button>
    </div>
  );

  const renderLoading = () => (
    <div className="text-center mt-3">
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    </div>
  );

  const renderNoActivities = () => {
    if (activeTab !== 'all' && activeTab !== 'changes') {
      return (
        <div className="text-center mt-3">
          <p>No activities found for this tab.</p>
        </div>
      );
    }

    return null;
  };

  const renderActivityEntries = (activityArray) => activityArray.map((activity) => (
    <Activity
      key={activity.id}
      activity={activity}
      lead={lead}
      setCurrentFormWithData={setCurrentFormWithData}
    />
  ));

  if (loadingActivities) {
    return renderLoading();
  }

  const sortedActivities = _lodash.orderBy(
    activities,
    ['occurred_at'],
    'desc',
  );

  if (GlobalContainer.product() === 'retention') {
    const beforeActivities = sortedActivities.filter((activity) => Moment(activity.occurred_at).isBefore(hideOlderThan));
    const afterActivities = sortedActivities.filter((activity) => Moment(activity.occurred_at).isAfter(hideOlderThan));
    let hiddenCount = beforeActivities.length;

    if (activeTab === 'all' || activeTab === 'changes') {
      hiddenCount += 1;
    }

    return (
      <div className="mt-4">
        {sortedActivities.length > 0 ? (
          <>
            {renderActivityEntries(afterActivities)}
            {expanded ? (
              <ShowButton
                onClick={handleShowClick}
                hiddenCount={hiddenCount}
              />
            ) : (
              <>
                <HideButton onClick={handleHideClick} />
                {renderActivityEntries(beforeActivities)}
              </>
            )}
          </>
        ) : (
          renderNoActivities()
        )}
        {(activeTab === 'all' || activeTab === 'changes') && (
          <SubmissionActivityEntry lead={lead} />
        )}
      </div>
    );
  }

  return (
    <div className="mt-4">
      {sortedActivities.length > 0
        ? renderActivityEntries(sortedActivities)
        : renderNoActivities()}
      {(activeTab === 'all' || activeTab === 'changes') && (
        <SubmissionActivityEntry lead={lead} />
      )}
    </div>
  );
};

Activities.propTypes = {
  loadingActivities:         PropTypes.bool.isRequired,
  activities:                PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeTab:                 PropTypes.string.isRequired,
  hideOlderThan:             PropTypes.instanceOf(Date).isRequired,
  expanded:                  PropTypes.bool.isRequired,
  setExpanded:               PropTypes.func.isRequired,
  lead:                      PropTypes.shape({}).isRequired,
  setCurrentFormWithData:    PropTypes.func.isRequired,
};

export default Activities;
