import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Container }        from 'flux/utils';
import LeadDrawerActions    from '~/actions/lead_drawer_actions';
import LeadDrawerStore      from '~/stores/lead_drawer_store';
import SharedEmailForm      from '~/components/forms/email_form';
import FormFooter           from './common/form_footer';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import { Popover } from 'react-bootstrap';
import { WarningMessage } from '~/components/warnings/GooglePermissionDeniedWarning';

class EmailForm extends React.Component {
  static getStores() {
    return [LeadDrawerStore];
  }

  static calculateState() {
    return {
      leadDrawerState: LeadDrawerStore.getState(),
    };
  }

  constructor(props) {
    super(props);

    const { lead, email, schedule } = this.props;
    const { currentUser } = Rails.helpers;

    this.state = {
      email: {
        id:             email?.id || null,
        subject:        email?.subject || '',
        body:           email?.body || '',
        cc_emails:      email?.cc_emails || '',
        attachment_ids: email?.attachment_ids || '',
      },
      status:   lead.status,
      schedule: schedule || { customOptions: { timezone: currentUser.timezone } },
      sending:  false,
      showPopover: false,
      warningMessage: '',
    };

    autoBind(this);
  }

  componentDidMount() {
    this.leadStoreListener = LeadDrawerStore.addListener(this.onLeadStoreChange);
    const { email, schedule } = this.props;

    this.setState((prevState) => ({
      email:                 { ...prevState.email, ...email },
      schedule:              { ...prevState.schedule, ...schedule },
      scheduleEmailsAllowed: checkFeatureFlag(process.env.ALLOW_SCHEDULE_EMAILS_FLAG),
    }));
  }

  componentDidUpdate(prevProps) {
    const { email, schedule } = this.props;
    if (schedule !== prevProps.schedule || email?.id !== prevProps.email?.id) {
      this.setState((prevState) => ({
        email: { ...prevState.email, ...email },
        schedule,
      }));
    }
  }

  componentWillUnmount() {
    if (this.leadStoreListener) this.leadStoreListener.remove();

    this.setState({
      email: {
        id:             null,
        subject:        '',
        body:           '',
        cc_emails:      '',
        attachment_ids: '',
      },
      status:   '',
      schedule: {},
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();

    const { lead } = this.props;
    const { schedule, email } = this.state;

    this.setState({ sending: true });

    const errors = this.validate();
    if (Object.keys(errors).length === 0) {
      const _footer = this.footer.serialize();
      const _attrs = {
        ..._footer,
        email: {
          ...email,
          internal: _footer.internal,
          schedule,
        },
      };

      if (email?.id) {
        LeadDrawerActions.updateLeadEmail(lead, email.id, _attrs);
      } else {
        LeadDrawerActions.createLeadEmail(lead, _attrs)
          .catch((error) => {
            const errorMsg = error.response?.body?.errors ||
            'An error occurred while checking permissions for email,' +
            'please try again later.'
            this.setState({
              warningMessage: errorMsg,
              showPopover: true,
            });
          })
          .finally(() => {
            this.setState({ sending: false });
          });
      }
    } else {
      this.setState({ sending: false });
    }
  }

  renderPopover = (props) => {
    const { warningMessage } = this.state;

    return (
      <Popover id="dropdown-message-id" {...props}>
        <Popover.Content className="bg-grey-lightest">
          <WarningMessage
            message={warningMessage}
            onClose={() => this.setState({ showPopover: false })}
          />
        </Popover.Content>
      </Popover>
    );
  }

  onLeadStoreChange = () => {
    const { leadDrawerState } = this.state;
    const { leadDrawerStoreAction } = leadDrawerState;

    if (leadDrawerStoreAction === 'createLeadEmailFail' || leadDrawerStoreAction === 'updateLeadEmailFail') {
      this.setState({ sending: false });
    }
  };

  setSchedule = (newSchedule) => {
    this.setState((prevState) => ({
      schedule: { ...prevState.schedule, ...newSchedule },
    }));
  };

  assignEmailState = (newEmail) => {
    this.setState((prevState) => ({
      email: { ...prevState.email, ...newEmail },
    }));
  };

  validate() {
    return this.footer.validate(this.emailForm.validate());
  }

  render() {
    const {
      status, internal, schedule, email, scheduleEmailsAllowed, sending, showPopover,
    } = this.state;
    const { errors, lead, scheduleBtnPopoverPlacement } = this.props;
    const { currentUser } = Rails.helpers;
    const {
      subject, body, cc_emails, attachments,
    } = email;

    let submitButtonText = 'Send';

    if (currentUser.is_microsoft) {
      submitButtonText = 'Send with Microsoft';
    } else if (currentUser.is_google) {
      submitButtonText = 'Send with Google';
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <SharedEmailForm
          ref={(el) => this.emailForm = el}
          onChange={this.assignEmailState}
          errors={errors}
          enableCCField
          subject={subject}
          body={body}
          cc_emails={cc_emails}
          attachments={attachments}
        />

        <div className="mt15 mb15">
          <FormFooter
            ref={(el) => this.footer = el}
            status={status}
            activityType="direct_email"
            internal={internal}
            lead={lead}
            submitButtonText={submitButtonText}
            onSubmit={this.onFormSubmit}
            showScheduleButton={scheduleEmailsAllowed}
            schedule={schedule}
            setSchedule={this.setSchedule}
            sending={sending}
            scheduleBtnPopoverPlacement={scheduleBtnPopoverPlacement}
            isEditingMode={!!email.id}
            showPopover={showPopover}
            renderPopover={this.renderPopover}
            warningMessage={this.state.warningMessage}
          />
        </div>
      </form>
    );
  }
}

EmailForm.defaultProps = {
  lead:                        {},
  scheduleBtnPopoverPlacement: 'bottom',
};

EmailForm.propTypes = {
  lead:                        PropTypes.shape({}),
  scheduleBtnPopoverPlacement: PropTypes.string,
};

export default Container.create(EmailForm);
