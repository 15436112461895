// global _

import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import React               from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit            from '~/lib/brokerbit';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import { ALL_STATUSES }     from '~/components/forms/dropdowns/StatusSelect';
import Tooltipable          from '~/components/effects/tooltipable';
import BulkSelectActions    from '~/actions/bulk_select_actions';
import TaskActions          from '~/actions/task_actions';
import LeadHelpers          from '~/helpers/lead_helpers';
import UserAvatar           from '~/components/shared/users/user_avatar';
import { months }           from '~/components/forms/simple_month_picker';
import TaskModal            from '~/components/modals/task_modal';

class DataTableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { selectedKeys, lead } = this.props;
    const selectedRow = _lodash.includes(
      selectedKeys,
      `lead-item-${lead.id}`,
    );

    this.setState({ selectedRow });
  }

  static getDerivedStateFromProps(props, state) {
    const { selectedKeys, lead } = props;
    const selectedRow = _lodash.includes(
      selectedKeys,
      `lead-item-${lead.id}`,
    );

    state.selectedRow = selectedRow;

    return state;
  }

  handleMarkAsDoneTaskClick(e) {
    const { lead } = this.props;

    const taskData = { id: lead.task_id, status: 'done' };

    TaskActions.updateTask(taskData);

    GlobalContainer.notify('Task Marked as Done');
  }

  onLeadClick(e) {
    e.preventDefault();

    const {
      table, lead, query, clickable, totalCount, rowIdx,
    } =      this.props;
    const { helpers } = this.context;

    const leadID =      table.scope === 'today_or_past_tasks'
      || table.scope === 'tasks_due_later'
      || table.scope === 'tasks_overdue'
      || table.scope === 'all_tasks'
      ? lead.lead_id
      : lead.id;

    if (clickable === false) return;

    helpers.openLeadDrawer({
      loadLeadID: leadID,
      table,
      query,
      totalCount,
      rowIdx,
    });
  }

  onBulkSelect(e) {
    const selectedRow = e.target.checked;
    const { table, lead } = this.props;

    this.setState({ selectedRow });

    if (selectedRow) {
      BulkSelectActions.selectLeadItem(table, lead);
    } else {
      BulkSelectActions.deselectLeadItem(table, lead);
    }
  }

  onEditTaskClick(e) {
    e.preventDefault();

    const { lead } = this.props;

    ReactDOM.render(
      <TaskModal
        taskID={lead.task_id}
        dialogClass="modal-dialog modal-lg"
        loadLead={false}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onDeleteTaskClick(e) {
    e.preventDefault();

    const { lead } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this task?',
      callback: (ok) => {
        if (ok) {
          TaskActions.deleteTask(lead.task);
        }
      },
    });
  }

  removeDataFromArray(data, id) {
    const removeIndex = _lodash.map(data, 'id').indexOf(id);
    data.splice(removeIndex, 1);
    return data;
  }

  renderLeadRows() {
    const { lead, timestampField, table } = this.props;
    const displayCustomStatuses = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    return (
      <>
        {GlobalContainer.product() !== 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-sm-table-cell"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {displayCustomStatuses
                ? lead.status
                : LeadHelpers.findStatusLabel(lead.status_id, ALL_STATUSES)}
            </small>
          </td>
        )}

        <td
          width="25%"
          className="clickable text-grey-dark font-weight-bold"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadAvatar(lead, { className: 'mr5' })}
          {LeadHelpers.renderLeadName(lead)}
        </td>

        <td
          width="15%"
          className="clickable d-none d-md-table-cell"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderProductionValue(lead.production_ytd, {
            className: 'mr5',
          })}
          {LeadHelpers.renderProductionBadge(lead)}
        </td>

        {GlobalContainer.product() === 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.referrals_count}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {months[lead.anniversary_month - 1]}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.birthday && Moment(lead.birthday).format('MMMM Do')}
          </td>
        )}

        {GlobalContainer.product() !== 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.currently_at && lead.currently_at.truncate(30)}
          </td>
        )}

        {timestampField && (
          <td
            width="10%"
            className="clickable d-none d-lg-table-cell"
            onClick={this.onLeadClick}
          >
            {GlobalContainer.product() === 'retention'
              ? LeadHelpers.renderLeadDateField(
                lead,
                timestampField,
                'fromNow',
              )
              : LeadHelpers.renderLeadDateField(lead, timestampField)}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-sm-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.last_direct_contact
              && Moment(lead.last_direct_contact).fromNow()}
          </td>
        )}

        {GlobalContainer.product() === 'recruiting'
          && table.scope === 'search_results' && (
            <td
              width="15%"
              className="clickable d-none d-md-table-cell"
              onClick={this.onLeadClick}
            >
              {lead.next_due_date
                && Moment(lead.next_due_date.due_date_at).format(
                  'MMMM Do',
                )}
            </td>
        )}

        {Rails.abilities.manageLead && (
          <td
            className="clickable d-none d-md-table-cell text-center"
            onClick={this.onLeadClick}
          >
            <UserAvatar tooltip user={lead.owner} />
          </td>
        )}

        {Rails.helpers.currentUser.staff_type === 'external' && (
          <td className="clickable d-none d-lg-table-cell">
            {LeadHelpers.renderLeadDateField(
              lead,
              'my_last_activity_at',
              'fromNow',
            )}
          </td>
        )}
      </>
    );
  }

  renderTaskRows() {
    const { lead, table } = this.props;
    const displayCustomStatuses = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    return (
      <>
        {table.leadTasks
          && GlobalContainer.product() !== 'retention' && (
            <td
              width="15%"
              className="clickable"
              onClick={this.onLeadClick}
            >
              <small className="text-uppercase">
                {displayCustomStatuses
                  ? lead.status
                  : LeadHelpers.findStatusLabel(lead.status_id, ALL_STATUSES)}
              </small>
            </td>
        )}

        <td
          width="15%"
          className="clickable text-grey-dark font-weight-bold"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadAvatar(lead, { className: 'mr5' })}
          {LeadHelpers.renderLeadName(lead)}
        </td>

        <td
          width="15%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderProductionValue(lead.production_ytd, {
            className: 'mr5',
          })}
          {LeadHelpers.renderProductionBadge(lead)}
        </td>

        {table.leadTasks
          && GlobalContainer.product() !== 'retention' && (
            <td
              width="15%"
              className="clickable"
              onClick={this.onLeadClick}
            >
              {lead.currently_at && lead.currently_at.truncate(30)}
            </td>
        )}

        <td
          width="15%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {lead.task_name}
        </td>

        {(!table.leadTasks
          || GlobalContainer.product() === 'retention') && (
          <td
            width="5%"
            className="clickable"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {LeadHelpers.renderTaskStatus(lead.task_status)}
            </small>
          </td>
        )}

        {!table.leadTasks && (
          <td
            width="5%"
            className="clickable"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {LeadHelpers.renderTaskPriority(lead.task_priority)}
            </small>
          </td>
        )}

        <td
          width="10%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          <small className="text-uppercase">
            {LeadHelpers.renderTaskCategory(lead.task_category)}
          </small>
        </td>

        <td
          width="5%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadDateField(
            lead,
            'task_due_date_at',
            'l',
          )}
        </td>

        <td
          width="5%"
          className="clickable d-none d-sm-table-cell text-center"
          onClick={this.onLeadClick}
        >
          <UserAvatar tooltip user={lead.task_owner} />
        </td>

        <td width="10%" className="clickable text-center">
          {(lead.task_status === 'to_do'
            || lead.task_status === 'in_progress') && (
            <Tooltipable text="Mark Task as Done">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={this.handleMarkAsDoneTaskClick}
              >
                <FontAwesomeIcon icon={['far', 'fa-check-square']} />
              </button>
            </Tooltipable>
          )}

          <Tooltipable text="Edit Task">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr5"
              onClick={this.onEditTaskClick}
            >
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
            </button>
          </Tooltipable>

          <Tooltipable text="Delete Task">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={this.onDeleteTaskClick}
            >
              <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
            </button>
          </Tooltipable>
        </td>
      </>
    );
  }

  renderBulkSelector() {
    if (Rails.abilities.manageLead === false) return null;

    const id = uuidv4();
    const { selectedRow } = this.state;

    return (
      <td width="1%" className="d-md-table-cell pt20 text-center">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={id}
            checked={selectedRow}
            onChange={this.onBulkSelect}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="custom-control-label" htmlFor={id} />
        </div>
      </td>
    );
  }

  render() {
    const { selectedRow } = this.state;
    const { table } = this.props;
    const rowClass = classNames('table-item', {
      selected: selectedRow,
    });

    return (
      <tr className={rowClass}>
        {this.renderBulkSelector()}

        {table.scope === 'today_or_past_tasks'
        || table.scope === 'tasks_due_later'
        || table.scope === 'tasks_overdue'
        || table.scope === 'all_tasks'
          ? this.renderTaskRows()
          : this.renderLeadRows()}
      </tr>
    );
  }
}

DataTableItem.contextTypes = {
  helpers: PropTypes.shape({}),
};

DataTableItem.defaultProps = {
  table:                {},
  query:                {},
  clickable:            true,
  totalCount:           0,
  rowIdx:               null,
  selectedKeys:         [],
  onItemSelect:         () => false,
  timestampField:       'referred_at',
};

DataTableItem.propTypes = {
  selectAllItems:       PropTypes.bool.isRequired,
  table:                PropTypes.shape({}),
  query:                PropTypes.shape({}),
  clickable:            PropTypes.bool,
  totalCount:           PropTypes.number,
  rowIdx:               PropTypes.number,
  lead:                 PropTypes.shape({}).isRequired,
  selectedKeys:         PropTypes.arrayOf(PropTypes.string),
  onItemSelect:         PropTypes.func,
  timestampField:       PropTypes.string,
};

export default DataTableItem;
