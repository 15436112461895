import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';

const AgentSideBar = () => {
  const { abilities } = Rails;
  const [isInboxTooltipVisible, setIsInboxTooltipVisible] = useState(false);

  const inboxLinkRef = useRef(null);
  const tooltipRef = useRef(null);

  const location = useLocation();
  const page = location.pathname;

  const handleInboxClick = (e) => {
    const isAccessAllowed = checkFeatureFlag(process.env.RESTRICT_INBOX_ACCESS_FLAG);
    if (!isAccessAllowed) {
      e.preventDefault();
      setIsInboxTooltipVisible(true);
    } else {
      setIsInboxTooltipVisible(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (inboxLinkRef.current && !inboxLinkRef.current.contains(e.target)
        && tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setIsInboxTooltipVisible(false);
    }
  };

  const getClassName = (pathOrRegex) => {
    let isActive = false;

    if (Array.isArray(pathOrRegex)) {
      isActive = pathOrRegex.some((p) => (typeof p === 'string' ? page === p : p.test(page)));
    } else if (typeof pathOrRegex === 'string') {
      isActive = page === pathOrRegex;
    } else {
      isActive = pathOrRegex.test(page);
    }

    return classNames('list-group-item justify-content-between', {
      active: isActive,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="sidebar list-group text-nowrap">
      <Link to="/recruiter/today" className={getClassName('/recruiter/today')}>
        <FontAwesomeIcon icon={['fas', 'fa-calendar-day']} className="fa-lg position-absolute" />
        <span className="ml40">Today</span>
      </Link>

      <>
        <Link
          id="inbox-link"
          to="/recruiter/inbox"
          className={getClassName('/recruiter/inbox')}
          onClick={handleInboxClick}
        >
          <FontAwesomeIcon icon={['fas', 'fa-inbox']} className="fa-lg position-absolute" />
          <span className="ml40">Inbox</span>
        </Link>

        <Tooltip
          placement="bottom"
          isOpen={isInboxTooltipVisible}
          target="inbox-link"
          autohide={false}
          toggle={() => { }}
          innerRef={tooltipRef}
        >
          <>
            It looks like you&apos;re trying to access the inbox — great choice! Please upgrade your pricing plan
            {' '}
            <a
              ref={inboxLinkRef}
              href="/billing"
              target="_blank"
              rel="noopener noreferrer"
              className="text-success"
            >
              here
            </a>
            {' '}
            to enable this feature.
          </>
        </Tooltip>
      </>

      <Link to="/recruiter/leads" className={getClassName('/recruiter/leads')}>
        <FontAwesomeIcon icon={['fas', 'fa-address-book']} className="fa-lg position-absolute" />
        <span className="ml40">Leads</span>
      </Link>

      <Link to="/recruiter/tasks_due_today" className={getClassName(/tasks/)}>
        <FontAwesomeIcon icon={['fas', 'fa-tasks']} className="fa-lg position-absolute" />
        <span className="ml40">Tasks</span>
      </Link>

      {abilities.manageCampaigns && (
        <Link to="/recruiter/campaigns" className={getClassName(/campaign/)}>
          <FontAwesomeIcon icon={['fas', 'fa-bullhorn']} className="fa-lg position-absolute" />
          <span className="ml40">Campaigns</span>
        </Link>
      )}

      {abilities.manageLandingPages && (
        <Link to="/recruiter/team/landing_page/edit" className={getClassName('/recruiter/team/landing_page/edit')}>
          <FontAwesomeIcon icon={['fas', 'fa-file-alt']} className="fa-lg position-absolute" />
          <span className="ml40">Landing Pages</span>
        </Link>
      )}

      {abilities.manageLeadSources && (
        <Link to="/recruiter/data" className={getClassName(['/recruiter/data', /search_queries/, /configure_mls/])}>
          <FontAwesomeIcon icon={['fas', 'fa-database']} className="fa-lg position-absolute" />
          <span className="ml40">Data</span>
        </Link>
      )}

      {abilities.manageLabels && (
        <Link to="/recruiter/labels" className={getClassName('/recruiter/labels')}>
          <FontAwesomeIcon icon={['fas', 'fa-tags']} className="fa-lg position-absolute" />
          <span className="ml40">Labels</span>
        </Link>
      )}

      <Link to="/recruiter/reports" className={getClassName('/recruiter/reports')}>
        <FontAwesomeIcon icon={['fas', 'fa-chart-line']} className="fa-lg position-absolute" />
        <span className="ml40">Reports</span>
      </Link>
    </div>
  );
};

AgentSideBar.defaultProps = {};
AgentSideBar.propTypes = {};

export default AgentSideBar;
