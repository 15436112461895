import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import shadowRoot          from 'react-shadow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit       from '~/lib/brokerbit';
import APIRequest      from '~/lib/api_request';
import TinyUser        from '~/components/shared/users/tiny_user';
import ActivityEditor  from '~/components/forms/HtmlEditors/ActivityEditor';
import Tooltipable     from '~/components/effects/tooltipable';
import LeadDrawerStore from '~/stores/lead_drawer_store';

class TimelineActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lead:          props.lead,
      activity:      props.activity,
      queuedChanges: {},
      // eslint-disable-next-line react/no-unused-state
      loading:       true, // Used in Activities
      // eslint-disable-next-line react/no-unused-state
      emailEvents:   {}, // Used in Email Activities
    };

    autoBind(this);

    this.onSubmit = this.onSubmit.bind(this); // don't remove, for some reason autoBind doesn't work with onSubmit: https://zube.io/brokerkit/webapp/c/1920
    this.onLeadDrawerStoreChange = this.onLeadDrawerStoreChange.bind(this);
  }

  componentDidMount() {
    this.leadDrawerStoreListener = LeadDrawerStore.addListener(
      this.onLeadDrawerStoreChange,
    );
  }

  componentDidUpdate(prevProps) {
    const { activity } = this.props;
    if (activity !== prevProps.activity) {
      this.setState({ activity });
    }
  }

  componentWillUnmount() {
    if (this.leadDrawerStoreListener) {
      this.leadDrawerStoreListener.remove();
    }
  }

  onLeadDrawerStoreChange() {
    const leadDrawerState = LeadDrawerStore.getState();
    const { activity: currentActivity } = this.state;
    const { activity: leadDrawerActivity, leadDrawerStoreAction } = leadDrawerState;

    if (
      leadDrawerStoreAction === 'updateLeadActivityDone'
      && currentActivity?.id === leadDrawerActivity?.id
    ) {
      this.setState({ activity: leadDrawerActivity });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { lead, activity, queuedChanges } = this.state;
    this.setState({ sending: true });

    APIRequest.put({
      resource: `/v1/leads/${lead.id}/activities/${activity.id}`,
      data:     {
        activity: {
          body:       queuedChanges?.body,
          parameters: queuedChanges?.parameters,
        },
      },
    }).end((err, res) => {
      this.setState({
        activity: res.body,
        sending:  false,
        editing:  false,
      });
    });
  }

  onDelete = async (e) => {
    e.preventDefault();

    const { deleteMessage } = this.props;
    const { lead, activity } = this.state;
    const message =      deleteMessage || 'Are you sure you want to delete this?';

    const confirmed = await this.confirmDeletion(message);
    if (!confirmed) return;

    this.setState({ deleting: true });
    try {
      await this.deleteActivity(lead, activity);
      setTimeout(() => {
        this.setState({
          deleting: false,
          deleted:  true,
        });
      }, 500);
    } catch (err) {
      this.setState({ deleting: false, deleted: false });
    }
  };

  confirmDeletion = (message) => new Promise((resolve) => {
    brokerbit.confirmBox({
      message,
      callback: (ok) => resolve(ok),
    });
  });

  deleteActivity = (lead, activity) => APIRequest.delete({
    resource: `/v1/leads/${lead.id}/activities/${activity.id}`,
  });

  switchActivityView(value) {
    const { lead, activity } = this.state;

    APIRequest.put({
      resource: `/v1/leads/${lead.id}/activities/${activity.id}`,
      data:     {
        activity: { internal: value === 'lock' },
      },
    }).end((err, res) => {
      this.setState({
        activity: res.body,
      });
    });
  }

  renderForm() {
    const { activity, queuedChanges, sending } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <ActivityEditor
          value={activity.body}
          onChange={(html) => {
            queuedChanges.body = html;
          }}
          placeholder="Enter Text"
        />
        <div className="mt5">
          <button
            type="submit"
            className="btn btn-primary btn-sm mr5"
          >
            {sending ? (
              <span>
                <FontAwesomeIcon
                  icon="far fa-spinner"
                  pulse
                  className="mr5"
                />
                {' '}
                Saving
              </span>
            ) : (
              'Save'
            )}
          </button>
          <a
            href="#"
            onClick={() => {
              this.setState({ queuedChanges: {}, editing: false });
            }}
            className="btn btn-secondary btn-sm"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }

  renderView() {
    const { activity } = this.state;

    if (!activity) {
      return null;
    }

    return (
      <>
        {activity.body && (
          <shadowRoot.div className="form-control font-activity">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: activity.body || '',
              }}
            />
          </shadowRoot.div>
        )}
      </>
    );
  }

  renderWhoTimestamp() {
    const { activity } = this.state;

    return (
      <div className="text-grey mt10">
        <span className="pull-right text-grey">
          <Tooltipable
            text={Moment(activity.occurred_at).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}
          >
            <>{Moment(activity.occurred_at).fromNow()}</>
          </Tooltipable>
        </span>
        <TinyUser user={activity.owner} />
      </div>
    );
  }

  renderActivitySwitch() {
    const { activity } = this.state;
    const { alwaysPublic } = this.props;

    if (alwaysPublic) {
      return null;
    }

    return this.renderActivityButton(activity.internal);
  }

  renderActivityButton(isInternal) {
    const { editable, deletable } = this.props;

    const buttonClass = classNames('btn btn-secondary btn-sm pl8 pr8', {
      mr5: editable || deletable,
    });

    const tooltipText = isInternal ? 'Private - Viewable to staff only' : 'Public - Viewable to staff and recruiting agent';
    const icon = isInternal ? ['far', 'fa-eye-slash'] : ['far', 'fa-eye'];

    return (
      <Tooltipable text={tooltipText}>
        <button
          type="button"
          className={buttonClass}
          onClick={(e) => this.switchActivityView(isInternal ? 'unlock' : 'lock')}
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      </Tooltipable>
    );
  }

  render() {
    const {
      editable,
      deletable,
      title,
      icon,
      prefix,
      color,
      form,
      view,
      children,
    } = this.props;
    const {
      deleting, deleted, editing, activity,
    } = this.state;

    if (deleted) {
      return null;
    }

    let body;
    if (children) {
      body = children;
    } else if (editing) {
      body = this.renderForm();
    } else {
      let _title;
      if (typeof title === 'function') _title = title(this);
      else _title = title;

      body = (
        <div>
          <div className="actions">
            {Rails.abilities.manageLead && this.renderActivitySwitch()}
            {editable && (
              <button
                type="button"
                onClick={() => {
                  this.setState({ editing: true });
                }}
                className={classNames(
                  'btn btn-secondary btn-sm',
                  deletable ? 'mr5' : '',
                )}
              >
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
            )}
            {deletable && (
              <button
                type="button"
                onClick={this.onDelete.bind(this)}
                className="btn btn-secondary btn-sm"
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            )}
          </div>
          {_title && (
            <h5
              className="text-grey-dark mb5"
              style={{ width: '95%' }}
            >
              {_title}
            </h5>
          )}
          {this.renderView()}
          {this.renderWhoTimestamp()}
        </div>
      );
    }

    return (
      <div
        className={classNames(
          'timeline-activity',
          activity && activity.internal ? 'private' : 'public',
          deleting ? 'fade-out' : '',
        )}
      >
        <span className="icon fa-stack fa-lg">
          <FontAwesomeIcon
            icon={['fas', 'fa-circle']}
            className={`fa-stack-2x ${color}`}
          />
          <FontAwesomeIcon
            icon={[`${prefix}`, `fa-${icon}`]}
            inverse
            className="fa-stack-1x"
          />
        </span>

        <div className="body pl-1">{body}</div>
      </div>
    );
  }
}

TimelineActivity.defaultProps = {
  editable:       false,
  deletable:      false,
  alwaysPublic:   false,
  icon:           'sticky-note',
  prefix:         'far',
  color:          'text-grey-dark',
};

export default TimelineActivity;
