import { PropTypes } from 'prop-types';
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarSupportedContent from './nav_bar_supported_content';
import HelpCenter from './help_center';
import NotificationButton from '~/components/Notifications/Button';

class RecruitingNavContent extends React.PureComponent {
  onNewLeadClick = (e) => {
    e.preventDefault();
    const { helpers } = this.context;

    helpers.openNewLeadDrawer();
  }

  render() {
    return (
      <Nav className="ml-auto mr-auto-sm-down" navbar>
        <div className="d-flex">
          <NotificationButton />
          <HelpCenter />
        </div>

        <NavItem className="mr20-md-up">
          <NavLink className="btn btn-success text-white" href="#new-lead-drawer" onClick={this.onNewLeadClick}>
            <FontAwesomeIcon icon={['far', 'fa-plus']} />
            {' '}
            Add Lead
          </NavLink>
        </NavItem>
        <NavBarSupportedContent />
      </Nav>
    );
  }
}

RecruitingNavContent.defaultProps = {};
RecruitingNavContent.propTypes = {};
RecruitingNavContent.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default RecruitingNavContent;
