import autoBind        from 'react-autobind';
import qs              from 'qs';
import classNames      from 'classnames';
import React           from 'react';
import { toast }       from 'react-toastify';
import { PropTypes }   from 'prop-types';

import AttentionNeeded from '~/components/attention_needed';
import GlobalNotices   from '~/components/shared/global_notices';
import DrawerManager   from '~/components/drawers/drawer_manager';
import NavBar          from '~/components/navigation/nav_bar';
/**
 * Global Container component
 * Responsible for executing logic at global level
 */

export const DrawerContext = React.createContext();

class GlobalContainer extends React.Component {
  /**
   * Return location.search query as object: ?a=b&x=y => { a: b, x: y }
   * @returns {Object} params
   */
  static urlParams() {
    if (!window.location.search) return {};

    const locationSearch = window.location.search.replace('?', '');
    return qs.parse(locationSearch);
  }

  static notify(message, type) {
    const opts = {
      position:     toast.POSITION.TOP_RIGHT,
      closeOnClick: true,
      autoClose:    3000,
    };

    switch (type) {
      case 'success':
        toast.success(
          <div>{message}</div>,
          Object.assign(opts, { className: 'success-toast' }),
        );
        break;
      case 'info':
        toast.info(
          <div>{message}</div>,
          Object.assign(opts, { className: 'info-toast' }),
        );
        break;
      case 'warn':
      case 'warning':
        toast.warn(
          <div>{message}</div>,
          Object.assign(opts, { className: 'warning-toast' }),
        );
        break;
      case 'error':
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: message }} />,
          Object.assign(opts, { className: 'danger-toast' }),
        );
        break;
      default:
        switch (GlobalContainer.product()) {
          case 'recruiting':
            toast.success(
              <div>{message}</div>,
              Object.assign(opts, { className: 'success-toast' }),
            );
            break;
          case 'retention':
            toast.info(
              <div>{message}</div>,
              Object.assign(opts, { className: 'info-toast' }),
            );
            break;
          default:
            toast.success(
              <div>{message}</div>,
              Object.assign(opts, {
                className: 'success-toast',
              }),
            );
        }
    }
  }

  static renderErrors(errors) {
    const errorsContent = [];
    _lodash.forEach(errors, (v, k) => {
      errorsContent.push(`${_lodash.titleize(k)} ${v.join(', ')}`);
    });

    return errorsContent.join(', ');
  }

  /**
   * Returns the product mode (or none if not in product mode)
   * @returns {String} product
   */
  static product() {
    const path = window.location.pathname;
    switch (true) {
      case /^\/recruiter\/archived/.test(path):
        return 'archived';
      case /^\/recruiter/.test(path):
        return 'recruiting';
      case /^\/retention/.test(path):
        return 'retention';
      case /^\/agent/.test(path):
        return 'agent';
      case /^\/archived/.test(path):
        return 'archived';
      case /^\/campaign_libraries/.test(path):
        return 'recruiting';
      default:
        return 'default';
    }
  }

  static productUriPrefix() {
    const product = this.product();

    if (product === 'recruiting') {
      return '/recruiter';
    }
    if (product === 'retention') {
      return '/retention';
    }

    return '';
  }

  static productUri(url) {
    return `${this.productUriPrefix()}${url}`;
  }

  /**
   * Returns the product mode (or none if not in product mode)
   * @returns {String} product
   */
  static productColor() {
    const path = window.location.pathname;
    switch (true) {
      case /^\/recruiter/.test(path):
        return 'green';
      case /^\/retention/.test(path):
        return 'blue';
      case /^\/agent/.test(path):
        return 'green';
      default:
        return 'green';
    }
  }

  constructor(props) {
    super(props);
    this.state = {};
    autoBind(this);
  }

  getChildContext() {
    const { abilities } = Rails;

    return {
      helpers: {
        openDrawer:     this.openDrawer,
        closeDrawer:    this.closeDrawer,
        openLeadDrawer: (options) => {
          this.openDrawer('lead', options);
        },
        openNewLeadDrawer: () => {
          this.openDrawer('new-lead');
        },
        openTeamMemberDrawer: (options) => {
          this.openDrawer('team-member', options);
        },
        openNewTeamMemberDrawer: () => {
          this.openDrawer('team-member', {
            externalRolesOnly: !abilities.manageCurrentTeam,
          });
        },
        openNewReferralDrawer: () => {
          this.openDrawer('new-referral');
        },
        openEmailEmailDrawer: (options) => {
          this.openDrawer('email-domain', options);
        },
      },
    };
  }

  componentDidMount() {
    let searchState = {};

    if (window.location.search) {
      searchState = qs.parse(
        window.location.search.toString().replace('?', ''),
      );
    }
    if (searchState.lead) {
      this.openDrawer('lead', { loadLeadID: searchState.lead });
    }
  }

  /**
   * Open the LeadDrawer by a reloaded Lead or load Lead by ID
   * @param {String} kind - Lead load options, support: lead, loadLeadID, defaultAction
   * @param {Object} options - Lead load options, support: lead, loadLeadID, defaultAction
   */
  openDrawer = (kind, options = {}) => {
    this.drawerManager.open(kind, options);
  }

  /**
   * Close lead drawer
   */
  closeDrawer() {
    this.drawerManager && this.drawerManager.close();
  }

  render() {
    const { widgets, children } = this.props;

    return (
      <DrawerContext.Provider value={{ openDrawer: this.openDrawer }}>
        <div
          className={classNames(
            GlobalContainer.product(),
            'content-container',
          )}
          style={{ minHeight: 'calc(100vh - 20px)' }}
        >
          <GlobalNotices />
          <NavBar />

          <div className="mt15">
            {widgets && <AttentionNeeded />}
            <div className="main react-app">
              <div className="container-fluid p15">{children}</div>
            </div>
          </div>
        </div>

        <footer className="" style={{ minHeight: '20px' }}>
          <span className="ml5">
            &copy;
            {' '}
            {Moment().format('YYYY')}
            {' '}
            BrokerKit Services Inc.
            <a
              href="/home/terms-of-service/"
              className="ml15 mr15"
            >
              Terms
            </a>
            <a href="/home/privacy-policy/">Privacy</a>
          </span>
        </footer>

        <DrawerManager ref={(el) => (this.drawerManager = el)} />
      </DrawerContext.Provider>
    );
  }
}

GlobalContainer.childContextTypes = {
  handleSearch:     PropTypes.func,
  handleTaskSearch: PropTypes.func,
  helpers:          PropTypes.shape({}),
};

GlobalContainer.contextTypes = {
  history: PropTypes.shape({}),
};

GlobalContainer.defaultProps = {
  children: null,
};

GlobalContainer.propTypes = {
  children: PropTypes.node,
};

export default GlobalContainer;
