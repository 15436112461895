import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarSupportedContent from './nav_bar_supported_content';
import HelpCenter from './help_center';
import NotificationButton from '~/components/Notifications/Button';

class RetentionNavContent extends React.PureComponent {
  onNewTeamMemberClick = (e) => {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.openNewTeamMemberDrawer();
  }

  render() {
    const { abilities } = Rails;

    return (
      <Nav className="ml-auto mr-auto-sm-down" navbar>
        <div className="d-flex">
          <NotificationButton />
          <HelpCenter />
        </div>

        { abilities.retention && (
          <NavItem className="mr20-md-up">
            <NavLink
              className="btn btn-blue btn-wide text-white"
              href="#new-team-member-drawer"
              onClick={this.onNewTeamMemberClick}
            >
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
              {' '}
              Add Team Member
            </NavLink>
          </NavItem>
        )}
        <NavBarSupportedContent />
      </Nav>
    );
  }
}

RetentionNavContent.defaultProps = {};
RetentionNavContent.propTypes = {};
RetentionNavContent.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default RetentionNavContent;
