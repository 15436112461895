import React               from 'react';
import PropTypes           from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DataTableSortLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortDirection: props.sortDirection,
    };

    this.onSortToggle = this.onSortToggle.bind(this);
    this.renderSortTitleContent = this.renderSortTitleContent.bind(this);
  }

  onSortToggle(e) {
    e.preventDefault();
    const { sortField, onSortToggle, tableSortField } = this.props;
    const { sortDirection } = this.state;

    let newDirection;
    switch (sortDirection) {
      case 'desc':
        newDirection = 'asc';
        break;
      case 'asc':
        newDirection = 'desc';
        break;
      default:
        newDirection = 'desc';
    }

    // reset internal sort direction if sort column changed
    if (tableSortField && tableSortField.column !== sortField.column) {
      newDirection = 'desc';
    }

    this.setState({
      sortDirection: newDirection,
    });

    onSortToggle(sortField, newDirection);
  }

  renderSortTitleContent() {
    const { sortField, tableSortField } = this.props;
    let { sortDirection } = this.state;

    if (tableSortField && tableSortField.column !== sortField.column) {
      sortDirection = null;
    }

    switch (sortDirection) {
      case 'asc':
        return (
          <span className="text-black">
            {sortField.title}
            {' '}
            <FontAwesomeIcon icon={['fas', 'fa-caret-down']} />
          </span>
        );
      case 'desc':
        return (
          <span className="text-black">
            {sortField.title}
            {' '}
            <FontAwesomeIcon icon={['fas', 'fa-caret-up']} />
          </span>
        );
      default:
        return sortField.title;
    }
  }

  render() {
    const { sortField } = this.props;

    return (
      <a href={`#${sortField.column}`} className="sort_link text-grey" onClick={this.onSortToggle}>
        {this.renderSortTitleContent()}
      </a>
    );
  }
}

DataTableSortLink.defaultProps = {
  sortDirection:      null,
  tableSortDirection: null,
  onSortToggle:       () => false,
};

DataTableSortLink.propTypes = {
  sortField: PropTypes.shape({
    column: PropTypes.string.isRequired,
    title:  PropTypes.string.isRequired,
  }).isRequired,
  sortDirection: PropTypes.string,

  tableSortField: PropTypes.shape({
    column: PropTypes.string.isRequired,
    title:  PropTypes.string.isRequired,
  }).isRequired,
  tableSortDirection: PropTypes.string,

  onSortToggle: PropTypes.func,
};

export default DataTableSortLink;
