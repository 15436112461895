import autoBind            from 'react-autobind';
import React, { useState } from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tabs from 'react-bootstrap/Tabs';
import Tab  from 'react-bootstrap/Tab';

import APIRequest            from '~/lib/api_request';
import TwilioBundleActions   from '~/actions/twilio_bundle_actions';
import BusinessProfileStore  from '~/stores/business_profile_store';
import TwilioBundleStore     from '~/stores/twilio_bundle_store';
import BusinessContactsStore from '~/stores/business_contacts_store';
import ProfileStatusTab      from './tabs/profile_status_tab';
import BusinessProfileTab    from './tabs/business_profile_tab';
import BusinessContactsTab   from './tabs/business_contacts_tab';
import VerifiedTextingTab    from './tabs/verified_texting_tab';

function TwilioTabs(props) {
  const [key, setKey] = useState('profile_status');

  return (
    <Tabs
      id="data-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="data-tabs mb15 clearfix"
      mountOnEnter
      unmountOnExit
    >
      <Tab eventKey="profile_status" title="Profile Status">
        <ProfileStatusTab
          {...props}
          setTab={setKey}
        />
      </Tab>
      <Tab eventKey="business_profile" title="Business Profile" disabled={_lodash.isEmpty(props.bundle)}>
        <BusinessProfileTab
          {...props}
          setTab={setKey}
        />
      </Tab>
      <Tab eventKey="business_contacts" title="Business Contacts" disabled={_lodash.isEmpty(props.bundle)}>
        <BusinessContactsTab
          {...props}
          setTab={setKey}
        />
      </Tab>
      <Tab eventKey="verified_texting" title="Verified Texting" disabled={_lodash.isEmpty(props.bundle)}>
        <VerifiedTextingTab
          {...props}
          setTab={setKey}
        />
      </Tab>
    </Tabs>
  );
}

class TwilioProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bundle:                      {},
      status:                      {},
      business_information:        {},
      address:                     {},
      authorized_representative_1: {},
      authorized_representative_2: {},
      shaken_stir:                 {},
      brand:                       {},
      loading:                     true,
      error:                       false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.listener = BusinessProfileStore.addListener(this.onBusinessProfileStoreChange);
    this.twilio_bundle_listener = TwilioBundleStore.addListener(this.onTwilioBundleStoreChange);
    this.contacts_listener = BusinessContactsStore.addListener(this.onBusinessContactsStoreChange);

    TwilioBundleActions.loadTwilioBundle();
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
    if (this.twilio_bundle_listener) this.twilio_bundle_listener.remove();
    if (this.contacts_listener) this.contacts_listener.remove();
  }

  onTwilioBundleStoreChange() {
    const {
      bundle,
      status,
      business_information,
      address,
      authorized_representative_1,
      authorized_representative_2,
      shaken_stir,
      brand,
      loading,
      error,
    } = TwilioBundleStore.getState();

    const newState = {
      loading,
      error,
      bundle,
      status,
      business_information,
      address,
      authorized_representative_1,
      authorized_representative_2,
      shaken_stir,
      brand,
    };

    this.setState(newState);
  }

  onBusinessContactsStoreChange() {
    const {
      authorized_representative_1,
      authorized_representative_2,
      lastBusinessContactsStoreAction,
    } = BusinessContactsStore.getState();

    let newState = {};

    if (lastBusinessContactsStoreAction === 'createContactsDone'
        || lastBusinessContactsStoreAction === 'updateContactsDone') {
      newState = { authorized_representative_1, authorized_representative_2 };
    }

    this.setState(newState);
  }

  onBusinessProfileStoreChange() {
    const {
      bundle,
      business_information,
      address,
      lastBusinessProfileStoreAction,
    } = BusinessProfileStore.getState();

    let newState = {};

    if (lastBusinessProfileStoreAction === 'createProfileDone'
        || lastBusinessProfileStoreAction === 'updateProfileDone') {
      newState = { bundle, business_information, address };
    }

    this.setState(newState);
  }

  render() {
    const {
      error,
      loading,
      bundle,
      status,
      business_information,
      address,
      authorized_representative_1,
      authorized_representative_2,
      shaken_stir,
      brand,
    } = this.state;

    if (loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-center">
          Oops, something went wrong. Try again later.
        </div>
      );
    }

    return (
      <>
        <TwilioTabs
          bundle={bundle}
          status={status}
          business_information={business_information}
          address={address}
          authorized_representative_1={authorized_representative_1}
          authorized_representative_2={authorized_representative_2}
          shaken_stir={shaken_stir}
          brand={brand}
        />
      </>
    );
  }
}

export default TwilioProfile;
