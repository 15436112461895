import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import React               from 'react';
import Collapse            from 'react-bootstrap/Collapse';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadHelpers           from '~/helpers/lead_helpers';
import Tooltipable           from '~/components/effects/tooltipable';
import LeadsSelect           from '~/components/forms/leads_select';
import TaskCategorySelect    from '~/components/forms/task_category_select';
import TaskPrioritySelect    from '~/components/forms/task_priority_select';
import TaskStatusSelect      from '~/components/forms/task_status_select';
import TaskEditor            from '~/components/forms/HtmlEditors/TaskEditor';
import FollowUpPicker        from '~/components/forms/lead_fields/follow_up_picker';
import { TeammatePicker }    from '~/components/forms/team_member_picker';
import { RatingSelect }      from '~/components/forms/lead_fields';
import CallDispositionSelect from '~/components/forms/call_disposition_select';
import StatusSelect          from '~/components/forms/dropdowns/StatusSelect';
import ScheduleButton        from '../ScheduleForm/ScheduleButton';
import { OverlayTrigger } from 'react-bootstrap';

class FormFooterView extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      isOpen: false,
    };

    this.containerRef = React.createRef();
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;
    const { setTaskField } = this.props;

    this.setState({ isOpen: !isOpen });
    setTaskField('isOpen', !isOpen);
  }

  renderTasksForm = () => {
    const {
      errors, task, setTaskField,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="task_name">Task Name</label>
            <input
              id="task_name"
              name="name"
              value={task.name}
              className={classNames('d-block form-control', errors.name ? 'has-error' : '')}
              placeholder="Enter Name"
              onChange={(val) => setTaskField('name', val && val.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputCategory">Task Category</label>
            <TaskCategorySelect
              id="inputCategory"
              name="category"
              placeholder="Select Category"
              value={task.category}
              className={errors.category ? 'has-error' : ''}
              onChange={(opt) => setTaskField('category', opt && opt.value)}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="task_description" className="d-block">
            Description
          </label>
          <TaskEditor
            className={errors.description ? 'tiny-mce has-error' : ''}
            onChange={(html) => setTaskField('description', html)}
            placeholder="Enter Description"
            id="task_description"
            spellCheck={isOpen}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="leads-tasks" className="d-block">
              Related Contacts
            </label>
            <LeadsSelect
              id="leads-tasks"
              placeholder="Please Select Contacts ..."
              selected_ids={task.lead_ids}
              multiple
              onChange={(ids) => setTaskField('lead_ids', ids)}
              isInvalid={!!errors.lead_ids}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label
              htmlFor="task_owner_id"
              className="form-control-label"
            >
              Owner
            </label>
            <TeammatePicker
              id="task_owner_id"
              name="owner_id"
              placeholder="Owner"
              value={task.owner_id}
              onChange={(opt) => setTaskField('owner_id', opt && opt.value)}
              clearable
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_priority">Priority</label>
            <TaskPrioritySelect
              id="task_priority"
              name="priority"
              placeholder="Select Priority"
              value={task.priority}
              className={errors.priority ? 'has-error' : ''}
              onChange={(opt) => setTaskField('priority', opt && opt.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_status">Status</label>
            <TaskStatusSelect
              id="task_status"
              name="status"
              placeholder="Select Status"
              value={task.status}
              className={errors.status ? 'has-error' : ''}
              onChange={(opt) => setTaskField('status', opt && opt.value)}
            />
          </div>
        </div>
      </>
    );
  }

  renderTasksControls = () => {
    const { isOpen } = this.state;
    const { errors, task, setTaskField } = this.props;

    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <button type="button" className="btn btn-outline-info" onClick={this.onCollapseClick}>
              { isOpen ? (
                <FontAwesomeIcon icon={['far', 'fa-minus']} />
              ) : (
                <FontAwesomeIcon icon={['far', 'fa-plus']} />
              )}
            </button>
          </div>
          <FollowUpPicker
            ref={(el) => { this.picker = el; }}
            placeholder="Follow Up Task"
            lead
            date={task.due_date_at}
            className={classNames({ 'has-error': errors.due_date_at })}
            onChange={(picker) => setTaskField('due_date_at', picker && picker.date)}
            right
          />
        </div>
        <p className="pt3">Select date for Follow Up</p>
      </>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { showScheduleButton, setSchedule } = this.props;
    const {
      lead,
      onRatingChange,
      rating,
      internal,
      status,
      call_disposition,
      errors,
      showRatingSelect,
      sending,
      submitButtonText,
      submitButtonSendingText,
      onSubmit,
      disabledButton,
      activityType,
      onCallDispositionChange,
      onStatusChange,
      onToggleSharedType,
      submitActionsComponent,
      schedule,
      scheduleBtnPopoverPlacement,
      isEditingMode,
      showPopover,
      renderPopover,
    } = this.props;

    const product = GlobalContainer.product();
    const showTaskSelect = !(product === 'recruiting' && status === 'onboarding'); // show anytime it's not recruiting and Signed (onboardind) status
    const stage = LeadHelpers.determineStatusStage(lead);
    const isAdminOrStaff = Rails.abilities.manageLead;

    return (
      <div>
        {
          !isEditingMode && (
            <>
              <div className="row m0">
                <div className="col-lg-4 pl0 pr0 mb10-md-down">
                  {activityType === 'phone_call' && (
                    <CallDispositionSelect
                      name="call_disposition"
                      placeholder="Call Disposition"
                      value={call_disposition}
                      className="mb-2"
                      onChange={onCallDispositionChange}
                    />
                  )}

                  {isAdminOrStaff && (
                    <StatusSelect
                      id="status"
                      name="status"
                      placeholder="Status"
                      value={status}
                      stage={stage}
                      onChange={onStatusChange}
                      isInvalid={!!errors.status}
                      isSearchable
                    />
                  )}

                  {errors.status && (
                    <div className="alert alert-danger mt-2" role="alert">
                      {errors.status}
                    </div>
                  )}
                </div>

                {showRatingSelect && (
                  <div className="col-lg-4 pl0-md-down pr0-md-down mb10">
                    <RatingSelect
                      value={rating}
                      onChange={onRatingChange}
                      placeholder="Rating ..."
                    />
                  </div>
                )}

                {showTaskSelect && isAdminOrStaff && (
                  <div
                    className={classNames(
                      'col-lg-4 pl0 pr0 mb10 col-md-12',
                      !showRatingSelect && 'ml-auto',
                    )}
                  >
                    {this.renderTasksControls()}
                  </div>
                )}
              </div>

              <Collapse in={isOpen}>
                <div className="mt20">{this.renderTasksForm()}</div>
              </Collapse>
            </>
          )
        }

        <div className="row mt20 ml0 mr0">
          <div className="col-sm-6 mb10 p0">
            {isAdminOrStaff && (
              internal ? (
                <Tooltipable text="Shared with staff only">
                  <button
                    type="button"
                    id="visibility-button"
                    className="btn btn-secondary"
                    onClick={onToggleSharedType}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'fa-eye-slash']}
                      className="mr5"
                    />
                    {' '}
                    Staff Only
                  </button>
                </Tooltipable>
              ) : (
                <Tooltipable text="Shared with referring agent">
                  <button
                    type="button"
                    id="visibility-button"
                    className="btn btn-secondary"
                    onClick={onToggleSharedType}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'fa-eye']}
                      className="mr5"
                    />
                    {' '}
                    Shared
                  </button>
                </Tooltipable>
              )
            )}
          </div>
          <div className="col-sm-6 text-right p0 mb10" ref={this.containerRef}>
            <div>
              {submitActionsComponent || (
                <>
                  <div className="btn-group" role="group" aria-label="email-group">
                    {submitButtonText === "Send with Google" ? (
                      <>
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          show={showPopover}
                          overlay={renderPopover}
                        >
                          <button
                            type="submit"
                            className={classNames(
                              'btn btn-primary btn-block-sm-down',
                              { disabled: sending },
                            )}
                            onClick={onSubmit}
                            disabled={disabledButton || sending}
                          >
                            {sending ? (
                              <span>
                                <FontAwesomeIcon
                                  icon="far fa-spinner"
                                  pulse
                                  className="mr5"
                                />
                                {' '}
                                {submitButtonSendingText}
                              </span>
                            ) : (
                              submitButtonText
                            )}
                          </button>
                        </OverlayTrigger>

                        {showScheduleButton && (
                          <ScheduleButton
                            schedule={schedule}
                            setSchedule={setSchedule}
                            disabled={disabledButton || sending}
                            containerRef={this.containerRef}
                            popoverPlacement={scheduleBtnPopoverPlacement}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className={classNames(
                            'btn btn-primary btn-block-sm-down',
                            { disabled: sending },
                          )}
                          onClick={onSubmit}
                          disabled={disabledButton || sending}
                        >
                          {sending ? (
                            <span>
                              <FontAwesomeIcon
                                icon="far fa-spinner"
                                pulse
                                className="mr5"
                              />
                              {' '}
                              {submitButtonSendingText}
                            </span>
                          ) : (
                            submitButtonText
                          )}
                        </button>

                        {showScheduleButton && (
                          <ScheduleButton
                            schedule={schedule}
                            setSchedule={setSchedule}
                            disabled={disabledButton || sending}
                            containerRef={this.containerRef}
                            popoverPlacement={scheduleBtnPopoverPlacement}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormFooterView.propTypes = {
  scheduleBtnPopoverPlacement: PropTypes.string,
  rating:                      PropTypes.string,
  status:                      PropTypes.string,
  call_disposition:            PropTypes.string,
  warningMessage:              PropTypes.string,
  submitButtonText:            PropTypes.string.isRequired,
  submitButtonSendingText:     PropTypes.string.isRequired,
  lead:                        PropTypes.shape({}),
  task:                        PropTypes.shape({}),
  errors:                      PropTypes.shape({}),
  schedule:                    PropTypes.shape({}),
  submitActionsComponent:      PropTypes.shape({}),
  internal:                    PropTypes.bool,
  sending:                     PropTypes.bool,
  showRatingSelect:            PropTypes.bool,
  disabledButton:              PropTypes.bool,
  showScheduleButton:          PropTypes.bool,
  isEditingMode:               PropTypes.bool,
  showPopover:                 PropTypes.bool,
  setSchedule:                 PropTypes.func,
  onToggleSharedType:          PropTypes.func.isRequired,
  onStatusChange:              PropTypes.func.isRequired,
  onCallDispositionChange:     PropTypes.func.isRequired,
  setTaskField:                PropTypes.func.isRequired,
  onRatingChange:              PropTypes.func.isRequired,
  onSubmit:                    PropTypes.func.isRequired,
  renderPopover:               PropTypes.func.isRequired,
};

FormFooterView.defaultProps = {
  scheduleBtnPopoverPlacement: 'bottom',
  rating:                      '',
  status:                      '',
  call_disposition:            '',
  lead:                        {},
  task:                        {},
  errors:                      {},
  schedule:                    {},
  submitActionsComponent:      null,
  internal:                    false,
  sending:                     false,
  showRatingSelect:            false,
  disabledButton:              false,
  showScheduleButton:          false,
  isEditingMode:               false,
  setSchedule:                 () => {},
};

export default FormFooterView;
