import React, { memo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Tooltipable from '~/components/effects/tooltipable';
import { SkeletonText } from '~/components/forms/SkeletonComponents';

const getIconByType = (notificationType) => {
  const icons = {
    email: 'fa-envelope',
    text:  'fa-comment',
    video: 'fa-video',
  };
  return icons[notificationType] || 'fa-empty-set';
};

const NotificationItem = memo(({
  notification,
  toggleReadStatus,
  isUpdating,
  loadingSkeleton,
}) => {
  const getUrl = () => {
    const prefix = notification.RecruitingStatus !== false ? 'recruiter' : 'retention';
    const path = notification.InboxConversationID ? 'inbox' : 'today';
    const params = notification.InboxConversationID
      ? `name=${notification.LeadName}&conversation=${notification.InboxConversationID}&message=${notification.InboxMessageID}`
      : `lead=${notification.LeadID}&_t=${notification.TeamID}#${notification.OriginatingObjectID}-${notification.OriginatingObjectType}`;

    return `/${prefix}/${path}?${params}`;
  };

  const handleLinkClick = useCallback(async (e) => {
    e.preventDefault();

    if (!notification.IsRead) {
      await toggleReadStatus(notification, !notification.IsRead);
    }

    window.location.href = getUrl();
  }, [notification, toggleReadStatus]);

  return (
    <div className={classNames('px-3 d-flex list-group-item rounded-0 border-left-0 border-right-0 border-top-0 border-bottom justify-content-between align-items-center', { 'loading-skeleton': loadingSkeleton })}>
      <div className="d-flex align-items-center mr-2">
        <div className="bg-light rounded-circle d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px' }}>
          {!loadingSkeleton && <FontAwesomeIcon icon={['fal', getIconByType(notification.IconType)]} size="lg" className="text-secondary" />}
        </div>
      </div>

      <div className="flex-grow-1">
        <div className="d-flex flex-wrap align-items-center text-grey-dark">
          <span className="mr-2">
            {loadingSkeleton ? (
              <SkeletonText data-testid="skeleton-text" className="bg-grey-lightest" />
            ) : (
              <>
                <a href="#" className="text-dark" onClick={handleLinkClick}>
                  {notification.LeadName}
                </a>
                {' '}
                {notification.Title}
              </>
            )}
          </span>

          <span className="rounded-circle bg-grey mr-2" style={{ width: '4px', height: '4px' }} />
          <span className="text-success small">
            {loadingSkeleton ? (
              <SkeletonText data-testid="skeleton-text" className="bg-grey-lightest" />
            ) : (
              Moment(notification.CreatedAt).fromNow()
            )}
          </span>
        </div>

        <div className={notification?.IsRead ? 'font-weight-normal' : 'font-weight-bold'}>
          {loadingSkeleton ? (
            <SkeletonText data-testid="skeleton-text" width="250px" className="mt-2 bg-grey-lightest" />
          ) : (
            notification.Content
          )}
        </div>
      </div>

      {!loadingSkeleton && (
        <Tooltipable text={notification.IsRead ? 'Mark as unread' : 'Mark as read'} placement="left">
          <button
            type="button"
            className={classNames('btn btn-link shadow-none p-0 mr-2', {
              'read-button':   notification.IsRead,
              'unread-button': !notification.IsRead,
            })}
            onClick={() => toggleReadStatus(notification, !notification.IsRead)}
            aria-label={notification.IsRead ? 'Mark as unread' : 'Mark as read'}
          >
            {isUpdating ? (
              <FontAwesomeIcon icon="fa-spinner" className="text-secondary" size="xs" spin />
            ) : (
              !notification.IsRead && <FontAwesomeIcon icon="fa-circle" className="text-success" size="xs" />
            )}
          </button>
        </Tooltipable>
      )}
    </div>
  );
});

export default NotificationItem;
