import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import APIRequest from '~/lib/api_request';
import LeadDrawerStore from '~/stores/lead_drawer_store';
import CampaignSubscriptionStore from '~/stores/campaign_subscription_store';
import GlobalContainer from '~/components/global_container';
import TaskStore from '~/stores/task_store';
import Activities from './timeline/Activities';

const tabs = [
  'all',
  'changes',
  'notes',
  'emails',
  'calls',
  'texts',
  'tasks',
  'appointments',
];

const LeadDrawerTimeline = ({ lead, setCurrentFormWithData }) => {
  const [activeTab, setActiveTab] = useState(GlobalContainer.urlParams()?.tab || 'all');
  const [expanded, setExpanded] = useState(false);
  const [hideOlderThan, setHideOlderThan] = useState(new Date());

  const [activities, setActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [errors, setErrors] = useState(null);

  const isMounted = useRef(true);

  const attachStoreListener = useCallback(
    (store, actions, lastActionProperty) => {
      const listener = store.addListener(() => {
        const state = store.getState();

        if (actions.includes(state[lastActionProperty])) {
          fetchLeadActivities(lead.id, activeTab);
        }
      });

      return () => listener.remove();
    },
    [lead.id, activeTab, fetchLeadActivities],
  );

  useEffect(() => attachStoreListener(
    CampaignSubscriptionStore,
    [
      'createCampaignSubscriptionDone',
      'deleteCampaignSubscriptionDone',
    ],
    'lastCampaignSubscriptionStoreAction',
  ), [lead.id, activeTab, attachStoreListener]);

  useEffect(() => attachStoreListener(
    TaskStore,
    ['createTaskDone', 'deleteTaskDone'],
    'lastTaskStoreAction',
  ), [lead.id, activeTab, attachStoreListener]);

  useEffect(
    () => attachStoreListener(
      LeadDrawerStore,
      [
        'updateLeadDone',
        'updateLeadRoleDone',
        'createLeadAppointmentDone',
        'createLeadSmsMessageDone',
        'createLeadEmailDone',
        'createLeadActivityDone',
        'updateLeadActivityDone',
        'cancelScheduledLeadEmailDone',
        'updateLeadEmailDone',
        'updateLeadSmsMessageDone',
        'cancelScheduledLeadSmsMessageDone',
      ],
      'leadDrawerStoreAction',
    ),
    [lead.id, activeTab, attachStoreListener],
  );

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    fetchLeadActivities(lead.id, activeTab);
  }, [lead.id, activeTab]);

  useEffect(() => {
    if (GlobalContainer.product() === 'retention' && lead.joined_at) {
      setExpanded(true);
      setHideOlderThan(Moment(lead.joined_at).add(1, 'second'));
    }
  }, [lead.joined_at]);

  const fetchLeadActivities = useCallback((leadID, type) => {
    setLoadingActivities(true);
    setErrors(null);

    const queryParams = type ? { type } : {};

    const request = APIRequest.get({
      resource: `/v1/leads/${leadID}/activities`,
      data:     queryParams,
    }).end((error, response) => {
      if (!isMounted.current) return;

      if (error) {
        setErrors((prevErrors) => [...prevErrors, error]);
      } else {
        setActivities(response.body);
      }

      setLoadingActivities(false);
    });

    return () => {
      if (request) request.abort();
    };
  }, []);

  const handleTabSelect = (key, e) => {
    if (activeTab === key) return;

    e.currentTarget.blur();

    setActiveTab(key);
  };

  const productClass = GlobalContainer.product() === 'retention'
    ? 'retention-activities'
    : 'recruiting-activities';

  const memoizedTabs = useMemo(() => (
    <Tabs
      id="activities-tabs"
      activeKey={activeTab}
      onSelect={handleTabSelect}
      variant="pills"
      className={productClass}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab}
          disabled={loadingActivities}
          eventKey={tab}
          title={_lodash.startCase(tab)}
        />
      ))}
    </Tabs>
  ), [activeTab, loadingActivities]);

  return (
    <>
      <h3 className="mb15"> Activity </h3>

      {memoizedTabs}

      <Activities
        loadingActivities={loadingActivities}
        activities={activities}
        errors={errors}
        activeTab={activeTab}
        hideOlderThan={hideOlderThan}
        expanded={expanded}
        setExpanded={setExpanded}
        lead={lead}
        setCurrentFormWithData={setCurrentFormWithData}
      />
    </>
  );
};

LeadDrawerTimeline.defaultProps = {
  lead:                   {},
  setCurrentFormWithData: () => {},
};

LeadDrawerTimeline.propTypes = {
  lead: PropTypes.shape({
    joined_at: PropTypes.string,
    id:        PropTypes.number,
  }),
  setCurrentFormWithData: PropTypes.func,
};

export default React.memo(LeadDrawerTimeline);
