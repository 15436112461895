import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import update              from 'immutability-helper';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Redirect }  from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable from '~/components/effects/tooltipable';
import GlobalContainer from '~/components/global_container';
import ActivatedMlsesSelect from '~/components/forms/activated_mlses_select';
import ErrorMessage from '~/components/forms/ErrorMessage';
import Select from '~/components/forms/select';

import SearchQueryConditionForm from './conditions/search_query_condition_form';

import SearchQueryActions from '~/actions/search_query_actions';
import SearchQueryStore   from '~/stores/search_query_store';

const ProductOptions = [
  { value: 'recruiting', label: 'Recruiting' },
  { value: 'retention',  label: 'Retention' },
];

class SearchQueryForm extends React.Component {
  constructor(props) {
    super(props);

    const { searchQuery } = this.props;
    const { currentTeam } = Rails.helpers;

    this.newCondition = {
      id:                 null,
      search_field_id:    null,
      search_operator_id: null,
      value:              '',
      _destroy:           false,
    };

    this.state = {
      submitting:                     false,
      errors:                         {},
      redirect:                       null,
      searchQueryForm: {
        id:                           null,
        name:                         '',
        team_id:                      currentTeam.id,
        importers_mlses_teams_id:     null,
        product:                      'recruiting',
        email_notification:           false,
        labels:                       '',
        data:                         {},
        search_conditions_attributes: [{ ...this.newCondition }],
      },
    };

    if (searchQuery) {
      this.state.searchQueryForm = this.buildSearchQueryFormState(searchQuery);
    }

    autoBind(this);
  }

  componentDidMount() {
    this.searchQueryStoreListener = SearchQueryStore.addListener(this.onSearchQueryStoreChange);
  }

  componentWillUnmount() {
    if (this.searchQueryStoreListener) this.searchQueryStoreListener.remove();
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { searchQueryForm } = this.state;
    const errors = this.validate(searchQueryForm);

    if (_lodash.size(errors) === 0) {
      this.setState({ submitting: true }, () => {
        if (_lodash.isNumber(searchQueryForm.id)) {
          SearchQueryActions.updateSearchQuery(searchQueryForm);
        } else {
          SearchQueryActions.createSearchQuery(searchQueryForm);
        }
      });
    }
  }

  onSearchQueryStoreChange() {
    const searchQueryStoreState = SearchQueryStore.getState();
    const {
      searchQuery, lastSearchQueryStoreAction, errors, submitting,
    } = searchQueryStoreState;
    const search_queries_uri = GlobalContainer.productUri('/data');

    let nextState = { searchQueryStoreState };

    switch (lastSearchQueryStoreAction) {
      case 'createSearchQueryDone':
        GlobalContainer.notify(`Import Query "${searchQuery.name}" created.`, 'success');
        nextState = { errors, submitting, redirect: search_queries_uri };

        break;
      case 'createSearchQueryFail':
        nextState = { ...nextState, errors, submitting };

        break;
      case 'updateSearchQueryDone':
        GlobalContainer.notify(`Import Query "${searchQuery.name}" updated.`, 'success');
        nextState = { errors, submitting, redirect: search_queries_uri };

        break;
      case 'updateSearchQueryFail':
        nextState = { ...nextState, errors, submitting };
        break;
      default:
    }

    this.setState(nextState);
  }

  setQueryField(name, val) {
    this.setState((prevState) => ({
      searchQueryForm: update(prevState.searchQueryForm, { [name]: { $set: val } }),
    }));
  }

  updateCondition = (conditionIdx, conditionDataFragment) => {
    const { searchQueryForm } = this.state;
    const updateCondition = searchQueryForm.search_conditions_attributes[conditionIdx];

    const newState = update(this.state, {
      searchQueryForm: {
        search_conditions_attributes: {
          $splice: [[conditionIdx, 1, { ...updateCondition, ...conditionDataFragment }]],
        },
      },
    });

    this.setState(newState);
  }

  deleteCondition = (conditionIdx) => {
    const { searchQueryForm } = this.state;
    const deleteCondition = searchQueryForm.search_conditions_attributes[conditionIdx];

    deleteCondition._destroy = true;

    const newState = update(this.state, {
      searchQueryForm: {
        search_conditions_attributes: {
          $splice: [[conditionIdx, 1, deleteCondition]],
        },
      },
    });

    this.setState(newState);
  }

  addCondition(e) {
    e.preventDefault();

    const { searchQueryForm } = this.state;

    searchQueryForm.search_conditions_attributes.push({ ...this.newCondition });

    this.setState({ searchQueryForm });
  }

  validate(searchQueryForm) {
    const {
      name, importers_mlses_teams_id, product, search_conditions_attributes,
    } = searchQueryForm;
    const errors = {};

    if (!name) {
      errors.name = ['Please enter a name to save this query'];
    }

    if (!importers_mlses_teams_id) {
      errors.importers_mlses_teams_id = ['Please select a MLS to save this query'];
    }

    if (!product) {
      errors.product = ["Can't be empty"];
    }

    if (search_conditions_attributes.length === 0) {
      errors.conditions = ["Can't be empty"];
    }

    search_conditions_attributes.forEach((condition, index) => {
      if (condition._destroy === true) return;

      errors[index] = {};

      if (!condition.search_field_id) {
        errors[index].search_field_id =  ["Can't be empty"];
      }

      if (!condition.search_operator_id) {
        errors[index].search_operator_id = ["Can't be empty"];
      }

      if (!condition.value) {
        errors[index].value = ["Can't be empty"];
      }

      if (_lodash.size(errors[index]) === 0) delete errors[index];
    });

    this.setState({ errors });

    return errors;
  }

  buildSearchQueryFormState(searchQueryProp) {
    if (!searchQueryProp) return null;

    return {
      id:                           searchQueryProp.id,
      name:                         searchQueryProp.name,
      importers_mlses_teams_id:     searchQueryProp.importers_mlses_teams_id,
      product:                      searchQueryProp.product,
      email_notification:           searchQueryProp.email_notification,
      labels:                       searchQueryProp.labels,
      data:                         searchQueryProp.data,
      search_conditions_attributes: searchQueryProp.search_conditions.map((condition, idx) => _lodash.pickBy({
        id:                 condition.id,
        search_field_id:    condition.search_field_id,
        search_operator_id: condition.search_operator_id,
        value:              condition.value,
      })),
    };
  }

  renderErrors() {
    const { errors } = this.state;

    if (errors.conditions) {
      return (
        <div className="alert alert-danger text-left mb15">
          Can&lsquo;t save Import Query without Conditions.
        </div>
      );
    }

    return null;
  }

  renderSearchQueryConditions() {
    const { searchQueryForm, errors } = this.state;
    const { product } = searchQueryForm;
    const conditionAttributes = searchQueryForm.search_conditions_attributes;

    return conditionAttributes.map((condition, idx) => {
      if (condition._destroy === true) return null;

      const conditionDOM = (
        <SearchQueryConditionForm
          key={idx}
          condition={condition}
          product={product}
          conditionIdx={idx}
          errors={errors}
          updateCondition={this.updateCondition}
          deleteCondition={this.deleteCondition}
        />
      );

      return conditionDOM;
    });
  }

  render() {
    const {
      searchQueryForm, submitting, errors, redirect,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: redirect,
            dataTab:  'mls_import',
          }}
        />
      );
    }

    return (
      <div>
        <form method="POST" onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-sm-6 col-xs-12 form-group">
              <label htmlFor="search_query_name">
                Import Query Name:
              </label>
              <input
                id="search_query_name"
                name="search_query[name]"
                className={classNames('form-control', {
                  'has-error': errors.name,
                })}
                placeholder="Import Query Name"
                value={searchQueryForm.name}
                onChange={(e) => this.setQueryField('name', e && e.target.value)}
              />
              <div>
                {errors.name && (
                  <ErrorMessage message={errors.name} />
                )}
              </div>
            </div>

            <div className="col-sm-6 col-xs-12 form-group" id="mls-select">
              <label htmlFor="search_query_name">Select MLS:</label>
              <ActivatedMlsesSelect
                placeholder="--- Select a MLS ---"
                value={searchQueryForm.importers_mlses_teams_id}
                onChange={(val) => this.setQueryField('importers_mlses_teams_id', val)}
                className={classNames({ 'has-error': !!errors.importers_mlses_teams_id })}
                clearable
                target="mls-select"
              />
              <div>{errors.importers_mlses_teams_id && <ErrorMessage message={errors.importers_mlses_teams_id} />}</div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 form-group">
              <span className="mr-2">Data will Import/Update</span>

              <div className="d-inline-flex mr-2">
                <Select
                  placeholder="Select ..."
                  className={classNames({
                    'has-error': !!errors.product,
                  })}
                  value={searchQueryForm.product}
                  onChange={(opt) => this.setQueryField(
                    'product',
                    opt ? opt.value : 'recruiting',
                  )}
                  options={ProductOptions}
                  style={{ width: '110px' }}
                />
              </div>

              <span>
                when All of the following conditions are met:
              </span>
            </div>
          </div>

          <hr className="dotted-hr" />

          <div className="search-query-conditions">
            <div className="row">
              <div className="col my-1 text-center">Field</div>
              <div className="col my-1 text-center">Operator</div>
              <div className="col my-1 text-center">Value</div>
              <div className="col-lg-1 text-center" />
            </div>

            {this.renderErrors()}
            {this.renderSearchQueryConditions()}
          </div>

          <a
            href="#condition"
            id="btn-condition"
            className="btn btn-primary"
            onClick={this.addCondition}
          >
            Add New Condition
          </a>

          <div className="row mt-4">
            <div className="col form-group">
              <label htmlFor="search_query_name">
                Comma Separated Labels to add to Leads:
              </label>
              <input
                id="search_query_labels"
                name="search_query[labels]"
                className="form-control"
                value={searchQueryForm.labels}
                onChange={(e) => this.setQueryField('labels', e && e.target.value)}
              />
            </div>
          </div>

          <Tooltipable
            placement="bottom"
            text="This count is updated each time you save the search and it can change through time based on MLS data changes"
          >
            <p>
              Query Result Count:
              {' '}
              {searchQueryForm.data.count || 0}
            </p>
          </Tooltipable>

          <hr className="dotted-hr" />

          <div className="row">
            <div className="col-6 my-auto">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="search_query_email_notification"
                  checked={searchQueryForm.email_notification}
                  onChange={(e) => this.setQueryField(
                    'email_notification',
                    e && e.target.checked,
                  )}
                />

                <Tooltipable
                  placement="bottom"
                  text="You will receive email notifications when imports and updates are completed if you are subscribed"
                >
                  <label
                    className="ml-1 form-check-label"
                    htmlFor="search_query_email_notification"
                  >
                    Receive email notifications
                  </label>
                </Tooltipable>
              </div>
            </div>

            <div className="col-6">
              <div className="pull-right">
                {submitting ? (
                  <button
                    type="button"
                    className="btn btn-primary disabled"
                    disabled
                  >
                    <FontAwesomeIcon
                      icon="far fa-spinner"
                      pulse
                      className="mr5"
                    />
                    {' '}
                    Saving ...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Save Query
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SearchQueryForm.defaultProps = {
  searchQuery: null,
};

SearchQueryForm.propTypes = {
  history:     PropTypes.shape({}).isRequired,
  searchQuery: PropTypes.shape({}),
};

export default SearchQueryForm;
