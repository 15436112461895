import React from 'react';
import Draggable           from 'react-draggable';
import { PropTypes }       from 'prop-types';
import autoBind            from 'react-autobind';
import { Device }          from 'twilio-client';
import axios               from 'axios';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIRequest          from '~/lib/api_request';
import AppDispatcher       from '~/dispatchers/app_dispatcher';

import LeadHelpers         from '~/helpers/lead_helpers';
import AppModal            from './app_modal';
import { BUTTONS }         from '~/constants/DigitButtons';

let pendingActivityRequest = null;

class Dialer extends React.Component {
  _isMounted = false;

  device = new Device();

  connection = null;

  constructor(props) {
    super(props);

    const { currentUser, currentTeam } = Rails.helpers;
    const smsNumber = _lodash.first(currentUser.sms_numbers);

    this.state = {
      body:            '',
      teamId:          currentTeam.id,
      userId:          currentUser.id,
      muted:           false,
      hold:            false,
      callLog:         'Call Initialization',
      onPhone:         false,
      userPhoneNumber: smsNumber ? smsNumber.service_number : null,
      recordCall:      currentUser.role
        ? currentUser.role.record_calls
        : false,
      error:            '',
      initialized:      false,
      timerOn:          false,
      timerStart:       0,
      timerTime:        0,
      creatingActivity: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    // Fetch Twilio capability token
    axios
      .get(`${Rails.apiUrl}/twilio/token`)
      .then((response) => {
        this.device.setup(response.data.token, {
          edge:               'ashburn',
          enableRingingState: true,
        });
      })
      .catch((error) => {
        this.setState({
          error:
            'Could not fetch token, please reload the page and try again',
        });
      });

    this.handleTwilioEvents();
  }

  componentWillUnmount() {
    const { onDialerClose } = this.props;
    this._isMounted = false;
    this.device.destroy();
    onDialerClose();
  }

  // Initialize state and Twilio connection
  handleTwilioEvents = () => {
    const {
      userPhoneNumber, recordCall, userId, teamId,
    } =      this.state;
    const { lead, leadPhone } = this.props;
    const handleStateChange = (updatedState) => this.setState(updatedState);

    this.device.on('ready', () => {
      handleStateChange({
        callLog:     userPhoneNumber ? 'Pending...' : '',
        onPhone:     !!userPhoneNumber,
        initialized: true,
        error:       '',
      });

      if (userPhoneNumber) {
        this.initiateConnection(
          leadPhone,
          userPhoneNumber,
          recordCall,
          userId,
          teamId,
          lead.id,
        );
        this.handleConnectionEvents();
      }
    });

    this.device.on('disconnect', this.handleDisconnection.bind(this));
    this.device.on('error', this.handleConnectionError.bind(this));
    this.device.on('offline', () => this.handleOfflineState(handleStateChange));
  };

  // Handle the Twilio disconnection
  handleDisconnection(connection) {
    if (this._isMounted) {
      this.stopTimer();
      this.setState({
        callLog:     'Call Ended...',
        onPhone:     false,
        initialized: true,
      });

      if (connection._isAnswered && !connection.parameters.activityCreated) {
        this.createActivityAfterDisconnection();
        connection.parameters = { ...connection.parameters, activityCreated: true };
      }
    }
  }

  // Handle the Twilio connection error
  handleConnectionError(error) {
    this.stopTimer();
    this.setState({
      error:       error.message,
      onPhone:     false,
      initialized: true,
    });
  }

  // Handle the Twilio offline state
  handleOfflineState(handleStateChange) {
    const { connection } = this;

    this.stopTimer();
    handleStateChange({
      callLog:     'Offline...',
      onPhone:     false,
      initialized: true,
    });

    if (connection && !connection.parameters.activityCreated) {
      this.createActivityAfterDisconnection();
      this.connection.parameters = { ...connection.parameters, activityCreated: true };
    }
  }

  // Handle end of call
  handleCallEnd() {
    const { body } = this.state;
    const { setDialerField } = this.props;

    // hang up call in progress
    this.device.disconnectAll();
    const callSid = this.connection.parameters.CallSid;

    if (pendingActivityRequest) {
      APIRequest.abort(pendingActivityRequest);
    }

    this.createActivityAfterDisconnection();
    this.connection.parameters = { ...this.connection.parameters, activityCreated: true };
  }

  handleConnectionEvents = () => {
    this.connection.on('accept', (con) => {
      this.startTimer();

      this.setState({
        callLog: 'Connected...',
      });
    });

    this.connection.on('ringing', (con) => {
      this.setState({
        callLog: 'Calling...',
      });
    });
  };

  handleToggleCall = (e) => {
    e.preventDefault();

    const {
      onPhone, userPhoneNumber, recordCall, teamId, userId,
    } =      this.state;
    const { lead, leadPhone } = this.props;
    const handleStateChange = (updatedState) => this.setState(updatedState);

    if (!onPhone && userPhoneNumber) {
      handleStateChange({
        muted:       false,
        onPhone:     true,
        error:       '',
        initialized: true,
        callLog:     'Pending...',
      });

      this.initiateConnection(
        leadPhone,
        userPhoneNumber,
        recordCall,
        userId,
        teamId,
        lead.id,
      );
      this.handleConnectionEvents();
    } else if (onPhone) {
      this.handleCallEnd();
    } else {
      handleStateChange({
        onPhone:     false,
        initialized: true,
      });
    }
  };

  handleToggleMute = (event) => {
    event.preventDefault();

    const { hold } = this.state;

    if (this.connection && !hold) {
      this.setState(
        (prevState) => ({ muted: !prevState.muted }),
        () => {
          const { muted } = this.state;
          this.connection.mute(muted);
        },
      );
    }
  };

  handleToggleHold = (event) => {
    event.preventDefault();

    const { muted } = this.state;

    if (this.connection && !muted) {
      this.setState(
        (prevState) => ({ hold: !prevState.hold }),
        () => {
          const { hold } = this.state;
          this.connection.mute(hold);

          const audioTrack = this.connection
            .getRemoteStream()
            .getAudioTracks()[0];

          if (audioTrack) {
            audioTrack.enabled = !hold;
          }
        },
      );
    }
  };

  startTimer = () => {
    const { timerTime } = this.state;

    this.setState(
      {
        timerOn:    true,
        timerStart: Date.now() - timerTime,
      },
      () => {
        const { timerStart } = this.state;

        this.timer = setInterval(() => {
          this.setState({
            timerTime: Date.now() - timerStart,
          });
        }, 10);
      },
    );
  };

  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  // Helper function to initiate Twilio connection
  initiateConnection(
    leadPhone,
    userPhoneNumber,
    recordCall,
    userId,
    teamId,
    leadId,
  ) {
    this.connection = this.device.connect({
      To:      leadPhone,
      From:    userPhoneNumber,
      recordCall,
      user_id: userId,
      team_id: teamId,
      lead_id: leadId,
    });
  }

  // Create activity after disconnection
  createActivityAfterDisconnection() {
    const { body } = this.state;
    const { lead } = this.props;
    const { setDialerField, switchForm, currentForm } = this.props;
    const callSid = this.connection.parameters.CallSid;
    const callStatus = this.connection.status();
    const $modal = $(this.appModal.modal);

    this.setState({ creatingActivity: true });

    const data = {
      event:    'phone_call',
      activity: {
        body,
        call_sid: callSid,
        internal: true,
      },
    };

    if (callStatus) {
      data.activity.parameters = {
        call_disposition: callStatus,
      };
    }

    pendingActivityRequest = APIRequest.post({
      resource: `/v1/leads/${lead.id}/activities`,
      data,
    });

    pendingActivityRequest.end((error, response) => {
      this.setState({ creatingActivity: false });

      if (!error) {
        const { id } = response.body;

        $modal.modal('hide');

        AppDispatcher.dispatch({
          type: 'CREATE_LEAD_ACTIVITY_DONE',
        });

        setDialerField({
          id,
          body,
          callSid,
          callStatus,
        });

        if (switchForm && currentForm !== 'phone-call') {
          switchForm('phone-call', false);
        }
      }
    });
  }

  sendDigit(digit) {
    this.device.activeConnection().sendDigits(digit);
  }

  renderDigitButton = (button) => (
    <div
      key={button.digit}
      className="digit"
      onClick={() => this.sendDigit(button.digit)}
    >
      {button.digit}
      {button.subDigit && (
        <div className="sub-digit">{button.subDigit}</div>
      )}
    </div>
  );

  renderDTMFTone = () => (
    <div className="col-12">
      {BUTTONS.map((row, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`row-${rowIndex}`} className="row">
          {row.map((button) => this.renderDigitButton(button))}
        </div>
      ))}
    </div>
  );

  render() {
    const {
      error,
      onPhone,
      callLog,
      muted,
      hold,
      initialized,
      timerTime,
      timerOn,
      userPhoneNumber,
      creatingActivity,
    } = this.state;
    const {
      containerID, modalClass, dialogClass, lead, leadPhone, onDialerClose,
    } =      this.props;

    const seconds = `0${Math.floor(timerTime / 1000) % 60}`.slice(-2);
    const minutes = `0${Math.floor(timerTime / 60000) % 60}`.slice(
      -2,
    );
    const hours = `0${Math.floor(timerTime / 3600000)}`.slice(-2);

    return (
      <AppModal
        containerID={containerID}
        modalClass={modalClass}
        dialogClass={dialogClass}
        contentClass=""
        ref={(appModal) => (this.appModal = appModal)}
      >
        <Draggable>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="appModalLabel">
                {LeadHelpers.renderLeadName(lead)}
                <br />
                {LeadHelpers.renderPhoneNumber(leadPhone)}
              </h5>

              {!creatingActivity && (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  {creatingActivity && (
                    <div className="col-12 text-center">
                      <FontAwesomeIcon
                        icon="far fa-spinner"
                        pulse
                        className="mr5"
                      />
                      {' '}
                      Saving note ...
                    </div>
                  )}

                  {error && (
                    <div className="col-12 text-center text-danger">
                      {error}
                    </div>
                  )}

                  {!userPhoneNumber && (
                    <div className="col-12 text-center text-danger">
                      Please Set Up Phone Number on the Phone Number
                      Page
                      {' '}
                      <a
                        href="/users/phone_number/edit"
                        target="_blank"
                        className="text-danger"
                      >
                        here
                      </a>
                    </div>
                  )}

                  {!error && callLog && userPhoneNumber && (
                    <>
                      <div className="col-12 text-center">
                        {!creatingActivity && callLog}
                      </div>
                      {timerOn && (
                        <div className="col-12 text-center">
                          {hours > 0 && `${hours} : `}
                          {minutes}
                          {' '}
                          :
                          {' '}
                          {seconds}
                        </div>
                      )}
                    </>
                  )}

                  {onPhone && (
                    <>
                      {this.renderDTMFTone()}

                      <div className="col-12 mt15">
                        <label htmlFor="notes" className="d-block">
                          Notes
                        </label>
                        <Input
                          id="notes"
                          type="textarea"
                          rows="4"
                          onChange={(v) => {
                            this.state.body = v.target.value.replace(
                              /(?:\r\n|\r|\n)/g,
                              '<br />',
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="col-12 text-center">
                {onPhone && this.connection && (
                  <>
                    <button
                      type="button"
                      className="btn btn-default mr-2"
                      onClick={this.handleToggleMute}
                    >
                      <FontAwesomeIcon
                        icon={[
                          'fas',
                          `${
                            muted
                              ? 'fa-microphone-slash'
                              : 'fa-microphone'
                          }`,
                        ]}
                      />
                    </button>

                    <button
                      type="button"
                      className="btn btn-default mr-2"
                      onClick={this.handleToggleHold}
                    >
                      <FontAwesomeIcon
                        icon={[
                          'fas',
                          `${hold ? 'fa-play' : 'fa-pause'}`,
                        ]}
                      />
                    </button>
                  </>
                )}

                {!creatingActivity && (
                  <button
                    type="button"
                    className={`btn ${
                      onPhone ? 'btn-danger' : 'btn-success'
                    }`}
                    disabled={!initialized}
                    onClick={this.handleToggleCall}
                  >
                    <FontAwesomeIcon icon={['fas', 'fa-phone-alt']} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </Draggable>
      </AppModal>
    );
  }
}

Dialer.defaultProps = {
  containerID:    'secondary-modal',
  modalClass:     'modal modal-overlay',
  dialogClass:    'modal-dialog modal-sm',
  currentForm:    null,
  switchForm:     () => false,
  setDialerField: () => false,
  onDialerClose:  () => {},
};

Dialer.propTypes = {
  containerID:    PropTypes.string,
  modalClass:     PropTypes.string,
  dialogClass:    PropTypes.string,
  lead:           PropTypes.shape({}).isRequired,
  leadPhone:      PropTypes.string.isRequired,
  currentForm:    PropTypes.string,
  switchForm:     PropTypes.func,
  setDialerField: PropTypes.func,
  onDialerClose:  PropTypes.func,
};

export default Dialer;
