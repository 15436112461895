import React from 'react';
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NavBarSupportedContent extends React.Component {
  constructor(props) {
    super(props);

    this.renderDropdownMenu = this.renderDropdownMenu.bind(this);
  }

  renderDropdownMenu() {
    const { abilities, render, helpers } = Rails;
    const { currentUser, teams } = helpers;

    if (!currentUser) {
      return (
        <ul className="navbar-nav ml-auto my-2 my-lg-0">
          <li className="nav-item dropdown">
            <DropdownItem className="" href="/users/sign_in">
              Log In / Register
            </DropdownItem>
          </li>
        </ul>
      );
    }

    let adminItem;
    if (currentUser.admin) {
      adminItem = (
        <div>
          <DropdownItem href="/houston" className="text-danger font-weight-bold">
            Admin
          </DropdownItem>
          <DropdownItem divider />
        </div>
      );
    }

    const accountItems = [];

    accountItems.push(<DropdownItem href="/users/edit">User Settings</DropdownItem>);
    if (currentUser.admin || currentUser.role.name === 'staff' || currentUser.role.name === 'admin') {
      accountItems.push(<DropdownItem href="/users/phone_number/edit">Phone Number</DropdownItem>);
    }
    if (currentUser.role.name === 'agent' || currentUser.role.name === 'affiliate') {
      accountItems.push(<DropdownItem href="/team/landing_page/edit">Landing Page</DropdownItem>);
    }

    const teamItems = [];
    let inviteTeamItem;

    if (abilities.manageCurrentTeam) {
      inviteTeamItem = (
        <div>
          <DropdownItem href="/onboard" className="text-green font-weight-bold">
            Invite Agents
          </DropdownItem>
          <DropdownItem divider />
        </div>
      );

      teamItems.push(<DropdownItem href="/team/edit">Account Settings</DropdownItem>);
      teamItems.push(<DropdownItem href="/team/members">Manage Team</DropdownItem>);
      teamItems.push(<DropdownItem href={`${Rails.baseUrl}billing`}>Billing</DropdownItem>);
    }

    let teamSwitcher;
    if (_lodash.size(teams)) {
      teamSwitcher = (
        <div>
          <DropdownItem divider />
          <h6 className="navbar-text text-uppercase text-muted ml20" disabled>
            Change Team
          </h6>
          {teams.map((t) => (
            <DropdownItem href={`/teams/${t.id}/switch`} key={`change-team-${t.id}`}>
              {t.name}
            </DropdownItem>
          ))}
        </div>
      );
    }

    return (
      <UncontrolledDropdown nav>
        <DropdownToggle className="text-nowrap custom-user-profile" nav>
          <img src={render.currentUserAvatar.url} {...render.currentUserAvatar.options} height="20" alt="User avatar" />
          <span className="font-weight-bold ml5 display-flex text-grey3">
            {currentUser.name || currentUser.email}
            {' '}
            <FontAwesomeIcon icon={['fas', 'fa-caret-down']} className="ml1" />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {adminItem}
          {inviteTeamItem}
          {accountItems.length > 0 && <div>{accountItems.map((i) => <div key={uuidv4()}>{i}</div>)}</div>}
          {teamItems.length > 0 && <div>{teamItems.map((i) => <div key={uuidv4()}>{i}</div>)}</div>}
          {teamSwitcher}
          <DropdownItem divider />
          <DropdownItem href="/users/sign_out" rel="nofollow" data-method="delete">
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  render() {
    return (
      <div className="navbar-collapse text-center-sm-down" id="navbarSupportedContent">
        {this.renderDropdownMenu()}
      </div>
    );
  }
}

NavBarSupportedContent.defaultProps = {};

NavBarSupportedContent.propTypes = {};

export default NavBarSupportedContent;
