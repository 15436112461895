const tableConfig = {
  todayTable() {
    return {
      title:              'New Leads',
      subtitle:           null,
      scope:              'today_new_leads',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'referred_at',
        title:  'Referred On',
      },
    };
  },

  followUpTable() {
    return {
      title:              'Tasks Due Today',
      subtitle:           null,
      scope:              'today_or_past_tasks',
      resource:           '/v1/task_leads',
      leadTasks:          true,
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    false,
        bucket:    true,
        owner:     true,
        status:    false,
      },
      tasksBulkAction: {
        owner:       false,
        status:      true,
        category:    false,
        priority:    false,
        dueDate:     true,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },

  backlogTable() {
    return {
      title:              'Backlog',
      subtitle:           'Leads without tasks',
      scope:              'backlog',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'referred_at',
        title:  'Referred On',
      },
    };
  },

  onboardingTable() {
    return {
      title:              'Onboarding',
      subtitle:           null,
      scope:              'today_retention_onboarding',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'last_activity_at',
        title:  'Last Contacted',
      },
    };
  },

  retentionFollowUps() {
    return {
      title:              'Tasks Due Today',
      scope:              'today_or_past_tasks',
      subtitle:           null,
      resource:           '/v1/task_leads',
      leadTasks:          true,
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    false,
        bucket:    true,
        owner:     true,
        status:    false,
      },
      tasksBulkAction: {
        owner:       false,
        status:      true,
        category:    false,
        priority:    false,
        dueDate:     true,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },

  todayRetentionTable() {
    return {
      title:              'Retention Reminders',
      subtitle:           'No 1-on-1 contact in the last 30 days',
      scope:              'today_retention_active',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'last_activity_at',
        title:  'Last Contacted',
      },
      enableProductionFilter: true,
    };
  },

  backlogRetentionTable() {
    return {
      title:              'Retention Backlog',
      subtitle:           'Team Members without tasks',
      scope:              'backlog_retention',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'last_activity_at',
        title:  'Last Contacted',
      },
    };
  },

  recruitingSearchTable() {
    return {
      title:              'Search Results',
      subtitle:           null,
      scope:              'search_results',
      tabs:               true,
      resource:           '/v1/search',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'referred_at',
        title:  'Referred On',
      },
    };
  },

  recruitingLeadsTable() {
    return {
      title:              'Leads',
      subtitle:           null,
      scope:              'recruiting_leads',
      tabs:               true,
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'referred_at',
        title:  'Referred On',
      },
    };
  },

  retentionSearchTable() {
    return {
      title:              'Search Results',
      subtitle:           null,
      scope:              'search_results',
      tabs:               true,
      resource:           '/v1/search',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'joined_at',
        title:  'Joined On',
      },
    };
  },

  retentionTeamTable() {
    return {
      title:              'Team',
      subtitle:           null,
      scope:              'retention_team',
      tabs:               true,
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'joined_at',
        title:  'Joined On',
      },
    };
  },

  archivedSearchTable() {
    return {
      title:              'Search Results',
      subtitle:           null,
      scope:              'search_results',
      tabs:               true,
      resource:           '/v1/search',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'joined_at',
        title:  'Joined On',
      },
    };
  },

  archivedTable() {
    return {
      title:              'Archived',
      subtitle:           null,
      scope:              'archived',
      tabs:               true,
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'joined_at',
        title:  'Joined On',
      },
    };
  },

  referralsTable() {
    return {
      title:              'Referrals Made',
      subtitle:           null,
      scope:              'agent_referrals',
      resource:           '/v1/leads',
      contactsBulkAction: {
        labels:    true,
        notes:     true,
        emails:    true,
        texts:     true,
        campaigns: true,
        tasks:     true,
        delete:    true,
        bucket:    true,
        owner:     true,
        status:    true,
      },
      tasksBulkAction: {
        owner:       false,
        status:      false,
        category:    false,
        priority:    false,
        dueDate:     false,
        description: false,
        deleteTask:  false,
      },
      timestamp: {
        column: 'referred_at',
        title:  'Referred On',
      },
    };
  },

  allTasksTable() {
    return {
      title:              'All Tasks',
      subtitle:           null,
      scope:              'all_tasks',
      resource:           '/v1/task_leads',
      task_tabs:          true,
      contactsBulkAction: {
        labels:    false,
        notes:     false,
        emails:    false,
        texts:     false,
        campaigns: false,
        tasks:     false,
        delete:    false,
        bucket:    false,
        owner:     false,
        status:    false,
      },
      tasksBulkAction: {
        owner:       true,
        status:      true,
        category:    true,
        priority:    true,
        dueDate:     true,
        description: true,
        deleteTask:  true,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },

  tasksDueTodayTable() {
    return {
      title:              'Tasks Due Today',
      subtitle:           null,
      scope:              'today_or_past_tasks',
      resource:           '/v1/task_leads',
      task_tabs:          true,
      contactsBulkAction: {
        labels:    false,
        notes:     false,
        emails:    false,
        texts:     false,
        campaigns: false,
        tasks:     false,
        delete:    false,
        bucket:    false,
        owner:     false,
        status:    false,
      },
      tasksBulkAction: {
        owner:       true,
        status:      true,
        category:    true,
        priority:    true,
        dueDate:     true,
        description: true,
        deleteTask:  true,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },

  tasksDueLaterTable() {
    return {
      title:              'Tasks Due Later',
      subtitle:           null,
      scope:              'tasks_due_later',
      resource:           '/v1/task_leads',
      task_tabs:          true,
      contactsBulkAction: {
        labels:    false,
        notes:     false,
        emails:    false,
        texts:     false,
        campaigns: false,
        tasks:     false,
        delete:    false,
        bucket:    false,
        owner:     false,
        status:    false,
      },
      tasksBulkAction: {
        owner:       true,
        status:      true,
        category:    true,
        priority:    true,
        dueDate:     true,
        description: true,
        deleteTask:  true,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },

  tasksOverdueTable() {
    return {
      title:              'Tasks Overdue',
      subtitle:           null,
      scope:              'tasks_overdue',
      resource:           '/v1/task_leads',
      task_tabs:          true,
      contactsBulkAction: {
        labels:    false,
        notes:     false,
        emails:    false,
        texts:     false,
        campaigns: false,
        tasks:     false,
        delete:    false,
        bucket:    false,
        owner:     false,
        status:    false,
      },
      tasksBulkAction: {
        owner:       true,
        status:      true,
        category:    true,
        priority:    true,
        dueDate:     true,
        description: true,
        deleteTask:  true,
      },
      timestamp: {
        column: null,
        title:  null,
      },
    };
  },
};

export default tableConfig;
