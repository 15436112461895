import autoBind   from 'react-autobind';
import React      from 'react';
import PropTypes  from 'prop-types';
import { Input }  from 'reactstrap';
import isEmail    from 'validator/lib/isEmail';

import APIRequest from '~/lib/api_request';
import Select from '~/components/forms/select';
import RoleSelect from '~/components/forms/team_fields/role_select';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';
import DuplicateContactCard from '~/components/duplicate_contact_card';
import AllowOrgDuplicateCheckBox from '~/components/forms/allow_org_duplicate_checkbox';

class TeamMemberPicker extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    autoBind(this);

    const value = props.value ? props.value.toString() : '';

    this.state = {
      value,
      loading:                 true,
      addableErrors:           [],
      members:                 [],
      duplicates_found_in_org: false,
      allow_duplicates_in_org: false,
      duplicate_org_accounts:  [],
    };

    this.loadOptions = this.loadOptions.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.value ? nextProps.value.toString() : '';
    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps) {
    const { level } = this.props;

    if (prevProps.level !== level) {
      this.setState({
        loading: true,
      });
      this.loadOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAllowDuplicatesChange(value) {
    this.setState({ allow_duplicates_in_org: value });
  }

  onSubmitAddable(e) {
    e.preventDefault();

    const {
      first_name, last_name, email, role, allow_duplicates_in_org,
    } = this.state;
    const { onChange } = this.props;

    this.setState({
      addableSending: true, duplicates_found_in_org: false, duplicate_org_accounts: [], addableErrors: [],
    });

    if (this.validateAddable()) {
      APIRequest
        .post({
          resource: '/v1/team/members',
          data:     {
            user:         {
              first_name,
              last_name,
              notify:     true,
            },
            role:         {
              name: role,
            },
            lead: {
              lead_emails_attributes: [{
                id:         null,
                email,
                email_type: 'office',
                main:       true,
                _destroy:   false,
              }],
              allow_duplicates_in_org,
            },
          },
        })
        .end((err, res) => {
          const errors = res.body || {};
          let { duplicates_found_in_org } = this.state;

          if (errors.duplicate_emails) {
            const duplicate_org_accounts = [];
            const dup = errors.duplicate_emails[0];

            if (dup.context === 'org') {
              duplicate_org_accounts.push(`${dup.match.name} - Email: ${dup.value}`);
              duplicates_found_in_org = true;
            }

            errors.email = 'It looks like a contact with this email already exists.';
            errors.duplicate = {
              context:   dup.context,
              match:     dup.match,
            };

            this.setState({
              addableSending: false, addableErrors: errors, duplicates_found_in_org, duplicate_org_accounts,
            });
          } else {
            this.loadOptions();
            const newTeamMemberOption = { value: res.body.id.toString(), addableOpen: false, addableSending: false };
            this.setState(newTeamMemberOption);
            if (onChange) onChange(newTeamMemberOption);
          }
        });
    } else {
      this.setState({ addableSending: false });
    }
  }

  validateAddable() {
    const {
      first_name, last_name, email, role,
    } = this.state;
    const errors = {};

    if (!first_name) {
      errors.first_name = 'This field can not be empty';
    }

    if (!last_name) {
      errors.last_name = 'This field can not be empty';
    }

    if (email) {
      if (!isEmail(email)) {
        errors.email = 'The provided email is invalid';
      }
    } else {
      errors.email = ['Please add an email address to create this record so that there is a way to match and update the data later and this email will function as their username'];
    }

    if (!role) {
      errors.role = 'This field can not be empty';
    }

    this.setState({ addableErrors: errors });

    return _lodash.size(errors) === 0;
  }

  loadOptions() {
    const {
      apiResource, blank, contactOwner, multi, show_all, clearLabel, level,
    } = this.props;
    this._isMounted = true;

    const request = APIRequest.get({
      resource: apiResource,
      data:     {
        level,
      },
    });

    request.end((error, response) => {
      if (this._isMounted) {
        if (error) return;

        const data = response.body.data ? response.body.data : response.body;
        const options = data.map((u) => ({ user: u, value: u.id.toString(), label: u.name }));

        if (blank) options.unshift({ value: '-blank-', label: '-blank-' });

        if (contactOwner) options.unshift({ value: -1, label: "Contact's Owner" });

        if (!multi && show_all) {
          options.unshift({ value: '', label: clearLabel });
        }

        this.setState({
          members: options,
          loading: false,
        });
      }
    });
  }

  renderOption(opt) {
    if (opt.user) {
      return (
        <div>
          <img src={opt.user.avatar_url} alt="" className="rounded-circle mr5" height="20" />
          {opt.user.name}
        </div>
      );
    }
    return <div>{opt.label}</div>;
  }

  renderAddable() {
    const { addable } = this.props;
    const {
      addableOpen, addableSending, addableErrors, role,
      duplicates_found_in_org, duplicate_org_accounts, allow_duplicates_in_org,
    } = this.state;

    if (addableOpen) {
      return (
        <div className="card mt15">
          <div className="card-block">
            <h5 className="mb15">{addable.title}</h5>
            <div className="field-group mb15">
              <label htmlFor="first_name" className="d-block">First Name</label>
              <Input
                id="first_name"
                className={addableErrors.first_name ? 'has-error' : ''}
                onChange={(v) => {
                  this.state.first_name = v.target.value;
                }}
              />
              { addableErrors.first_name && <ErrorMessageWithIcon message={addableErrors.first_name} /> }
            </div>
            <div className="field-group mb15">
              <label htmlFor="last_name" className="d-block">Last Name</label>
              <Input
                id="last_name"
                className={addableErrors.last_name ? 'has-error' : ''}
                onChange={(v) => {
                  this.state.last_name = v.target.value;
                }}
              />
              { addableErrors.last_name && <ErrorMessageWithIcon message={addableErrors.last_name} /> }
            </div>
            <div className="field-group mb15">
              <label htmlFor="email" className="d-block">Email</label>
              <Input
                id="email"
                className={addableErrors.email ? 'has-error' : ''}
                onChange={(v) => {
                  this.state.email = v.target.value;
                }}
              />
              { addableErrors.email && <ErrorMessageWithIcon message={addableErrors.email} /> }
              { addableErrors.duplicate && addableErrors.duplicate.context === 'team' && (
                <DuplicateContactCard duplicate={addableErrors.duplicate} />
              )}
              { duplicates_found_in_org && (
                <AllowOrgDuplicateCheckBox
                  checked={allow_duplicates_in_org}
                  duplicate_org_accounts={duplicate_org_accounts}
                  onChange={this.handleAllowDuplicatesChange}
                />
              )}
            </div>
            <div className="field-group">
              <label htmlFor="role" className="d-block">Role</label>
              <RoleSelect
                id="role"
                externalRolesOnly
                className={addableErrors.role ? 'has-error' : ''}
                value={role}
                onChange={(opt) => {
                  this.state.role = opt.value;
                }}
              />
              { addableErrors.role && <ErrorMessageWithIcon message={addableErrors.role} /> }
            </div>

            <div className="mt15">
              <button
                type="submit"
                className="btn btn-primary mr5"
                disabled={addableSending}
                onClick={this.onSubmitAddable}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => { this.setState({ addableOpen: false }); }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      addable, label, disabled, onChange,
    } = this.props;
    const {
      loading, members, addableOpen, value,
    } = this.state;

    return (
      <div>
        { addableOpen ? (
          this.renderAddable()
        ) : (
          <div>
            { label
            && (
            <label className="d-block">
              { addable && (
              <a
                href="#add"
                className="pull-right"
                onClick={() => {
                  this.setState({ addableOpen: true });
                }}
              >
                {addable.title || 'Add Team Member'}
              </a>
              )}
              {label}
            </label>
            ) }
            <Select
              {...this.props}
              ref={(el) => { this.select = el; }}
              isLoading={loading}
              options={members}
              value={value}
              openOnFocus
              optionRenderer={this.renderOption}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    );
  }
}

TeamMemberPicker.defaultProps = {
  label:        '',
  name:         '',
  value:        '',
  addable:      {},
  multi:        false,
  clearable:    false,
  clearLabel:   'Show All',
  blank:        false,
  show_all:     false,
  apiResource:  '/v1/team/members_options',
  options:      [],
  onChange:     () => false,
  contactOwner: false,
  level:        null,
};

TeamMemberPicker.propTypes = {
  label:        PropTypes.string,
  name:         PropTypes.string,
  value:        PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  addable:      PropTypes.shape({}),
  multi:        PropTypes.bool,
  show_all:     PropTypes.bool,
  clearable:    PropTypes.bool,
  clearLabel:   PropTypes.string,
  apiResource:  PropTypes.string,
  options:      PropTypes.arrayOf(PropTypes.shape({})),
  blank:        PropTypes.bool,
  onChange:     PropTypes.func,
  contactOwner: PropTypes.bool,
  level:        PropTypes.string,
};

export default TeamMemberPicker;
